import React from "react";
import {findIndexIgnoreCase} from "../commons/Utils/ArrayUtils";
import EiaTextField from "./EiaTextField";
import {testShortFloat} from "../commons/Utils/RegExUtils";
import EiaSelect from "./EiaSelect";
import {CURRENCY_NAMES, CURRENCY_TYPES,} from "../commons/enums/D4Enums";
import StringUtils from "../commons/Utils/StringUtils";

const MAX_PRICE_EACH = 100000;

const CurrencySelects = function (props) {
    const {currency, setCurrency, addAnyCurrencyType, addPrice, amount, setAlert} = props;

    const isCurrency = Object.keys(currency).length > 0;

    let currencyTypes;
    if(addAnyCurrencyType) {
        currencyTypes = ["Any", ... CURRENCY_TYPES];
    } else {
        currencyTypes = CURRENCY_TYPES;
    }


    const currencyType = isCurrency ? currency.type : currencyTypes[0];

    const getCurrencyNames = function(baseArray, type) {
        if(addAnyCurrencyType) {
            return type === "Any" ? ["Any"] : baseArray[type]
        } else {
            return baseArray[type]
        }
    }

    let currencyNames = getCurrencyNames(CURRENCY_NAMES, currencyType);

    const currencyName = isCurrency ? currency.name : currencyNames[0];
    const price = isCurrency ? currency.price : 1;

    const currencyTypeIndex = findIndexIgnoreCase(currencyTypes, currencyType);
    const currencyNameIndex = findIndexIgnoreCase(currencyNames, currencyName);

    const [priceEach, setPriceEach] = React.useState(0);

    React.useEffect(() => {
        //set a default currency object if it is null or undefined or empty
        if(!isCurrency) {
            const temp = {};
            temp.type = currencyType;
            temp.name = currencyName;
            temp.price = price;
            setCurrency(temp);
        }
    }, []);

    const updateCurrencyTypeIndex = function (value) {
        const temp = Object.assign({}, currency);

        temp.type = currencyTypes[value];
        const tempCurrencyNames = getCurrencyNames(CURRENCY_NAMES, temp.type);
        temp.name = tempCurrencyNames[0];

        setCurrency(temp);
    }

    const updateCurrencyNameIndex = function (value) {
        const temp = Object.assign([], currency);
        temp.name = currencyNames[value];

        setCurrency(temp);
    }

    const updatePrice = function(newPrice, newPriceEach) {
        if(newPriceEach > MAX_PRICE_EACH || (newPriceEach <= 1 && newPrice > MAX_PRICE_EACH)) {
            if(setAlert) {
                let message = "maximum price each item is " + StringUtils.getPriceString(MAX_PRICE_EACH) + " " + currencyName;
                setAlert(["error", message])
                return;
            }
        }

        const temp = Object.assign([], currency);
        temp.price = newPrice;
        setCurrency(temp);

        if(newPriceEach === null) {
            setPriceEach(newPrice / amount);
        } else {
            setPriceEach(newPriceEach);
        }
    }


    return (
        <div>
            <EiaSelect title={"Currency Type"} values={currencyTypes}
                       value={currencyTypeIndex} onChange={(e) => updateCurrencyTypeIndex(e.target.value)}/>
            <EiaSelect title={"Currency"} values={currencyNames}
                       value={currencyNameIndex} onChange={(e) => updateCurrencyNameIndex(e.target.value)}/>
            {addPrice ? <EiaTextField validator={testShortFloat} onChange={(e) => updatePrice(e.currentTarget.value, null)} label="Price" value={price}/> : null}
            {addPrice && amount > 1? <EiaTextField validator={testShortFloat} onChange={(e) => updatePrice(e.currentTarget.value * amount, e.currentTarget.value)} label="Price each" value={priceEach}/> : null}
        </div>
    )
}
export default CurrencySelects;