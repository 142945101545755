import React from "react";
import EiaDialog from "./EiaDialog";
import SearchBasePage from "../../pages/SearchBasePage";
import {ITEM_RARITIES} from "../../commons/enums/D4Enums";
import {Flex, Heading, Label} from "@aws-amplify/ui-react";
import EiaHelpButton from "../EiaHelpButton";
import {COLOR_LEGENDARY, COLOR_UNIQUE} from "../../commons/enums/ColorEnums";

const toleranceFactor = 0.9;

const isUnique=function(baseItem) {
    return baseItem !== undefined && baseItem != null && baseItem.rarity === ITEM_RARITIES[1];
}

const getPriceCheckStats=function(baseItem, stats) {

    let result = [];

    stats.forEach(stat => {
        const temp = Object.assign({}, stat);
        if(stat.greaterAffix) {
            result.push(temp);
        }
    })

    return result;
}

const getPriceCheckUniqueAffixes=function(baseItem, uniqueAffixes) {
    if(isUnique(baseItem)) {
        let result = [];

        uniqueAffixes.forEach(stat => {
            const temp = Object.assign({}, stat);
            if(temp.floatValues && temp.floatValues[0]) {
                temp.floatValues = [temp.floatValues[0] * toleranceFactor];
            }
            result.push(temp);
        })

        return result;
    } else {
        return [];
    }
}

const legendaryStyle = {color: COLOR_LEGENDARY};
const uniqueStyle = {color: COLOR_UNIQUE};

const priceCheckTitle = <Flex direction={"row"}>
    {"Price Check"}
    <EiaHelpButton title={"How does Price Check work?"}>
        <Label>Price Check shows you similar ACTIVE listings so you have an idea of the price. Only unique and legendary items are supported. What are similar listings? Listings with ...</Label>
        <Heading level={5} style={uniqueStyle}>Unique</Heading>
        <Label>{"\u2022 at least the same greater affixes"}</Label>
        <Label>{"\u2022 at least 90% of the unique affix value, e.g. if you have a 20% tibault's unique affix then it searches for tibault's unique affixes with at least 18%"}</Label>
        <Label>{"\u2022 at least 90% of the item power, dps and armor"}</Label>
        <Heading level={5} style={legendaryStyle}>Legendary</Heading>
        <Label>{"\u2022 at least the same greater affixes"}</Label>
        <Label>{"\u2022 same item type"}</Label>
        <Label>{"\u2022 at least 90% of the item power, dps and armor"}</Label>
    </EiaHelpButton>
</Flex>

function PriceCheckDialog(props) {
    const { user, platform, region, openPriceCheckDialog, setOpenPriceCheckDialog, isLoading, currency, baseItem, baseStats, stats, uniqueAffixes } = props;

    const priceCheckBaseItem = {
        title: "",
        name: baseItem.name,
        rarity: baseItem.rarity,
        itemType: baseItem.itemType,
        itemSubType: baseItem.itemSubType
    }

    const priceCheckBaseStats = {
        itemPower: baseStats.itemPower ? baseStats.itemPower * toleranceFactor : 0,
        dps: isUnique(baseItem) ? 0 : baseStats.dps * toleranceFactor,
        armor: isUnique(baseItem) ? 0 : baseStats.armor * toleranceFactor,
        levelRequirement: baseStats.levelRequirement ? baseStats.levelRequirement * (1/toleranceFactor) : 100,
        classRequirement: isUnique(baseItem) ? "Any" : baseStats.classRequirement
    }

    const priceCheckStats = getPriceCheckStats(baseItem, stats);

    const priceCheckUniqueAffixes = getPriceCheckUniqueAffixes(baseItem, uniqueAffixes);



    return (
        <EiaDialog isLoading={isLoading} open={openPriceCheckDialog} onClose={() => setOpenPriceCheckDialog(false)} title={priceCheckTitle} fullWidth={true} maxWidth={"xl"}>
            <SearchBasePage
                user={user}
                platform={platform}
                region={region}
                currency={currency}
                // setCurrency={setCurrency}
                baseItem={priceCheckBaseItem}
                // setBaseItem={setBaseItem}
                initialBaseStats={priceCheckBaseStats}
                initialItemStats={priceCheckStats}
                initialUniqueAffixes={priceCheckUniqueAffixes}
                onlyBestPricePerItemName={false}
                activateFiltersDrawer={false}
                allowBids={false}
            />
        </EiaDialog>
    );
}

export default PriceCheckDialog;