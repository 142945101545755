import React from "react";
import {SelectField} from "@aws-amplify/ui-react";

//the prop.value must be the index and not the value itself, e.g. for ["bla","blub"] pass props.value=0 instead of props.value="bla"
const EiaSelect = function(props) {
    const {title, values} = props;

    const options = values === undefined ? [] : values.map((value, valueIndex) => {
        return (
            <option key={valueIndex} value={valueIndex}>{value}</option>);
    })

    return (
        <div>
            <SelectField
                label={title}
                {...props}
            >
                {options}
            </SelectField>
        </div>
    )
}
export default EiaSelect;