import React from "react";
import {Button, Icon} from "@aws-amplify/ui-react";
import {RiQuestionFill} from "react-icons/ri";
import EiaDialog from "./dialogs/EiaDialog";

const EiaHelpButton = function(props) {

    const {title} = props

    const [openDialog, setOpenDialog] = React.useState(false);

    return (
        <div>
            <Button size={"large"} variation={"link"}
                onClick={() => setOpenDialog(true)}
            >
                <Icon ariaLabel="Javascript" as={RiQuestionFill}/>
            </Button>
            <EiaDialog open={openDialog} onClose={() => setOpenDialog(false)} title={title} fullWidth={true}>
                {props.children}
            </EiaDialog>
        </div>
    );
}
export default EiaHelpButton;