import React from "react";
import {Flex, Heading} from "@aws-amplify/ui-react";
import EiaHelpButton from "../EiaHelpButton";
import EiaImage from "../EiaImage";
import helpAffixStats from "../../resources/help/helpAffixStats.jpg";
import helpAspects from "../../resources/help/helpAspects.jpg";
import helpUniqueAffix from "../../resources/help/helpUniqueAffix.jpg";
import D4ItemStatSelect from "./D4ItemStatSelect";
import {D4_ASPECTS} from "../../commons/enums/D4Aspects";
import {D4_UNIQUE_AFFIXES} from "../../commons/enums/D4UniqueAffixes";

const D4AffixSelects = function(props) {
    const {size, stats, setStats, updateStatsSelects, aspects, setAspects, updateAspectSelects, setAlert, itemCanHaveAspects,
        uniqueAffixes, setUniqueAffixes, updateUniqueAffixesSelects, itemCanHaveUniqueAffixes} = props;

    const indexes = Array.from(Array(size).keys());

    return (
        <Flex direction="column" justifyContent="space-between">
            <Flex>
                <Heading level={4}>Affix Stats</Heading>
                <EiaHelpButton title={"Where are the affix stats?"}>
                    <Flex justifyContent={"space-around"}>
                        <EiaImage objectFit="cover" height={300}
                                  src={helpAffixStats}/>
                    </Flex>
                </EiaHelpButton>
            </Flex>
            {
                indexes.map(index => <D4ItemStatSelect index={index} key={index} update={updateStatsSelects} stats={stats} isGreaterAffixPossible={true} onChange={setStats} setAlert={setAlert}/>)
            }
            {itemCanHaveAspects ?
                <Flex>
                    <Heading level={4}>Aspect</Heading>
                    <EiaHelpButton title={"Where is the aspect?"}>
                        <Flex justifyContent={"space-around"}>
                            <EiaImage objectFit="cover" height={300}
                                      src={helpAspects}/>
                        </Flex>
                    </EiaHelpButton>
                </Flex> : null
            }
            {itemCanHaveAspects ?
                <D4ItemStatSelect statEnum={D4_ASPECTS} index={0} update={updateAspectSelects} stats={aspects}
                                  onChange={setAspects} setAlert={setAlert}/> : null
            }
            {itemCanHaveUniqueAffixes ?
                <Flex>
                    <Heading level={4}>Unique Affix</Heading>
                    <EiaHelpButton title={"Where is the unique affix?"}>
                        <Flex justifyContent={"space-around"}>
                            <EiaImage objectFit="cover" height={300}
                                      src={helpUniqueAffix}/>
                        </Flex>
                    </EiaHelpButton>
                </Flex> : null
            }
            {itemCanHaveUniqueAffixes ?
                <D4ItemStatSelect statEnum={D4_UNIQUE_AFFIXES} index={0} update={updateUniqueAffixesSelects} stats={uniqueAffixes}
                                  onChange={setUniqueAffixes} setAlert={setAlert}/> : null
            }
        </Flex>
    )
}
export default D4AffixSelects;