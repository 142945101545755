import * as React from 'react';
import {Table, TableBody, TableCell, TableHead, TableRow} from "@aws-amplify/ui-react";
import EiaCard from "../cards/EiaCard";

const EiaFixedTable = function(props) {
    const {data, tableHeader, styleHeader, styleHeaderIndex} = props;

    return(
        <div>
            <EiaCard>
                <Table
                    caption=""
                    highlightOnHover={true}
                    size="small"
                    variation="striped">
                    <TableHead>
                        <TableRow>
                            {tableHeader.map((value, valueIndex) => {
                                return (
                                    <TableCell style={valueIndex === styleHeaderIndex ? styleHeader : null} key={valueIndex} as="th">{value}</TableCell>
                                    );
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((row, rowIndex) => {
                            return (
                                <TableRow key = {rowIndex}>
                                    {row.map((value, valueIndex) => {
                                        return (
                                            <TableCell key={valueIndex}>{value}</TableCell>
                                        );
                                    })}
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </EiaCard>
        </div>

    );
}
export default EiaFixedTable;