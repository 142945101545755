import React from "react";
import {Flex, Heading} from "@aws-amplify/ui-react";
import EiaHelpButton from "../EiaHelpButton";
import EiaImage from "../EiaImage";
import D4ItemStatSelect from "./D4ItemStatSelect";
import helpImplicitStats from "../../resources/help/helpImplicitStats.jpg";

const D4ImplicitStatSelects = function(props) {
    const {size, implicitStats, setImplicitStats, updateImplicitStatsSelects, setAlert} = props;

    const indexes = Array.from(Array(size).keys());

    return (
        <Flex direction="column" justifyContent="space-between">
            <Flex>
                <Heading level={4}>Implicit Stats</Heading>
                <EiaHelpButton title={"Where are the implicit stats?"}>
                    <Flex justifyContent={"space-around"}>
                        <EiaImage objectFit="cover" height={300}
                                  src={helpImplicitStats}/>
                    </Flex>
                </EiaHelpButton>
            </Flex>
            {
                indexes.map(index => <D4ItemStatSelect index={index} key={index} update={updateImplicitStatsSelects} stats={implicitStats} onChange={setImplicitStats} setAlert={setAlert}/>)
            }
        </Flex>
    )
}
export default D4ImplicitStatSelects;