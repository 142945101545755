import React from "react";
import EiaButton from "../EiaButton";
import {Flex, TextAreaField} from "@aws-amplify/ui-react";
import {userApiGetUser} from "../../commons/api/BasicApi";
import AlertCard from "../cards/AlertCard";
import EiaDialog from "./EiaDialog";
import UserCard from "../cards/UserCard";
import Utils from "../../commons/Utils/Utils";
import {useCookies} from "react-cookie";
import LoadingDialog from "./LoadingDialog";
import {d4ChangeAuctionStatus, d4GetMyBids, d4UpdateBid} from "../../commons/api/D4Api";
import EiaCard from "../cards/EiaCard";
import D4ItemCardWithPrice from "../d4/D4ItemCardWithPrice";

const isSmallScreen = Utils.isSmallScreen();
const direction = isSmallScreen ? "column" : "row";

const sellerText = "1. Wait for the friend request of the buyer or send him a friend request with"
    + " his battle tag if available.\n"
    + "2. Group up and meet to trade (e.g. Kyovashad)."

const buyerText = "1. Send the seller a friend request with his battle tag.\n"
    + "2. Group up and meet to trade (e.g. Kyovashad).";

function TradeDialog(props) {
    const {
        onCancel,
        bidsState,
        salesState,
        open,
        auction,
        isSeller
    } = props;

    const platform = auction ? auction.platform : "";
    const region = auction ? auction.region : "";
    const buyerName = auction ? auction.buyer : "";
    const sellerName = auction ? auction.seller : "";

    const [cookies, setCookies, removeCookies] = useCookies(['mode', 'realm']);
    const mode = cookies.mode;
    const realm = cookies.realm;

    const [seller, setSeller] = React.useState({});
    const [buyer, setBuyer] = React.useState({});

    const [alert, setAlert] = React.useState(null)
    const [isLoading, setIsLoading] = React.useState(false);

    const [auctionMergedWithBid, setAuctionMergedWithBid] = React.useState({})
    const auctionId = auction ? auction.id : "";

    React.useEffect(() => {
        if (!open) {
            return;
        }

        setIsLoading(true);
        const sellerRequest = {
            requestedUser: sellerName,
            requestedUserIsSeller: true,
            auctionId: auctionId,
            platform: platform,
            region: region,
            mode: mode,
            realm: realm,
        }

        let isSellerFinished = false;
        let isBuyerFinished = false;
        let isBidLoadingFinished = false;

        userApiGetUser(sellerRequest, function (response) {
            if(response) {
                if(response.user) {
                    //it is possible that the user does not exist in the db yet, because the user only gets saved in db when the user has no default settings
                    setSeller(response.user)
                } else {
                    setSeller({name: sellerName})
                }
            } else {
                setAlert(["error", "Failed loading seller"]);
            }

            isSellerFinished = true;
            if (isSellerFinished && isBuyerFinished && isBidLoadingFinished) {
                setIsLoading(false);
            }
        }, function (error) {
            setAlert(["error", error.message]);
            isSellerFinished = true;
            if (isSellerFinished && isBuyerFinished && isBidLoadingFinished) {
                setIsLoading(false);
            }
        })

        const buyerRequest = {
            requestedUser: buyerName,
            requestedUserIsSeller: false,
            auctionId: auctionId,
            platform: platform,
            region: region,
            mode: mode,
            realm: realm,
        }

        userApiGetUser(buyerRequest, function (response) {
            if(response) {
                if(response.user) {
                    //it is possible that the user does not exist in the db yet, because the user only gets saved in db when the user has no default settings
                    setBuyer(response.user)
                } else {
                    setBuyer({name: buyerName})
                }
            } else {
                setAlert(["error", "Failed loading buyer"]);
            }

            isBuyerFinished = true;
            if (isSellerFinished && isBuyerFinished && isBidLoadingFinished) {
                setIsLoading(false);
            }
        }, function (error) {
            setAlert(["error", error.message]);
            isBuyerFinished = true;
            if (isSellerFinished && isBuyerFinished && isBidLoadingFinished) {
                setIsLoading(false);
            }
        })

        if(isSeller) {
            const request = {
                platform: platform,
                region: region,
                mode: mode,
                realm: realm,
                auctionId: auctionId
            }

            d4GetMyBids(request, function (response) {
                if (response.bids) {

                    let temp = Object.assign([], auction);

                    let foundBuyer = false;

                    for (let i = 0; i < response.bids.length; i++) {
                        let bid = response.bids[i]
                        if(bid.bidder == auction.buyer) {
                            temp.originalCurrency = temp.currency;
                            temp.originalPrice = temp.price;
                            temp.originalAmount = temp.amount;
                            temp.originalPricePerItem = temp.amount ? temp.price / temp.amount : temp.price

                            temp.price = bid.price;
                            temp.amount = bid.amount;
                            temp.currency = bid.currency;
                            temp.pricePerItem = temp.amount ? temp.price / temp.amount : temp.price
                            foundBuyer = true;
                        }
                    }

                    if(!foundBuyer) {
                        setAlert(["error", "Cannot find buyer anymore - please close and reload sales"]);
                    }
                    setAuctionMergedWithBid(temp);
                } else {
                    setAlert(["error", "Cannot find buyer anymore - please close and reload sales"]);
                }

                isBidLoadingFinished = true;
                if (isSellerFinished && isBuyerFinished && isBidLoadingFinished) {
                    setIsLoading(false);
                }
            }, function (error) {
                setAlert(["error", error.message]);
                isBidLoadingFinished = true;
                if (isSellerFinished && isBuyerFinished && isBidLoadingFinished) {
                    setIsLoading(false);
                }
            })
        } else {
            setAuctionMergedWithBid(auction);
            isBidLoadingFinished = true;
            if (isSellerFinished && isBuyerFinished && isBidLoadingFinished) {
                setIsLoading(false);
            }
        }
    }, [open]);

    const close = function () {
        setAlert(null);
        onCancel();
    }

    const completeSuccessful = function () {
        setAlert(null);
        if (isSeller) {
            completeAsSeller();
        } else {
            completeAsBuyer(true)
        }
    }

    const completeUnsuccessful = function () {
        setAlert(null);
        if (isSeller) {
            //do nothing for now since seller have no button for this
        } else {
            completeAsBuyer(false)
        }
    }

    const reloadMySales = function() {
        salesState.reload(setIsLoading, setAlert, mode, realm);
    }

    const reloadMyBids = function() {
        bidsState.reload(setIsLoading, setAlert, mode, realm);
    }

    const completeAsSeller = function () {
        setIsLoading(true)

        const request = {
            platform: platform,
            region: region,
            mode: mode,
            realm: realm,
            id: auctionId,
            status: "COMPLETED"
        }

        d4ChangeAuctionStatus(request, function (response) {
            setAlert(["success", ("Successfully completed auction with item " + response.auction.item.name)]);
            setIsLoading(false);
            reloadMySales();
            close();
        }, function (error) {
            setAlert(["error", error.message]);
            setIsLoading(false);
            reloadMySales();
        })
    }

    const completeAsBuyer = function (withSuccess) {
        setIsLoading(true);

        const request = {
            platform: platform,
            region: region,
            mode: mode,
            realm: realm,

            auctionId: auctionId,
            status: "NOT_SUCCESSFUL"
        }

        if (withSuccess) {
            request.status = "SUCCESSFUL"
        }

        d4UpdateBid(request, function (response) {
            setAlert(["success", ("Successfully completed trade")]);
            setIsLoading(false);
            reloadMyBids();
            close();
        }, function (error) {
            setAlert(["error", error.message]);
            setIsLoading(false);
            reloadMyBids();
        })
    }

    return (
        <EiaDialog open={open} title={"Trade"} onClose={close}>
            <LoadingDialog open={isLoading}/>
            <AlertCard alert={alert}/>
            <EiaCard>
                <D4ItemCardWithPrice auctionMergedWithBid={auctionMergedWithBid} showDetails={true} showBuyDetails={true}/>
            </EiaCard>

            <TextAreaField isReadOnly={true} rows={3} defaultValue={isSeller ? sellerText : buyerText}/>
            {/*<Flex direction="row" justifyContent="space-evenly">*/}
            {/*    <TextField isReadOnly label="Mode" value={mode}/>*/}
            {/*    <TextField isReadOnly label="Realm" value={realm}/>*/}
            {/*</Flex>*/}
            {/*<Flex direction="row" justifyContent="space-evenly">*/}
            {/*    <TextField isReadOnly label="Price" value={auction.price + " " + auction.currency}/>*/}
            {/*</Flex>*/}
            {/*<Divider/>*/}
            <Flex direction={direction} justifyContent="space-evenly">
                <UserCard user={buyer} title={"Buyer"}></UserCard>
                <UserCard user={seller} title={"Seller"}></UserCard>
            </Flex>
            <Flex direction="row" justifyContent="space-evenly">
                {
                    isSeller ? null : <EiaButton onClick={completeUnsuccessful} colorTheme={"error"}>Withdraw</EiaButton>
                }
                <EiaButton onClick={completeSuccessful} colorTheme={"success"}>Mark as finished</EiaButton>
            </Flex>
        </EiaDialog>
    );
}

export default TradeDialog;