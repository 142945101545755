import React from "react";
import {Flex, Message, SwitchField} from "@aws-amplify/ui-react";
import EiaButton from "../components/EiaButton";
import {d4GetMyAuctions, d4SearchAuctions} from "../commons/api/D4Api";
import {contains, findByKeyValue} from "../commons/Utils/ArrayUtils";
import {getAdditionalHeaders, SEARCH_TABLE_HEADERS, SEARCH_TABLE_HEADERS_NO_STATS} from "../commons/TableHeaders";
import ShowDetailsButton from "../components/ShowDetailsButton";
import {auctionToTableElement, convertToD4ItemForRequest} from "../commons/Converter";
import Utils from "../commons/Utils/Utils";
import EiaAuctionTableWithButtons from "../components/tables/EiaAuctionTableWithButtons";
import FiltersDrawer from "../components/drawer/FiltersDrawer";
import {useCookies} from "react-cookie";
import {ITEM_SUB_TYPES_WITHOUT_STATS} from "../commons/enums/D4Enums";
import EiaAuctionViewWithButtons from "../components/EiaAuctionViewWithButtons";
import EiaInfoText from "../components/EiaInfoText";
import BidDialog from "../components/dialogs/BidDialog";

const tableInitialState = {
    sorting: {
        sortModel: [{ field: 'creationDate', sort: 'desc' }],
    },
}

const bestPricesTableInitialState = {
    sorting: {
        sortModel: [{ field: 'itemName', sort: 'asc' }],
    },
}

const isSmallScreen = Utils.isSmallScreen();
const buttonsDirection = isSmallScreen ? "row" : "column"

const SearchBasePage = function (props) {
    const {user, platform, region, optionalLeftElements,
        currency, setCurrency,
        baseItem, setBaseItem,
        initialBaseStats={},
        initialItemStats=[],
        initialAspects=[],
        initialUniqueAffixes=[],
        onlyBestPricePerItemName,
        activateFiltersDrawer,
        allowBids=true
    } = props;

    const [cookies, setCookies, removeCookies] = useCookies(['mode', 'realm']);
    const mode = cookies.mode;
    const realm = cookies.realm;

    const [tableView, setTableView] = React.useState(false);
    const [hideOwnItems, setHideOwnItems] = React.useState(false);

    const [baseStats, setBaseStats] = React.useState(initialBaseStats);
    const [itemStats, setItemStats] = React.useState(initialItemStats);
    const [aspects, setAspects] = React.useState(initialAspects);
    const [uniqueAffixes, setUniqueAffixes] = React.useState(initialUniqueAffixes);

    const [alert, setAlert] = React.useState(null)
    const [tableData, setTableData] = React.useState([])
    const [isLoading, setIsLoading] = React.useState(false)
    const [selectedAuctionId, setSelectedAuctionId] = React.useState([])

    const filteredTableData = hideOwnItems && user !== undefined ? tableData.filter(element => element.seller != user.username) : tableData;

    const [openBidDialog, setOpenBidDialog] = React.useState(false);

    const [tableHeader, setTableHeader] = React.useState(SEARCH_TABLE_HEADERS);

    const selectedAuction = selectedAuctionId.length === 0 ? null : findByKeyValue(filteredTableData, "id", selectedAuctionId);

    const [initSearchDone, setInitSearchDone] = React.useState(false);

    React.useEffect(() => {
        if(currency !== undefined && currency.name !== undefined && !initSearchDone) {
            searchAuctions();
            setInitSearchDone(true);
        }
    }, [currency]);

    const searchAuctions = function () {
        setSelectedAuctionId([])
        setIsLoading(true)
        setAlert(null);
        const itemForRequest = convertToD4ItemForRequest(baseItem, baseStats, null, itemStats, aspects, uniqueAffixes);
        const request = {
            platform: platform,
            region: region,
            mode: mode,
            realm: realm,
            currency: currency ? currency.name : "Any",
            item: itemForRequest,
            status: "ACTIVE",
            onlyBestPricePerItemName: onlyBestPricePerItemName
        }

        d4SearchAuctions(request, function (response) {
            if(response.auctions) {
                if(!onlyBestPricePerItemName && response.auctions.length >= 1000) {
                    setAlert(["warning", "Your search results reached the maximum limit. Please use more search"
                    + " filters."]);
                }

                let tempTableData = response.auctions.map(auction => {
                    return auctionToTableElement(auction);
                })

                setTableData(tempTableData)
            } else {
                setTableData([])
            }

            if(contains(ITEM_SUB_TYPES_WITHOUT_STATS, baseItem.itemSubType)) {
                setTableHeader(SEARCH_TABLE_HEADERS_NO_STATS);
            } else {
                setTableHeader(SEARCH_TABLE_HEADERS.concat(getAdditionalHeaders(baseItem.itemType, baseItem.itemSubType, itemStats)));
            }

            setIsLoading(false)
        }, function (error) {
            setAlert(["error", error.message]);
            setTableData([])
            setIsLoading(false)
        })
    }

    const showAllFromSeller = function () {
        const seller = selectedAuction.seller;
        setSelectedAuctionId([])
        setIsLoading(true)

        const request = {
            platform: platform,
            region: region,
            mode: mode,
            realm: realm,
            optionalSeller: seller
        }

        d4GetMyAuctions(request, function (response) {
            if(response.auctions) {
                let tempTableData = response.auctions.map(auction => {
                    return auctionToTableElement(auction);
                })

                setTableData(tempTableData)
            } else {
                setTableData([])
            }

            setTableHeader(SEARCH_TABLE_HEADERS);

            setIsLoading(false)

        }, function (error) {
            setAlert(["error", error.message]);
            setTableData([])
            setIsLoading(false)
        })
    }

    const handleClickAuctionInNonTableView=function(auction) {
        setSelectedAuctionId(auction.id);
        setOpenBidDialog(true);
    }

    const buttons = <Flex direction={"column"}>
        <SwitchField
            isDisabled={isLoading}
            label="Table View"
            labelPosition="end"
            isChecked={tableView}
            onChange={(e) => {
                setTableView(e.target.checked);
            }}
        />
        <SwitchField
            isDisabled={isLoading}
            label="Hide own items"
            labelPosition="end"
            isChecked={hideOwnItems}
            onChange={(e) => {
                if(e.target.checked === false) {
                    setSelectedAuctionId([])
                }
                setHideOwnItems(e.target.checked);
            }}
        />
        {optionalLeftElements}
        <Flex direction={buttonsDirection} justifyContent="space-between">
            <EiaButton onClick={searchAuctions} disabled={isLoading} isFullWidth tooltip={"Reloads the item data"}>Reload</EiaButton>
            {activateFiltersDrawer ? <FiltersDrawer
                {... {currency, setCurrency, baseItem, setBaseItem, baseStats, setBaseStats, itemStats, setItemStats, setAlert, searchAuctions,
                    isLoading, aspects, setAspects, uniqueAffixes, setUniqueAffixes}}
            /> : null}
        </Flex>
        { !allowBids || tableView ? null : <EiaInfoText value={"Click an item to bid"} /> }
        {tableView ?
            <Flex direction={buttonsDirection} justifyContent="space-between">
                <ShowDetailsButton isFullWidth disabled={isLoading || selectedAuctionId.length === 0} title={"Details"}
                                   details={findByKeyValue(filteredTableData, "id", selectedAuctionId)}/>
                { allowBids && !onlyBestPricePerItemName ? <EiaButton onClick={showAllFromSeller} disabled={isLoading || selectedAuctionId.length === 0}
                    isFullWidth tooltip={"Shows all listings from the seller of the selected listing"}>Show all from seller</EiaButton> : null}
                { allowBids ?
                <EiaButton colorTheme={"success"} onClick={() => setOpenBidDialog(true)} disabled={isLoading || selectedAuctionId.length === 0}
                           isFullWidth tooltip={"Bid a currency and price of your choice"}>Bid ...</EiaButton> : null }
            </Flex> : null}
    </Flex>

    const infoMessage =tableData !== undefined && tableData.length === 100 ? <Message colorTheme={"info"}>Search without filters only shows 100 items</Message> : null;

    return (<div>
        {allowBids && selectedAuction ?
            <BidDialog
                platform={platform}
                region={region}
                open={openBidDialog}
                setOpen={setOpenBidDialog}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                setAlert={setAlert}
                selectedAuction={selectedAuction}
                selectedAuctionId={selectedAuctionId}
                searchAuctions={searchAuctions}
            /> : null
        }
        {
            tableView ? <EiaAuctionTableWithButtons
                buttons={buttons}
                alert={alert}
                selectedAuction={selectedAuction}
                selectedAuctionId={selectedAuctionId}
                setSelectedAuctionId={setSelectedAuctionId}
                isLoading={isLoading}
                tableData={filteredTableData}
                tableHeader={tableHeader}
                tableInitialState={onlyBestPricePerItemName ? bestPricesTableInitialState : tableInitialState}
                infoMessage={infoMessage}
            /> : <EiaAuctionViewWithButtons
                buttons={buttons}
                alert={alert}
                isLoading={isLoading}
                auctions={filteredTableData}
                onClickAuction={allowBids ? handleClickAuctionInNonTableView : null}
                infoMessage={infoMessage}
                supportSorting={!onlyBestPricePerItemName}
                defaultSortIndex={2}
            />
        }
    </div>)
}
export default SearchBasePage;