import {Flex} from "@aws-amplify/ui-react";
import React from "react";
import Utils from "../commons/Utils/Utils";

const isSmallScreen = Utils.isSmallScreen();
const direction = isSmallScreen ? "row" : "column";
const wrap = isSmallScreen ? "wrap" : "nowrap";

const EiaButtonBar = function (props) {

    return (<Flex direction={direction} justifyContent={"space-between"} wrap={wrap} >
            {props.children}
        </Flex>
    );
};
export default EiaButtonBar;