import React from "react";
import Utils from "../commons/Utils/Utils";

const isSmallScreen = Utils.isSmallScreen();
const defaultWidth = isSmallScreen ? window.screen.width * 0.8 : 560;
const defaultHeight = isSmallScreen ? window.screen.width * 0.8 / 1.77 : 315;

const YoutubeVideo = function (props) {
    const {src, width=defaultWidth, height=defaultHeight} = props;

    return (<iframe width={width} height={height} src={src} title="YouTube video player" frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin"
                    allowFullScreen></iframe>);
}
export default YoutubeVideo;