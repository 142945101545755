import HoursToReachLevelCalculator from "./HoursToReachLevelCalculator";
import React from "react";
import {Flex, Heading} from "@aws-amplify/ui-react";
import EiaFixedTable from "../tables/EiaFixedTable";

const nmDungeonHeader = ["Dungeon Tier", "Glyph XP Reward", "Monster Level", "Gold Reward", "XP Reward", "Obols Reward", "Runeshard Reward"];
const nmDungeonTable = [
        [1,10,55,10000,100000,0,0],
        [2,12,56,11600,102000,0,0],
        [3,14,57,13200,104000,0,0],
        [4,16,58,14800,106000,0,0],
        [5,18,59,16400,108000,0,0],
        [6,20,60,18000,110000,0,0],
        [7,22,61,19600,112000,0,0],
        [8,24,62,21200,114000,0,0],
        [9,27,63,22800,116000,0,0],
        [10,30,64,24400,118000,0,0],
        [11,33,65,26000,120000,0,0],
        [12,36,66,27600,122000,0,0],
        [13,39,67,29200,124000,0,0],
        [14,42,68,30800,126000,0,0],
        [15,45,69,32400,128000,0,0],
        [16,48,70,34000,130000,0,0],
        [17,51,71,35600,132000,0,0],
        [18,54,72,37200,134000,0,0],
        [19,58,73,38800,136000,0,0],
        [20,62,74,40400,138000,0,0],
        [21,66,75,42000,140000,0,0],
        [22,70,76,43600,142000,0,0],
        [23,74,77,45200,144000,0,0],
        [24,78,78,46800,146000,0,0],
        [25,82,79,48400,148000,0,0],
        [26,86,80,50000,150000,0,0],
        [27,90,81,51600,152000,0,0],
        [28,94,82,53200,154000,0,0],
        [29,98,83,54800,156000,0,0],
        [30,102,84,56400,158000,0,0],
        [31,106,85,58000,160000,0,0],
        [32,110,86,59600,162000,0,0],
        [33,114,87,61200,164000,0,0],
        [34,119,88,62800,166000,0,0],
        [35,124,89,64400,168000,0,0],
        [36,129,90,66000,170000,0,0],
        [37,134,91,67600,172000,0,0],
        [38,139,92,69200,174000,0,0],
        [39,144,93,70800,176000,0,0],
        [40,149,94,72400,178000,0,0],
        [41,154,95,74000,180000,0,0],
        [42,159,96,75600,182000,0,0],
        [43,164,97,77200,184000,0,0],
        [44,169,98,78800,186000,0,0],
        [45,174,99,80400,188000,0,0],
        [46,179,100,82000,189000,52,3],
        [47,184,101,83600,190000,54,3],
        [48,189,102,85200,191000,56,3],
        [49,194,103,86800,192000,58,3],
        [50,199,104,88400,193000,60,3],
        [51,204,105,88900,193500,62,3],
        [52,209,106,89400,194000,64,3],
        [53,215,107,89900,194500,66,3],
        [54,221,108,90400,195000,68,3],
        [55,227,109,90900,195500,70,3],
        [56,233,110,91400,196000,72,6],
        [57,239,111,91900,196500,74,6],
        [58,245,112,92400,197000,76,6],
        [59,251,113,92900,197500,78,6],
        [60,257,114,93400,198000,80,6],
        [61,263,115,93900,198500,82,6],
        [62,269,116,94400,199000,84,6],
        [63,275,117,94900,199500,86,6],
        [64,281,118,95400,200000,88,6],
        [65,287,119,95900,200500,90,6],
        [66,293,120,96400,201000,92,9],
        [67,299,121,96900,201500,94,9],
        [68,305,122,97400,202000,96,9],
        [69,311,123,97900,202500,98,9],
        [70,317,124,98400,203000,100,9],
        [71,323,125,98900,203500,102,9],
        [72,329,126,99400,204000,104,9],
        [73,335,127,99900,204500,106,9],
        [74,341,128,100400,205000,108,9],
        [75,347,129,100900,205500,110,9],
        [76,353,130,101400,206000,112,12],
        [77,359,131,101900,206500,114,12],
        [78,366,132,102400,207000,116,12],
        [79,373,133,102900,207500,118,12],
        [80,380,134,103400,208000,120,12],
        [81,387,135,103900,208500,122,12],
        [82,394,136,104400,209000,124,12],
        [83,401,137,104900,209500,126,12],
        [84,408,138,105400,210000,128,12],
        [85,415,139,105900,210500,130,12],
        [86,422,140,106400,211000,132,15],
        [87,429,141,106900,211500,134,15],
        [88,436,142,107400,212000,136,15],
        [89,443,143,107900,212500,138,15],
        [90,450,144,108400,213000,140,15],
        [91,457,145,108900,213500,142,15],
        [92,464,146,109400,214000,144,15],
        [93,471,147,109900,214500,146,15],
        [94,478,148,110400,215000,148,15],
        [95,485,149,110900,215500,150,15],
        [96,492,150,111400,216000,152,18],
        [97,499,151,111900,216500,154,18],
        [98,506,152,112400,217000,156,18],
        [99,513,153,112900,217500,158,18],
        [100,520,154,113400,218000,160,18]
    ]

;
const glyphHeader = ["Glyph Level", "Total Glyph XP", "Glyph XP to next level"];
const glyphData = [
        [1, 0, 8],
        [2, 8, 15],
        [3, 23, 24],
        [4, 47, 35],
        [5, 82, 46],
        [6, 128, 59],
        [7, 187, 72],
        [8, 259, 87],
        [9, 346, 104],
        [10, 450, 121],
        [11, 571, 140],
        [12, 711, 159],
        [13, 870, 180],
        [14, 1050, 203],
        [15, 1253, 226],
        [16, 1479, 251],
        [17, 1730, 276],
        [18, 2006, 303],
        [19, 2309, 332],
        [20, 2641, 361],
        [21, 3002, 0]
    ]
;

const aozTables = <Flex direction={"column"}>
    <Heading level={6}>Nightmare Dungeon Reward Table</Heading>
    <EiaFixedTable data = {nmDungeonTable} tableHeader={nmDungeonHeader}/>
    <Heading level={6}>Glyph XP Table</Heading>
    <EiaFixedTable data = {glyphData} tableHeader={glyphHeader}/>
</Flex>

const NmCalculator = function() {


    return(
        <HoursToReachLevelCalculator
            title={"Nightmare Dungeon Calculator"}
            lastUpdated={"2024/08/02"}
            initialCurrentLevel={1}
            initialTargetLevel={21}
            initialDungeonTier={60}
            initialDungeonClearMinutes={5}
            currentLevelText={"Glyph Current Level"}
            targetLevelText={"Glyph Target Level"}
            dungeonTierText={"Nightmare Dungeon tier you farm"}
            dungeonClearMinutesText={"Dungeon clear time (minutes)"}
            levelXpData={glyphData}
            dungeonXpRewardData={nmDungeonTable}
            tables={aozTables}
            levelMax={21}
            dungeonTierMax={100}
        />
    )
}

export default NmCalculator;