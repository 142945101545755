import React from "react";
import FullCalendar from '@fullcalendar/react'
import timeGridPlugin from '@fullcalendar/timegrid'
import rrulePlugin from '@fullcalendar/rrule'
import {useCookies} from "react-cookie";
import Utils from "../../commons/Utils/Utils";
import {Flex} from "@aws-amplify/ui-react";
import EiaTitle from "../../components/EiaTitle";

const whiteTextStyle = {
    color: 'white',
};

const isSmallScreen = Utils.isSmallScreen();
const initialView = isSmallScreen ? "timeGridDay" : "timeGridWeek";

const WorldBossCalendar = function (props) {

    const [cookies, setCookies, removeCookies] = useCookies(['darkMode']);
    const darkMode = cookies.darkMode === undefined ? true : cookies.darkMode;

    return (
        <Flex direction={"column"}>
            <EiaTitle title={"World Boss Calendar"} lastUpdated={"2024/08/02"}/>
            <div style={darkMode ? whiteTextStyle : null}>
                <FullCalendar
                    plugins={[rrulePlugin, timeGridPlugin]}
                    initialView={initialView}
                    sx={{
                        color: 'white',
                    }}
                    allDaySlot={false}
                    nowIndicator={true}
                    events={[
                        {
                            title: 'World Boss',
                            rrule: {
                                freq: 'minutely',
                                interval: 210,
                                dtstart: '2024-02-12T01:30:00Z',
                            },
                            duration: '00:30:00'
                        }
                    ]}
                    firstDay={1}
                />
            </div>
        </Flex>
    );
};
export default WorldBossCalendar;