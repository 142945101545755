import Utils from "./Utils/Utils";

let loggedInUser = null;
let appInitialized = false;
let notificationCounter = 0;

const isBrowserNotificationSupported = Utils.isBrowserNotificationSupported;

export default class LocalStorage {

    static updateNotificationCounter(newNotificationsCounter) {
        if(notificationCounter < newNotificationsCounter) {
            if(isBrowserNotificationSupported) {
                try {
                    new Notification('Easy Item Trading: ' + newNotificationsCounter + ' notifications')
                } catch (error) {
                    console.error(error);
                }
            }

            notificationCounter = newNotificationsCounter;
            return true;
        } else {
            notificationCounter = newNotificationsCounter;
            return false;
        }

    }

    static getUser() {
        return loggedInUser;
    }

    static login(user) {
        console.log("Logging in user ", user);
        loggedInUser = user;
    }

    static logout() {
        console.log("Logging out user ", loggedInUser);
        loggedInUser = null;
    }

    static initializeApp() {
        if(!appInitialized) {
            Date.prototype.toString = function() {
                return this.toLocaleString();
            }
            appInitialized = true;
        }
    }
}




