import React from "react";
import {Flex, SwitchField, TextField} from "@aws-amplify/ui-react";
import {remove} from "../../commons/Utils/ArrayUtils";
import EiaTable from "../tables/EiaTable";
import EiaDialog from "./EiaDialog";
import EiaCard from "../cards/EiaCard";

const tableHeader = [
    {field: "key", minWidth: 300},
    {field: "value", minWidth: 600}
];

function DetailsDialog(props) {
    const {onClose, open, details, title} = props;

    const [tableView, setTableView] = React.useState(false);

    const tableData = details ? remove(Object.keys(details), ["item"]).map(key => {
        let result = {
            id: key,
            key: key,
            value: details[key]
        }
        return result;
    }) : [];

    return (
        <EiaDialog open={open} onClose={onClose} title={title} maxWidth={"md"} fullWidth={true}>
            <Flex direction={"column"}>
                <SwitchField
                    label="Table View"
                    labelPosition="end"
                    isChecked={tableView}
                    onChange={(e) => {
                        setTableView(e.target.checked);
                    }}
                />
                {
                    tableView ?
                        <EiaTable data={tableData} tableHeader={tableHeader}/>
                        :
                        <EiaCard>
                            <Flex direction={"column"}>
                                {tableData.map((element, index) => {
                                    return <TextField descriptiveText={element.key} size={"small"} key={index} isReadOnly={true} value={element.value}/>
                                })}
                            </Flex>
                        </EiaCard>
                }
            </Flex>
        </EiaDialog>
    );
}

export default DetailsDialog;