import React from "react";
import EiaButton from "../EiaButton";
import EiaDialog from "./EiaDialog";
import EiaAuctionTableWithButtons from "../tables/EiaAuctionTableWithButtons";
import {BASE_BIDS_SELECT_HEADERS} from "../../commons/TableHeaders";
import {findByKeyValue} from "../../commons/Utils/ArrayUtils";
import {Flex} from "@aws-amplify/ui-react";
import Utils from "../../commons/Utils/Utils";
import D4ItemCard from "../d4/D4ItemCard";

const isSmallScreen = Utils.isSmallScreen();
const buttonsDirection = isSmallScreen ? "row" : "column";

function SelectBidderDialog(props) {
    const { onClose, open, auction, bids, accept, reject} = props;

    const [selectedBidId, setSelectedBidId] = React.useState([])
    const selectedBid = selectedBidId.length === 0 ? null : findByKeyValue(bids, "id", selectedBidId);
    const [updateAuctions, setUpdateAuctions] = React.useState(false);
    const [auctionFromBids, setAuctionFromBids] = React.useState(null)

    React.useEffect(() => {
        if(open) {
            //we get here once everytime the dialog opens
            setUpdateAuctions(false);
            setAuctionFromBids(auction);
        }
    }, [open])

    const auctionName = auctionFromBids ? auctionFromBids.itemName : "";
    const buyer = auctionFromBids ? auctionFromBids.buyer : null;

    const acceptBid = function() {
        accept(selectedBid.bidder, selectedBid.auctionId);
        onClose(false);
    }

    const rejectBid = function() {
        reject(selectedBid.bidder, selectedBid.auctionId);
        setUpdateAuctions(true);
    }

    const buttons = <Flex direction={"column"}>
        <Flex direction={buttonsDirection}>
            <EiaButton isFullWidth={true} colorTheme={"success"} disabled={selectedBid === null || buyer != null} onClick={acceptBid}>Accept</EiaButton>
            <EiaButton isFullWidth={true} colorTheme={"error"} disabled={selectedBid === null} onClick={rejectBid}>Reject</EiaButton>
        </Flex>
        {auctionFromBids ? <D4ItemCard item={auctionFromBids.item} amount={auctionFromBids.amount}/> : null}
    </Flex>

    return (
        <EiaDialog open={open} onClose={() => onClose(updateAuctions)} title={"Handle bids for " + auctionName} maxWidth={"xl"} fullWidth={true}>
            <EiaAuctionTableWithButtons
                buttons={buttons}
                selectedAuction={selectedBid}
                selectedAuctionId={selectedBidId}
                setSelectedAuctionId={setSelectedBidId}
                tableData={bids}
                tableHeader={BASE_BIDS_SELECT_HEADERS}
                noItemCard={true}
            />
        </EiaDialog>
    );
}

export default SelectBidderDialog;