import React from "react";
import {Message} from "@aws-amplify/ui-react";

const EiaInfoText = function(props) {
    const {value} = props;

    return (
        <Message colorTheme={"info"}>{value}</Message>
    )
}
export default EiaInfoText;