import HoursToReachLevelCalculator from "./HoursToReachLevelCalculator";
import React from "react";
import {Flex, Heading} from "@aws-amplify/ui-react";
import EiaFixedTable from "../tables/EiaFixedTable";

const pitDungeonHeader = ["Pit Tier", "Monster Level", "Gold Reward", "Obducite Reward", "Ingolith Reward", "Neathiron"
+ " Reward"];
const pitDungeonTable = [
    [1,100,82000,10,0,0],
    [2,101,83600,11,0,0],
    [3,102,85200,12,0,0],
    [4,103,86800,13,0,0],
    [5,104,88400,14,0,0],
    [6,105,90000,15,0,0],
    [7,106,91600,16,0,0],
    [8,107,93200,17,0,0],
    [9,108,94800,18,0,0],
    [10,109,96400,19,0,0],
    [11,110,98000,20,0,0],
    [12,111,99600,21,0,0],
    [13,112,101200,22,0,0],
    [14,113,102800,23,0,0],
    [15,114,104400,24,0,0],
    [16,115,106000,25,0,0],
    [17,116,107600,26,0,0],
    [18,117,109200,27,0,0],
    [19,118,110800,28,0,0],
    [20,119,112400,29,0,0],
    [21,120,114000,30,0,0],
    [22,121,115600,31,0,0],
    [23,122,117200,32,0,0],
    [24,123,118800,33,0,0],
    [25,124,120400,34,0,0],
    [26,125,122000,35,0,0],
    [27,126,123600,36,0,0],
    [28,127,125200,37,0,0],
    [29,128,126800,38,0,0],
    [30,129,128400,39,0,0],
]

const masterWorkingHeader = ["Masterwork Level", "Total Obducite (from level 0)", "Obducite to next level"];
const masterWorkingData = [
    [0,	0,	10],
    [1,	10,	20],
    [2,	30,	30],
    [3,	60,	40],
    [4,	100,	0],
    ]
;

const pitTables = <Flex direction={"column"}>
    <Heading level={6}>Pit Reward Table</Heading>
    <EiaFixedTable data = {pitDungeonTable} tableHeader={pitDungeonHeader}/>
    <Heading level={6}>Masterwork Table</Heading>
    <EiaFixedTable data = {masterWorkingData} tableHeader={masterWorkingHeader}/>
</Flex>

const PitCalculator_Obducite = function() {

    return(
        <HoursToReachLevelCalculator
            title={"Pit Masterworking Calculator (Obducite)"}
            lastUpdated={"2024/08/02"}
            initialCurrentLevel={0}
            initialTargetLevel={4}
            initialDungeonTier={30}
            initialDungeonClearMinutes={3}
            currentLevelText={"Masterwork Current Level"}
            targetLevelText={"Masterwork Target Level"}
            dungeonTierText={"Pit tier you run"}
            dungeonClearMinutesText={"Pit clear time (minutes)"}
            levelXpData={masterWorkingData}
            dungeonXpRewardData={pitDungeonTable}
            tables={pitTables}
            levelMax={4}
            dungeonTierMax={30}
            rewardToLevelIndex={3}
            rewardName={"obducite"}
            doRoundRewardToK={false}
        />
    )
}

export default PitCalculator_Obducite;