import React from "react";
import {canHaveArmor, canHaveDps, CLASSES} from "../../commons/enums/D4Enums";
import {findIndexIgnoreCase} from "../../commons/Utils/ArrayUtils";
import EiaTextField from "../EiaTextField";
import {testInteger, testShortFloat} from "../../commons/Utils/RegExUtils";
import EiaSelect from "../EiaSelect";

//currently we only support one number X_0 for stats but there could come more in the future
const floatValueIndex = 0;

const D4ItemBaseStatSelect = function(props) {
    const {baseItem, baseStats, setBaseStats, searchDefaults} = props;

    const defaultLevelRequirement = searchDefaults ? 100 : 0;
    const itemPowerLabel = searchDefaults ? "Item Power (min)" : "Item Power";
    const levelRequirementLabel = searchDefaults ? "Level Requirement (max)" : "Level Requirement";
    const armorLabel = searchDefaults ? "Armor (min)" : "Armor";
    const dpsLabel = searchDefaults ? "Damage per Second (min)" : "Damage per Second";

    const isBaseStats = Object.keys(baseStats).length > 0;

    const armor = isBaseStats ? baseStats.armor : 0;
    const dps = isBaseStats ? baseStats.dps : 0;
    const itemPower = isBaseStats ? baseStats.itemPower : 0;
    const levelRequirement = isBaseStats ? baseStats.levelRequirement : defaultLevelRequirement;
    const classRequirement = isBaseStats ? baseStats.classRequirement : "Any";
    const classRequirementIndex = findIndexIgnoreCase(CLASSES, classRequirement);

    React.useEffect(() => {
        const temp = {};
        temp.armor = armor;
        temp.dps = dps;
        temp.levelRequirement = levelRequirement;
        temp.classRequirement = classRequirement;
        temp.itemPower = itemPower;
        setBaseStats(temp);
    }, []);

    function setBaseStat(key, value) {
        const temp = Object.assign([], baseStats);

        temp[key] = value;

        setBaseStats(temp);
    }

    function setClassIndex(index) {
        const temp = Object.assign([], baseStats);

        temp.classRequirement = CLASSES[index];

        setBaseStats(temp);
    }

    return (
            <div>
                    <EiaTextField validator={testInteger} onChange={(e) => setBaseStat("itemPower", e.currentTarget.value)} label={itemPowerLabel} value={itemPower}/>
                    <EiaSelect title={"Class Requirement"} values={CLASSES} value={classRequirementIndex} onChange={(e) => setClassIndex(e.target.value)}/>
                    <EiaTextField validator={testInteger} onChange={(e) => setBaseStat("levelRequirement", e.currentTarget.value)} label={levelRequirementLabel} value={levelRequirement}/>
                    {
                        canHaveDps(baseItem, baseStats) ? <EiaTextField validator={testShortFloat} onChange={(e) => setBaseStat("dps", e.currentTarget.value)} label={dpsLabel} value={dps}/> : null
                    }
                    {
                        canHaveArmor(baseItem, baseStats) ? <EiaTextField validator={testShortFloat} onChange={(e) => setBaseStat("armor", e.currentTarget.value)} label={armorLabel} value={armor}/> : null
                    }
            </div>
    )
}
export default D4ItemBaseStatSelect;