import {Link} from "@aws-amplify/ui-react";
import React from "react";
import {useNavigate} from "react-router-dom";
import Utils from "../commons/Utils/Utils";
import * as Navigation from "../commons/enums/Navigation";

const isSmallScreen = Utils.isSmallScreen();

export const EiaNavigationLink=function(props) {

    const {relativeLink, title} = props;

    const navigateWithRouter = useNavigate();

    const navigate=function() {
        if(isSmallScreen) {
            Utils.scrollToStickyNavigationBar();
        }

        navigateWithRouter("/"+relativeLink);
    }

    return <Link textDecoration="underline" onClick={navigate}>{title ? title : relativeLink}</Link>
}

export default EiaNavigationLink;

export const Welcome = <EiaNavigationLink key={"welcome"} relativeLink={Navigation.WELCOME} title={"Welcome"} />

export const Search = <EiaNavigationLink key={"search"} relativeLink={Navigation.SEARCH} title={"Search"} />
export const BestPrices = <EiaNavigationLink key={"best-prices"} relativeLink={Navigation.BEST_PRICES} title={"Best Prices"} />
export const Sell = <EiaNavigationLink key={"sell"} relativeLink={Navigation.SELL} title={"Sell"} />

export const MySales = <EiaNavigationLink key={"my-sales"} relativeLink={Navigation.MY_SALES} title={"My Sales"} />

export const Notifications = <EiaNavigationLink key={"notifications"} relativeLink={Navigation.NOTIFICATIONS} title={"Notifications"} />
export const Builds = <EiaNavigationLink key={"builds"} relativeLink={Navigation.BUILDS} title={"Builds"} />
export const Tools = <EiaNavigationLink key={"tools"} relativeLink={Navigation.TOOLS} title={"Tools"} />
export const FAQ = <EiaNavigationLink key={"faq"} relativeLink={Navigation.FAQ} title={"FAQ"} />

export const D4_Calendar = <EiaNavigationLink key={"d4-calendar"} relativeLink={Navigation.TOOLS_D4_CALENDAR} title={"D4 Calendar"} />


// export const WELCOME = "welcome";
// export const NEWS = "news";
// export const SEARCH = "search";
// export const BEST_PRICES = "best-prices";
// export const SELL = "sell";
// export const MY_SALES = "my-sales";
// export const MY_BIDS = "my-bids";
// export const MY_HISTORY = "my-history";
// export const NOTIFICATIONS = "notifications";
// export const TOOLS = "tools";