import React from "react";
import EiaCard from "../cards/EiaCard";
import {Divider, Flex, Grid, Heading} from "@aws-amplify/ui-react";
import EiaDrawer from "./EiaDrawer";
import D4ItemBaseStatSelect from "../d4/D4ItemBaseStatSelect";
import {contains} from "../../commons/Utils/ArrayUtils";
import {
    canHaveAspects,
    canHaveUniqueAffix,
    ITEM_NAMES,
    ITEM_RARITIES,
    ITEM_SUB_TYPES,
    ITEM_SUB_TYPES_WITHOUT_STATS,
    ITEM_TYPES,
    ITEM_TYPES_WITH_RARITY
} from "../../commons/enums/D4Enums";
import CurrencySelects from "../CurrencySelects";
import EiaButton from "../EiaButton";
import Utils from "../../commons/Utils/Utils";
import D4ItemSelects from "../d4/D4ItemSelects";
import D4AffixSelects from "../d4/D4AffixSelects";

const isSmallScreen = Utils.isSmallScreen();
const searchDrawerColumnSpans = isSmallScreen ? 5 : 1;

const FiltersDrawer = function (props) {

    const {currency, setCurrency, baseItem, setBaseItem, baseStats, setBaseStats, itemStats, setItemStats, setAlert, searchAuctions, isLoading,
        aspects, setAspects, uniqueAffixes, setUniqueAffixes} = props;

    const itemCanHaveAspects = canHaveAspects(baseItem);
    const itemCanHaveUniqueAffix = canHaveUniqueAffix(baseItem);

    const [openDrawer, setOpenDrawer] = React.useState(false);


    const [updateStatsSelects, setUpdateStatsSelects] = React.useState(false);
    const [updateAspectSelects, setUpdateAspectSelects] = React.useState(false);
    const [updateUniqueAffixSelects, setUpdateUniqueAffixSelects] = React.useState(false);

    const updateBaseItem = function(value) {
        setBaseItem(value)
        setUpdateStatsSelects(true)
        setUpdateAspectSelects(true);
        setUpdateUniqueAffixSelects(true);
    }

    const resetFilters = function() {
        const itemType = ITEM_TYPES[0];
        const itemSubTypes = ITEM_SUB_TYPES[itemType]
        const itemSubType = itemSubTypes[0];
        const itemNames = ITEM_NAMES[itemSubType];
        const isItemWithRarity = contains(ITEM_TYPES_WITH_RARITY, itemType);

        const initialBaseItem = {};
        initialBaseItem.itemType = itemType;
        initialBaseItem.itemSubType = itemSubType;
        initialBaseItem.name = itemNames[0];
        initialBaseItem.amount = 1;
        initialBaseItem.title = "";
        if(isItemWithRarity) {
            initialBaseItem.rarity = ITEM_RARITIES[0];
        }

        updateBaseItem(initialBaseItem)

        setCurrency({})
        setAspects([])
        setUniqueAffixes([])
        setBaseStats([])
        setItemStats([])
    }

    return(
        <div>
            <EiaDrawer open={openDrawer} setOpen={setOpenDrawer} title={"Filters"}>
                <Grid templateColumns="1fr 1fr 1fr 1fr 1fr">
                    <EiaCard columnSpan={searchDrawerColumnSpans}>
                        <D4ItemSelects showAmount={false} baseItem={baseItem} setBaseItem={updateBaseItem} removeAny={false} titleEditable={true}/>
                    </EiaCard>
                    <EiaCard columnSpan={searchDrawerColumnSpans}>
                        <Heading level={5}>Basic Stats</Heading>
                        <D4ItemBaseStatSelect baseItem={baseItem} baseStats={baseStats} setBaseStats={setBaseStats} searchDefaults={true}/>
                    </EiaCard>
                    {
                        contains(ITEM_SUB_TYPES_WITHOUT_STATS, baseItem.itemSubType) ? null :
                            <EiaCard columnSpan={searchDrawerColumnSpans}>
                                <D4AffixSelects
                                    setAlert={setAlert}
                                    size={4}
                                    stats={itemStats}
                                    setStats={setItemStats}
                                    updateStatsSelects={updateStatsSelects}

                                    aspects={aspects}
                                    setAspects={setAspects}
                                    updateAspectSelects={updateAspectSelects}
                                    itemCanHaveAspects={itemCanHaveAspects}

                                    uniqueAffixes={uniqueAffixes}
                                    setUniqueAffixes={setUniqueAffixes}
                                    updateUniqueAffixesSelects={updateUniqueAffixSelects}
                                    itemCanHaveUniqueAffixes={itemCanHaveUniqueAffix}
                                />
                            </EiaCard>
                    }
                    <EiaCard columnSpan={searchDrawerColumnSpans}>
                        <Heading level={5}>Currency</Heading>
                        <Flex direction={"column"}>
                            <CurrencySelects currency={currency} setCurrency={setCurrency} addAnyCurrencyType={true}/>
                            <EiaButton isFullWidth colorTheme={"success"} onClick={() => {
                                setOpenDrawer(false);
                                searchAuctions();
                            }} disabled={isLoading}>Search</EiaButton>
                            <EiaButton isFullWidth onClick={resetFilters}>Reset Filters</EiaButton>
                            {
                                isSmallScreen ? <Divider/> : null
                            }
                            {
                                isSmallScreen ? <EiaCard columnSpan={searchDrawerColumnSpans}>
                                    <Flex direction={"row"} justifyContent={"right"}>
                                        <EiaButton onClick={() => setOpenDrawer(false)}>Close</EiaButton>
                                    </Flex>
                                </EiaCard> : null
                            }
                        </Flex>
                    </EiaCard>

                </Grid>
            </EiaDrawer>
            <EiaButton isFullWidth onClick={() => setOpenDrawer(true)} tooltip={"Configure filters to refine your search"}>Filters ...</EiaButton>
        </div>
    )
}
export default FiltersDrawer;