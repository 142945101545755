import {contains, containsIgnoreCase} from "../Utils/ArrayUtils";

const summonings = ["Tormented Duriel Set", "Tormented Andariel Set", "Duriel Set", "Andariel Set", "Exquisite Blood",
    "Malignant Heart", "Living Steel", "Distilled Fear", "Mucus-Slick Egg", "Shard of Agony", "Stygian Stone", "Pincushioned Doll",
    "Sandscorched Shackles"];

const summoningDescriptions = {
    "Tormented Duriel Set": ["Mucus-Slick Egg (6)", "Shard of Agony (6)", "Stygian Stone (1)"],
    "Tormented Andariel Set": ["Sandscorched Shackles (6)", "Pincushioned Doll (6)", "Stygian Stone (1)"],
    "Tormented Varshan Set": ["Malignant Heart (15)", "Stygian Stone (1)"],
    "Duriel Set": ["Mucus-Slick Egg (2)", "Shard of Agony (2)"],
    "Andariel Set": ["Sandscorched Shackles (2)", "Pincushioned Doll (2)"],
}

//updated 2024-05-15
const elixirs = [
    "Acrobatics Elixir",
    "Assault Elixir",
    "Crushing Elixir",
    "Elixir of Advantage II",
    "Elixir of Advantage",
    "Elixir of Antivenin",
    "Elixir of Beast-slaying",
    "Elixir of Cold Resistance II",
    "Elixir of Cold Resistance",
    "Elixir of Combatant Fortune",
    "Elixir of Cruelty",
    "Elixir of Demon-slaying",
    "Elixir of Destruction II",
    "Elixir of Destruction",
    "Elixir of Fire Resistance II",
    "Elixir of Fire Resistance",
    "Elixir of Fortitude II",
    "Elixir of Fortitude",
    "Elixir of Holy Bolts",
    "Elixir of Iron Barbs II",
    "Elixir of Iron Barbs",
    "Elixir of Lightning Resistance II",
    "Elixir of Lightning Resistance",
    "Elixir of Magic Resist",
    "Elixir of Man-slaying",
    "Elixir of Momentum",
    "Elixir of Poison Resistance II",
    "Elixir of Poison Resistance",
    "Elixir of Precision II",
    "Elixir of Precision",
    "Elixir of Resourcefulness II",
    "Elixir of Resourcefulness",
    "Elixir of Savagery",
    "Elixir of Shadow Resistance II",
    "Elixir of Shadow Resistance",
    "Elixir of Undead-slaying",
    "Expertise Elixir",
    "Expired Elixir of Death Evasion",
    "Gileon's Brew",
    "Heady Acrobatics Elixir",
    "Heady Assault Elixir",
    "Heady Crushing Elixir",
    "Heady Third Eye Elixir",
    "Heady Vampirism Elixir",
    "Iron Barb Elixir",
    "Iron Skin Elixir",
    "Potent Acrobatics Elixir",
    "Potent Assault Elixir",
    "Potent Crushing Elixir",
    "Potent Elixir of Cold Resistance",
    "Potent Elixir of Fire Resistance",
    "Potent Elixir of Lightning Resistance",
    "Potent Elixir of Poison Resistance",
    "Potent Elixir of Shadow Resistance",
    "Potent Iron Barb Elixir",
    "Potent Precision Elixir",
    "Potent Third Eye Elixir",
    "Potent Vampirism Elixir",
    "Precision Elixir",
    "Profane Mindcage",
    "Strong Acrobatics Elixir",
    "Strong Assault Elixir",
    "Strong Crushing Elixir",
    "Strong Elixir of Cold Resistance",
    "Strong Elixir of Fire Resistance",
    "Strong Elixir of Lightning Resistance",
    "Strong Elixir of Poison Resistance",
    "Strong Elixir of Shadow Resistance",
    "Strong Iron Barb Elixir",
    "Strong Precision Elixir",
    "Strong Third Eye Elixir",
    "Strong Vampirism Elixir",
    "Third Eye Elixir",
    "Vampirism Elixir",
    "Weak Acrobatics Elixir",
    "Weak Assault Elixir",
    "Weak Crushing Elixir",
    "Weak Iron Barb Elixir",
    "Weak Third Eye Elixir",
    "Weak Vampirism Elixir",
]

const gems = [
    "Any Royal Gem",
    "Royal Diamond",
    "Royal Emerald",
    "Royal Ruby",
    "Royal Skull",
    "Royal Topas",
    "Royal Amethyst",
    "Royal Sapphire"
]

const consumables = ["Abyssal Scroll"]

const summoning = "Summoning";
const elixir = "Elixir";
const gem = "Gem";
const consumable = "Consumable";

export const getSummoningDescription = function (summoning) {
    const descriptions = summoningDescriptions[summoning]
    if (descriptions) {
        return descriptions;
    } else {
        return [];
    }
}

export const PLATFORMS = ["ALL"];
export const REGIONS = ["EUROPE", "TEST"];
export const MODES = ["SOFTCORE", "HARDCORE"];
export const REALM = ["SEASON", "ETERNAL"];

export const CURRENCY_TYPES = ["Gold", summoning, elixir, gem, consumable]
export const CURRENCY_NAMES = {
    "Gold": ["Million Gold"],
    "Summoning": summonings,
    "Elixir": elixirs,
    "Gem": gems,
    "Consumable": consumables
}

export const ITEM_RARITIES = ["Any", "Unique", "Legendary", "Rare", "Magic", "Normal"];
export const DEFAULT_ITEM_RARITY = ITEM_RARITIES[2];

//do not change the order since the indexes get used by this fixed order
export const CLASSES = ["Any", "Druid", "Rogue", "Necromancer", "Barbarian", "Sorcerer"];

export const ITEM_TYPES = ["Any", "Weapon", "Armor", "Jewelry", "Misc", "Services"];

export const ITEM_TYPES_WITH_RARITY = ["Any", "Weapon", "Armor", "Jewelry"]
export const ITEM_TYPES_WITH_DPS = ["Weapon"]
export const ITEM_TYPES_WITH_ARMOR = ["Armor"]

export const canHaveAspects = function (baseItem) {
    return baseItem !== undefined && baseItem != null && (baseItem.rarity === ITEM_RARITIES[0] || baseItem.rarity === ITEM_RARITIES[2]);
}

export const canHaveUniqueAffix = function (baseItem) {
    return baseItem !== undefined && baseItem != null && (baseItem.rarity === ITEM_RARITIES[0] || baseItem.rarity === ITEM_RARITIES[1]);
}

export const isLegendaryOrUnique = function (baseItem) {
    return baseItem !== undefined && baseItem != null && (baseItem.rarity === ITEM_RARITIES[1] || baseItem.rarity === ITEM_RARITIES[2]);
}

export const canHaveDps = function (baseItem, baseStats) {
    if (baseStats && baseStats.dps > 0) {
        return true;
    }

    return contains(ITEM_TYPES_WITH_DPS, baseItem.itemType) && baseItem.name !== "Shield";
}

export const canHaveArmor = function (baseItem, baseStats) {
    if (baseStats && baseStats.armor > 0) {
        return true;
    }

    return contains(ITEM_TYPES_WITH_ARMOR, baseItem.itemType) || baseItem.name === "Shield";
}

export const ITEM_SUB_TYPES = {
    "Any": ["Any"],
    "Weapon": ["Two-Handed", "One-Handed", "Off-Hand", "Bow", "Crossbow"],
    "Armor": ["Helm", "Chest", "Gloves", "Pants", "Boots"],
    "Jewelry": ["Amulet", "Ring"],
    "Misc": [summoning, elixir, gem, consumable],
    "Services": ["Boss Kill", "Leveling", "World Tier Unlock"],
}

export const isSummoningMaterial = function (item) {
    return item && item.subType === "Summoning";
}

export const ITEM_SUB_TYPES_WITH_AMOUNT = [summoning, elixir, gem, consumable]
export const ITEM_SUB_TYPES_WITHOUT_STATS = [summoning, elixir, gem, consumable, "Boss Kill", "World Tier"
+ " Unlock", "Leveling"]

export const ITEM_NAMES = {
    "Leveling": ["Any", "1-50", "1-30", "30-50", "50-60", "60-70", "70-80"],
    "World Tier Unlock": ["Any", "T3", "T4", "T3+T4"],
    "Boss Kill": ["Any", "Uber Lilith", "Uber Malphas", "Duriel (seller mats)", "Duriel (buyer mats)", "Varshan (seller mats)", "Varshan"
    + " (buyer mats)", "Grigoire (seller mats)", "Grigoire (buyer mats)"],
    "Any": ["Any"],
    "Two-Handed": ["Any", "Axe", "Mace", "Polearm", "Staff", "Sword", "Scythe"],
    "One-Handed": ["Any", "Axe", "Mace", "Staff", "Sword", "Scythe", "Dagger", "Wand"],
    "Bow": ["Any", "Bow"],
    "Crossbow": ["Any", "Crossbow"],
    "Off-Hand": ["Any", "Book", "Focus", "Shield", "Totem"],

    "Helm": ["Any", "Helm"],
    "Chest": ["Any", "Chest"],
    "Gloves": ["Any", "Gloves"],
    "Pants": ["Any", "Pants"],
    "Boots": ["Any", "Boots"],

    "Amulet": ["Any", "Amulet"],
    "Ring": ["Any", "Ring"],

    "Summoning": ["Any"].concat(summonings),
    "Elixir": ["Any"].concat(elixirs),
    "Gem": ["Any"].concat(gems),
    "Consumable": ["Any"].concat(consumables)
}

export const getCurrencyTypeByCurrency = function (currency) {
    let result;
    Object.keys(CURRENCY_NAMES).forEach(key => {
        if (containsIgnoreCase(CURRENCY_NAMES[key], currency)) {
            result = key;
        }
    });
    return result;
}

export const getItemTypesByName = function (name) {
    let subTypes = [];
    Object.keys(ITEM_NAMES).forEach(key => {
        if (containsIgnoreCase(ITEM_NAMES[key], name)) {
            subTypes.push(key)
        }
    });

    let result = [];

    subTypes.forEach(subType => {
        Object.keys(ITEM_SUB_TYPES).forEach(key => {
            if (containsIgnoreCase(ITEM_SUB_TYPES[key], subType)) {
                result.push({
                    itemType: key,
                    itemSubType: subType
                })
            }
        })
    })

    return result;
}