import {Divider, Flex, Grid, Heading} from "@aws-amplify/ui-react";
import Compressor from 'compressorjs';
import React from "react";
import {useCookies} from "react-cookie";
import {contains, findValueIgnoreCase, remove} from "../commons/Utils/ArrayUtils";
import {convertToD4ItemForRequest} from "../commons/Converter";
import LocalStorage from "../commons/LocalStorage";
import Utils from "../commons/Utils/Utils";
import {basicApiRekognizeItem, userApiGetUser} from "../commons/api/BasicApi";
import {d4CreateAuction} from "../commons/api/D4Api";
import {
    canHaveAspects,
    canHaveUniqueAffix,
    CLASSES,
    DEFAULT_ITEM_RARITY,
    getItemTypesByName,
    isLegendaryOrUnique,
    ITEM_RARITIES,
    ITEM_SUB_TYPES_WITH_AMOUNT,
    ITEM_SUB_TYPES_WITHOUT_STATS,
} from "../commons/enums/D4Enums";
import CurrencySelects from "../components/CurrencySelects";
import EiaButton from "../components/EiaButton";
import EiaDropZone from "../components/EiaDropZone";
import EiaHelpButton from "../components/EiaHelpButton";
import EiaImage from "../components/EiaImage";
import AlertCard from "../components/cards/AlertCard";
import EiaCard from "../components/cards/EiaCard";
import D4AffixSelects from "../components/d4/D4AffixSelects";
import D4ImplicitStatSelects from "../components/d4/D4ImplicitStatSelects";
import D4ItemBaseStatSelect from "../components/d4/D4ItemBaseStatSelect";
import D4ItemCard from "../components/d4/D4ItemCard";
import D4ItemSelects from "../components/d4/D4ItemSelects";
import LoadingDialog from "../components/dialogs/LoadingDialog";
import helpBasicStats from './../resources/help/helpBasicStats.jpg';
import helpDetectionError from './../resources/help/helpDetectionError.jpg';
import helpDetectionFix from './../resources/help/helpDetectionFix.jpg';
import helpGameplaySettings from './../resources/help/helpGameplaySettings.jpg';
import helpItemAutoDetection1 from './../resources/help/helpItemAutoDetection1.jpg';
import helpItemAutoDetection2 from './../resources/help/helpItemAutoDetection2.jpg';
import itemPlaceHolder from './../resources/itemPlaceHolder.jpg';
import PriceCheckDialog from "../components/dialogs/PriceCheckDialog";
import YoutubeVideo from "../components/YoutubeVideo";

const isSmallScreen = Utils.isSmallScreen();
const maxAllowedImageUploadSize = 1000000
const initItemPreview = {
    name: "Axe",
    rarity: DEFAULT_ITEM_RARITY,
    itemType: "Weapon",
    itemSubType: "Two-Handed",
    itemPower: 0,
    dps: 0,
    armor: 0,
    levelRequirement: 0,
    classRequirement: "",
    amount: 1
}

const SellPage = function (props) {
    const {user, platform, region} = props;

    const [cookies, setCookies, removeCookies] = useCookies(['mode', 'realm']);
    const mode = cookies.mode;
    const realm = cookies.realm;

    const [openPriceCheckDialog, setOpenPriceCheckDialog] = React.useState(false);

    const [currency, setCurrency] = React.useState({})

    const [updateImplicitStatsSelects, setUpdateImplicitStatsSelects] = React.useState(false);
    const [updateStatsSelects, setUpdateStatsSelects] = React.useState(false);
    const [updateAspectSelects, setUpdateAspectSelects] = React.useState(false);
    const [updateUniqueAffixesSelects, setUpdateUniqueAffixestSelects] = React.useState(false);

    const [itemPreview, setItemPreview] = React.useState(initItemPreview)

    const baseItem = itemPreview === null ? {} : {
        title: itemPreview.title,
        name: itemPreview.name,
        rarity: itemPreview.rarity,
        itemType: itemPreview.type ? itemPreview.type : itemPreview.itemType,
        itemSubType: itemPreview.subType ? itemPreview.subType : itemPreview.itemSubType,
        amount: itemPreview.amount
    }
    const setBaseItem = function (newValue) {
        const temp = Object.assign({}, itemPreview);
        temp.name = newValue.name;
        temp.type = newValue.itemType;
        temp.itemType = newValue.itemType;
        temp.subType = newValue.itemSubType;
        temp.itemSubType = newValue.itemSubType;
        temp.rarity = newValue.rarity;
        temp.amount = newValue.amount;
        if (!canHaveAspects(temp)) {
            temp.aspects = [];
        }

        setItemPreview(temp);

        setUpdateImplicitStatsSelects(true)
        setUpdateStatsSelects(true)
        setUpdateAspectSelects(true);
        setUpdateUniqueAffixestSelects(true);
    }

    const baseStats = itemPreview == null ? {} : {
        itemPower: itemPreview.itemPower,
        dps: itemPreview.dps,
        armor: itemPreview.armor,
        levelRequirement: itemPreview.levelRequirement,
        classRequirement: itemPreview.classRequirement
    }
    const setBaseStats = function (newValue) {
        const temp = Object.assign({}, itemPreview);
        temp.itemPower = newValue.itemPower;
        temp.dps = newValue.dps;
        temp.armor = newValue.armor;
        temp.levelRequirement = newValue.levelRequirement;
        temp.classRequirement = newValue.classRequirement;
        setItemPreview(temp);
    }

    const implicitStats = itemPreview.basicStats === undefined ? [] : itemPreview.basicStats;
    const setImplicitStats = function (newValue) {
        const temp = Object.assign({}, itemPreview);
        temp.basicStats = newValue;
        setItemPreview(temp);
    }

    const stats = itemPreview.stats === undefined ? [] : itemPreview.stats;
    const setStats = function (newValue) {
        const temp = Object.assign({}, itemPreview);
        temp.stats = newValue;
        setItemPreview(temp);
    }

    const aspects = itemPreview.aspects === undefined ? [] : itemPreview.aspects;
    const setAspects = function (newValue) {
        const temp = Object.assign({}, itemPreview);
        temp.aspects = newValue;
        setItemPreview(temp);
    }

    const uniqueAffixes = itemPreview.uniqueAffixes === undefined ? [] : itemPreview.uniqueAffixes;
    const setUniqueAffixes = function (newValue) {
        const temp = Object.assign({}, itemPreview);
        temp.uniqueAffixes = newValue;
        setItemPreview(temp);
    }

    const [isLoading, setIsLoading] = React.useState(false)
    const [alert, setAlert] = React.useState(null)

    const [imageBytes, setImageBytes] = React.useState(null);

    const itemCanHaveAspects = canHaveAspects(baseItem);
    const itemCanHaveUniqueAffixes = canHaveUniqueAffix(baseItem);

    const checkUserAndSell = function () {
        setIsLoading(true)

        const request = {
            requestedUser: LocalStorage.getUser()
        }

        userApiGetUser(request, function (response) {
            if (response.user && response.user.battleTag) {
                sell();
            } else {
                setAlert(["error", "You need to set a battle tag to sell items. Go to your settings and update a battle tag."]);
                setIsLoading(false);
            }
        }, function (error) {
            setAlert(["error", error.message]);
            setIsLoading(false);
        })
    }

    const sell = function () {
        setIsLoading(true)

        const itemForRequest = convertToD4ItemForRequest(baseItem, baseStats, implicitStats, stats, aspects, uniqueAffixes);
        const isItemWithStats = !contains(ITEM_SUB_TYPES_WITHOUT_STATS, itemForRequest.subType);

        if (isItemWithStats && itemForRequest.stats.length === 0) {
            setAlert(["error", "Please use an english item screenshot or enter the item stats manually below"])
            setIsLoading(false);
            return;
        }

        const request = {
            platform: platform,
            region: region,
            mode: mode,
            realm: realm,
            currency: currency.name,
            item: itemForRequest,
            amount: contains(ITEM_SUB_TYPES_WITH_AMOUNT, itemForRequest.subType) ? baseItem.amount : 1,
            price: currency.price
        }

        d4CreateAuction(request, function (response) {
            setAlert(["success", ("Successfully created auction with item name " + response.auction.item.name + " for"
                + " " + response.auction.price + " " + response.auction.currency)]);
            setIsLoading(false)
        }, function (error) {
            setAlert(["error", error.message]);
            setIsLoading(false)
        })
    }

    const resetItem = function () {
        setUpdateStatsSelects(false);
        setUpdateImplicitStatsSelects(false);
        setUpdateAspectSelects(false);
        setUpdateUniqueAffixestSelects(false);
        setBaseStats({});
        setImplicitStats([]);
        setStats([]);
        setAspects([]);
        setUniqueAffixes([]);
        setItemPreview(initItemPreview);
    }

    const handleItemRekognition = function (acceptedFiles) {
        resetItem();
        setIsLoading(true)

        if (acceptedFiles.length > 1) {
            alertErrorAndStopLoading("Cannot read multiple files");
            return;
        }

        if (acceptedFiles.length < 1) {
            alertErrorAndStopLoading("No file to read");
            return;
        }

        acceptedFiles.forEach((file) => {

            if (file.size === 0) {
                alertErrorAndStopLoading("File size is 0");
            } else {
                verifyCompressAndUpload(file, 1.0, null);
            }
        })
    }

    const verifyCompressAndUpload = function (file, quality, compressedFile) {
        let currentSize = compressedFile ? compressedFile.size : file.size;
        if (currentSize > maxAllowedImageUploadSize) {
            let newQuality = quality - 0.5;
            if (newQuality < 0) {
                alertErrorAndStopLoading("File size is too big. Max allowed is 1 MB. Compression did not help");
                return;
            }
            console.log("Compression try with quality " + newQuality + " and size ", file.size);
            new Compressor(file, {
                quality: newQuality,
                success: (compressedResult) => {
                    console.log("Compression file size ", compressedResult.size);
                    verifyCompressAndUpload(file, newQuality, compressedResult);
                },
                error(err) {
                    alertErrorAndStopLoading("File size is too big. Max allowed is 1 MB. Compression failed",
                        err.message);
                },
            });
        } else {
            if (compressedFile) {
                uploadFileForRecognition(compressedFile);
            } else {
                uploadFileForRecognition(file);
            }
        }
    }

    const uploadFileForRecognition = function (file) {
        console.log("Uploading file size", file.size)
        const reader = new FileReader()

        reader.onabort = () => alertErrorAndStopLoading("Reading file aborted");
        reader.onerror = () => alertErrorAndStopLoading("Reading file failed");
        reader.onload = () => {
            const imageBytesBase64Encoded = btoa(reader.result)
            setImageBytes(imageBytesBase64Encoded);

            const request = {
                game: "D4",
                imageBytes: imageBytesBase64Encoded
            }

            basicApiRekognizeItem(request, function (response) {
                setAlert(["success", "Successfully read item from image"]);
                setRecognizedItem(response.d4Item);
                setIsLoading(false)
            }, function (error) {
                alertErrorAndStopLoading(error.message);
            })
        }

        reader.readAsBinaryString(file)
    }

    const alertErrorAndStopLoading = function (message) {
        setAlert(["error", message]);
        setIsLoading(false)
    }

    const getClassRequirement = function (value) {
        const classes = remove(CLASSES, ["Any"])
        const result = findValueIgnoreCase(classes, value);
        if (result) {
            return result;
        } else {
            setAlert(["warning", "Successfully read item from image - please double check Class Requirement"]);
            return "";
        }
    }

    const setRecognizedItem = function (d4Item) {
        let error = false;
        let warning = false;

        if (!d4Item) {
            error = "Failed detecting item from image";
        }

        if (d4Item.accountBound === true) {
            error = "Account bound items are not tradeable.";
        }

        if (!d4Item.itemPower) {
            d4Item.itemPower = 0;
        }

        if (!d4Item.dps) {
            d4Item.dps = 0;
        }

        if (!d4Item.levelRequirement) {
            d4Item.levelRequirement = 0;
        }

        if (!d4Item.armor) {
            d4Item.armor = 0;
        }

        if (d4Item.classRequirement) {
            d4Item.classRequirement = getClassRequirement(d4Item.classRequirement);
        } else {
            d4Item.classRequirement = "";
        }

        if (d4Item.name) {
            if (d4Item.type) {
                //nothing to do
            } else {
                //we have to search for the type because the backend item recognition mostly does not set type/subtype
                // for now
                let typeObjects = getItemTypesByName(d4Item.name);
                if (typeObjects) {
                    d4Item.type = typeObjects[0].itemType
                    d4Item.subType = typeObjects[0].itemSubType

                    if (typeObjects.length > 1) {
                        //we switch the 2h type to 1h type because we should detect 2h type from backend
                        if (d4Item.subType === "Two-Handed") {
                            d4Item.subType = "One-Handed";
                        }
                    }
                }
            }
        }

        if (d4Item.stats) {
            if (d4Item.stats.length > 4) {
                warning = "Screenshot item has more than 4 affix stats what is not supported. Fix it manually.";
                d4Item.stats = d4Item.stats.slice(1, 5);
            }
        }

        //TODO: rename backend or frontend so we do not have a missmatch here
        d4Item.uniqueAffixes = d4Item.uniqueStats;
        if (d4Item.rarity === ITEM_RARITIES[2]) {
            const noAspects = d4Item.aspects === undefined || d4Item.aspects === null || d4Item.aspects.length === 0;
            if (noAspects) {
                warning = "No aspect detected - select an aspect manually";
            }
        } else if (d4Item.rarity === ITEM_RARITIES[1]) {
            const noUniqueAffix = d4Item.uniqueAffixes === undefined || d4Item.uniqueAffixes === null || d4Item.uniqueAffixes.length === 0;
            if (noUniqueAffix) {
                warning = "No unique affix detected - select an unique aspect manually";
            }
        }

        if (error) {
            setAlert(["error", error]);
        } else {
            if (warning) {
                setAlert(["warning", warning]);
            }
        }

        setItemPreview(d4Item);
        setUpdateImplicitStatsSelects(true)
        setUpdateStatsSelects(true)
        setUpdateAspectSelects(true);
        setUpdateUniqueAffixestSelects(true);
    }

    const tryOpenPriceCheckDialog= function() {
        if(isLegendaryOrUnique(baseItem)) {
            setOpenPriceCheckDialog(true);
        } else {
            setAlert(["warning", "Price Check only works with legendary or unique items."]);
        }
    }

    return (
        <div>
            <PriceCheckDialog isLoading={isLoading}
                              openPriceCheckDialog={openPriceCheckDialog}
                              setOpenPriceCheckDialog={setOpenPriceCheckDialog}
                              user={user}
                              platform={platform}
                              region={region}
                              currency={currency}
                              baseItem={baseItem}
                              baseStats={baseStats}
                              stats={stats}
                              uniqueAffixes={uniqueAffixes}
            />
            <LoadingDialog open={isLoading}/>
            <AlertCard alert={alert}/>
            <EiaCard>
                <Flex>
                    <Heading level={4}>Use screenshot item auto detection (recommended)</Heading>
                    <EiaHelpButton title={"Sell with screenshot auto detection"}>
                        <Flex direction={"column"}>
                            <Heading level={4}>English</Heading>
                            <YoutubeVideo src={"https://www.youtube.com/embed/FYqNQPBhiKc?si=EH78WTXxsFfnAnUP"}/>
                            <Heading level={4}>German</Heading>
                            <YoutubeVideo src={"https://www.youtube-nocookie.com/embed/euVuzxbAtK0?si=2M6lyV_tDqjG0wfo"} />
                        </Flex>
                    </EiaHelpButton>
                </Flex>
                <Divider></Divider>
            </EiaCard>
            <Grid templateColumns="3fr 3fr 3fr 3fr">
                <EiaCard columnStart="1" columnEnd={isSmallScreen ? "-1" : "1"}>
                    <Heading level={4}>Auto detection image upload</Heading>
                    <EiaDropZone handleFiles={handleItemRekognition} isLoading={isLoading}/>
                </EiaCard>
                <EiaCard columnStart={isSmallScreen ? "1" : "2"} columnEnd={isSmallScreen ? "-1" : "2"}>
                    <Flex>
                        <Heading level={4}>Auto detection image</Heading>
                        <EiaHelpButton title={"Auto detection help"}>
                            <Heading level={5}>1. Set game language to english</Heading>
                            <Heading level={5}>2. Disable 'Advanced Tooltip Compare'</Heading>
                            <Heading level={5}>3. Enable 'Advanced Tooltip Information'</Heading>
                            <EiaImage src={helpGameplaySettings}/>
                            <Flex justifyContent={"space-around"}>
                                <EiaImage objectFit="cover" height={300}
                                          src={helpItemAutoDetection1}/>
                                <EiaImage objectFit="cover" height={300}
                                          src={helpItemAutoDetection2}/>
                            </Flex>
                        </EiaHelpButton>
                    </Flex>
                    <EiaImage objectFit="cover" height={300}
                              src={imageBytes ? `data:image/jpeg;base64,${imageBytes}` : itemPlaceHolder}/>
                </EiaCard>
                <EiaCard columnStart={isSmallScreen ? "1" : "3"} columnEnd={isSmallScreen ? "-1" : "3"}>
                    <Flex>
                        <Heading level={4}>Sell item preview</Heading>
                        <EiaHelpButton title={"Item Preview help"}>
                            <Heading level={5}>After uploading your item image you could see detection errors like</Heading>
                            <EiaImage src={helpDetectionError}/>
                            <Heading level={5}>Then scroll down and fix manually wrong values and add missing affixes / aspects</Heading>
                            <EiaImage src={helpDetectionFix}/>
                        </EiaHelpButton>
                    </Flex>

                    {itemPreview ? <D4ItemCard item={itemPreview} amount={itemPreview.amount} /> : null}
                </EiaCard>
                <EiaCard columnStart={isSmallScreen ? "1" : "4"} columnEnd={isSmallScreen ? "-1" : "4"}>
                    <Flex direction="column" justifyContent="space-between">
                        <Heading level={4}>What is your price?</Heading>
                        <CurrencySelects currency={currency} setCurrency={setCurrency} addPrice={true} amount={baseItem.amount} setAlert={setAlert}/>
                        <EiaButton isFullWidth onClick={tryOpenPriceCheckDialog} disabled={isLoading}
                                   tooltip={"Check the price for the selected item"}>Price Check ...</EiaButton>
                        <EiaButton colorTheme={"success"} isFullWidth onClick={checkUserAndSell} disabled={isLoading}
                                   tooltip={"Create a sale for the selected item and price"}>Sell</EiaButton>
                    </Flex>
                </EiaCard>
                <EiaCard columnStart="1" columnEnd="5">
                    <Heading level={4}>Manual select or edit item</Heading>
                    <Divider></Divider>
                </EiaCard>
                <EiaCard
                    columnStart="1"
                    columnEnd={isSmallScreen ? "-1" : "1"}
                >
                    <D4ItemSelects showAmount={true} baseItem={baseItem} setBaseItem={setBaseItem} removeAny={true}/>
                </EiaCard>
                <EiaCard
                    columnStart={isSmallScreen ? "1" : "2"}
                    columnEnd={isSmallScreen ? "-1" : "2"}
                >
                    <Flex direction="column" justifyContent="space-between">
                        <Flex>
                            <Heading level={4}>Basic Stats</Heading>
                            <EiaHelpButton title={"Where are the basic stats?"}>
                                <Flex justifyContent={"space-around"}>
                                    <EiaImage objectFit="cover" height={300}
                                              src={helpBasicStats}/>
                                </Flex>
                            </EiaHelpButton>
                        </Flex>
                        <D4ItemBaseStatSelect baseItem={baseItem} baseStats={baseStats} setBaseStats={setBaseStats}/>
                    </Flex>
                </EiaCard>
                {
                    contains(ITEM_SUB_TYPES_WITHOUT_STATS, baseItem.itemSubType) ? null :
                        <EiaCard
                            columnStart={isSmallScreen ? "1" : "3"}
                            columnEnd={isSmallScreen ? "-1" : "3"}
                        >
                            <D4ImplicitStatSelects
                                size={4}
                                implicitStats={implicitStats}
                                setImplicitStats={setImplicitStats}
                                updateImplicitStatsSelects={updateImplicitStatsSelects}
                                setAlert={setAlert}
                            />
                        </EiaCard>
                }
                {
                    contains(ITEM_SUB_TYPES_WITHOUT_STATS, baseItem.itemSubType) ? null :
                        <EiaCard
                            columnStart={isSmallScreen ? "1" : "4"}
                            columnEnd={isSmallScreen ? "-1" : "4"}
                        >
                            <D4AffixSelects
                                setAlert={setAlert}
                                size={4}
                                stats={stats}
                                setStats={setStats}
                                updateStatsSelects={updateStatsSelects}
                                aspects={aspects}
                                setAspects={setAspects}
                                updateAspectSelects={updateAspectSelects}
                                itemCanHaveAspects={itemCanHaveAspects}
                                uniqueAffixes={uniqueAffixes}
                                setUniqueAffixes={setUniqueAffixes}
                                updateUniqueAffixesSelects={updateUniqueAffixesSelects}
                                itemCanHaveUniqueAffixes={itemCanHaveUniqueAffixes}
                            />
                        </EiaCard>
                }
            </Grid>
        </div>
    )
}
export default SellPage;