//float with 1 decimal
const shortFloatRegex = new RegExp("^(\\d+\\.?\\d?)?$");
const shortFloatRegexTwoDecimals = new RegExp("^(\\d+\\.?(\\d{1,2})?)?$");
const integerRegex = new RegExp("^([0-9]+)?$");

export const testInteger = function (string, min, max) {
    if(max && string > max) {
        return false;
    }
    if(min && string < min) {
        return false;
    }
    return integerRegex.test(string);
};

export const testShortFloatTwoDecimals = function(string) {
    return shortFloatRegexTwoDecimals.test(string);
}

export const testShortFloat = function(string) {
    return shortFloatRegex.test(string);
}