import React from "react";
import {Card} from "@aws-amplify/ui-react";
import Utils from "../../commons/Utils/Utils";
import {COLOR_TEAL} from "../../commons/enums/ColorEnums";

const isSmallScreen = Utils.isSmallScreen();
const defaultPadding = isSmallScreen ? '4px' : '8px';

const EiaCard = function (props) {

    const {height, roundCorners, borderColor, isClickable, styleBgColor, children, padding=defaultPadding, ...restProps} = props;

    const [isHovered, setIsHovered] = React.useState(false);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    let style = isClickable && isHovered  ? {
        padding: padding,
        cursor: 'pointer',
        backgroundColor: COLOR_TEAL
    } : {
        padding: padding
    }

    if(styleBgColor) {
        style.backgroundColor = styleBgColor;
    }

    if(roundCorners) {
        style.borderRadius="0.5rem"
    }

    if(borderColor) {
        style.borderColor=borderColor
        style.borderWidth="thick"
    }

    if(height) {
        style.height=height;
    }

    return(
            <Card {...restProps}
                  style={style}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
            >
                {children}
            </Card>
    )
}
export default EiaCard;