import EiaAuctionTableWithButtons from "./tables/EiaAuctionTableWithButtons";
import EiaAuctionViewWithButtons from "./EiaAuctionViewWithButtons";
import React from "react";
import {SwitchField} from "@aws-amplify/ui-react";
import EiaButtonBar from "./EiaButtonBar";
import EiaDialog from "./dialogs/EiaDialog";
import D4ItemCardWithPrice from "./d4/D4ItemCardWithPrice";
import EiaInfoText from "./EiaInfoText";

const EiaAuctionsWithButtons = function (props) {

    const {alert, isLoading, tableData, tableHeaders, tableInitialState, generalButtons, auctionSpecificButtons, selectedAuctionId, selectedAuction, setSelectedAuctionId,
        openAuctionSpecificDialog, setOpenAuctionSpecificDialog, isViewFromSeller=false, isViewFromBidder=false, defaultSortIndex=1} = props;

    const [tableView, setTableView] = React.useState(false);

    const tableSwitchField = <SwitchField
        key={"tableSwitchField"}
        isDisabled={isLoading}
        label="Table View"
        labelPosition="end"
        isChecked={tableView}
        onChange={(e) => {
            setSelectedAuctionId([])
            setTableView(e.target.checked);
        }}
    />

    const buttonBarTableView = <EiaButtonBar>
        {[tableSwitchField, generalButtons, auctionSpecificButtons]}
    </EiaButtonBar>

    const buttonBarNonTableView = <EiaButtonBar>
        {[tableSwitchField, generalButtons, <EiaInfoText key={"infoText"} value={"Click an item to interact"} />]}
    </EiaButtonBar>

    const handleClickAuctionInNonTableView = function (auction) {
        setSelectedAuctionId([auction.id]);
        setOpenAuctionSpecificDialog(true);
    }

    return <div>
        <EiaDialog open={openAuctionSpecificDialog} onClose={() => setOpenAuctionSpecificDialog(false)}>
            {selectedAuction ? <D4ItemCardWithPrice auctionMergedWithBid={selectedAuction} showDetails={true}/> : null};
            {auctionSpecificButtons}
        </EiaDialog>
        {
            tableView ?
                <EiaAuctionTableWithButtons
                    buttons={buttonBarTableView}
                    alert={alert}
                    selectedAuction={selectedAuction}
                    selectedAuctionId={selectedAuctionId}
                    setSelectedAuctionId={setSelectedAuctionId}
                    isLoading={isLoading}
                    tableData={tableData}
                    tableHeader={tableHeaders}
                    tableInitialState={tableInitialState}
                />
                :
                <EiaAuctionViewWithButtons
                    buttons={buttonBarNonTableView}
                    alert={alert}
                    isLoading={isLoading}
                    auctions={tableData}
                    showDetails={true}
                    isViewFromSeller={isViewFromSeller}
                    isViewFromBidder={isViewFromBidder}
                    onClickAuction={handleClickAuctionInNonTableView}
                    supportSorting={true}
                    defaultSortIndex={defaultSortIndex}
                />
        }
    </div>
}

export default EiaAuctionsWithButtons;