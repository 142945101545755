import {contains} from "./Utils/ArrayUtils";
import {ITEM_TYPES_WITH_ARMOR, ITEM_TYPES_WITH_DPS} from "./enums/D4Enums";
import {getLinkOrNull} from "./wowhead/D4ItemLinks";
import {Link} from "@aws-amplify/ui-react";
import Tooltip, {tooltipClasses} from '@mui/material/Tooltip';
import D4ItemCard from "../components/d4/D4ItemCard";
import {styled} from "@mui/material";

const biddersComparator = (b1, b2) => b1.length - b2.length;

const renderCountry = function(params) {
    if(params && params.value) {
        return <img src={require("../resources/flags/" + params.value + ".svg")} style={{width: 30}}/>
    }
    return null;
}

const amountHeader = {field: "amount", headerName: "Amount", width: 70};

const StyledTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: 'rgba(0, 0, 0, 0)',
        fontSize: 16,
        minWidth: 400
    },
}));

const renderItemName = function(params) {
    if(params && params.value) {
        const link = getLinkOrNull(params.value);
        if(link) {
            return link;
        }

        if(params.row.item) {
            return <StyledTooltip followCursor={true} enterDelay={20} placement="top-start"
                                  title={<D4ItemCard item={params.row.item}/>}>
                <Link>
                    {params.value}
                </Link>
            </StyledTooltip>
        }

        return params.value;
    }
    return null;
}

const BASE_AUCTION_HEADERS = [
    {field: "id", headerName: "Id", hide: true},
    {field: "itemType", headerName: "Item Type", hide: true},
    {field: "itemName", headerName: "Item", minWidth: 100, renderCell: renderItemName},
    {field: "itemTitle", headerName: "Title", minWidth: 140},
    {field: "itemPower", headerName: "Item Power"},
    {field: "levelRequirement", headerName: "Level Requirement", width: 95},
    {field: "classRequirement", headerName: "Class Requirement", hide: true},
    amountHeader,
    {field: "price", headerName: "Price", width: 80},
    {field: "pricePerItem", headerName: "Price each", width: 100},
    {field: "currency", headerName: "Currency", minWidth: 120},
    {field: "sellerBattleTag", headerName: "Battle Tag", minWidth: 180},
    {field: "sellerDiscordName", headerName: "Discord", minWidth: 100},
    {field: "sellerCountryCode", headerName: "Country", width: 60, renderCell: renderCountry},
    {field: "sellerGamingSystem", headerName: "System", width: 60},
    {field: "bidders", headerName: "Bidders", sortComparator: biddersComparator},
    {field: "creationDate", headerName: "Creation Date", minWidth: 150, hide: true},
];

export const SEARCH_TABLE_HEADERS_NO_STATS = [
    {field: "id", headerName: "Id", hide: true},
    {field: "itemName", headerName: "Item", minWidth: 140, renderCell: renderItemName},
    amountHeader,
    {field: "price", headerName: "Price", width: 80},
    {field: "pricePerItem", headerName: "Price each", width: 100},
    {field: "currency", headerName: "Currency", minWidth: 120},
    {field: "sellerBattleTag", headerName: "Battle Tag", minWidth: 180},
    {field: "sellerDiscordName", headerName: "Discord", minWidth: 100},
    {field: "sellerCountryCode", headerName: "Country", width: 60, renderCell: renderCountry},
    {field: "sellerGamingSystem", headerName: "System", width: 60},
    {field: "bidders", headerName: "Bidders", sortComparator: biddersComparator},
    {field: "creationDate", headerName: "Creation Date", minWidth: 150, hide: true},
];

export const SEARCH_TABLE_HEADERS = BASE_AUCTION_HEADERS;

export const MY_SALES_TABLE_HEADERS = [
    {field: "id", headerName: "Id", hide: true},
    {field: "itemType", headerName: "Item Type", hide: true},
    {field: "itemName", headerName: "Item", minWidth: 100, renderCell: renderItemName},
    {field: "itemTitle", headerName: "Title", minWidth: 140},
    {field: "itemPower", headerName: "Item Power"},
    {field: "levelRequirement", headerName: "Level Requirement", width: 95},
    {field: "classRequirement", headerName: "Class Requirement", hide: true},
    amountHeader,
    {field: "price", headerName: "Price", width: 80},
    {field: "pricePerItem", headerName: "Price each", width: 100},
    {field: "currency", headerName: "Currency", minWidth: 120},
    {field: "bidders", headerName: "Bidders", sortComparator: biddersComparator},
    {field: "creationDate", headerName: "Creation Date", minWidth: 150, hide: true},
    {field: "status", headerName: "Status", minWidth: 120},
    {field: "buyer", headerName: "Buyer"}
];

export const BASE_BIDS_SELECT_HEADERS = [
    {field: "id", headerName: "Id", hide: true},
    {field: "bidder", headerName: "Bidder"},
    amountHeader,
    {field: "price", headerName: "Price", width: 80},
    {field: "pricePerItem", headerName: "Price each", width: 100},
    {field: "currency", headerName: "Currency", minWidth: 120},
    {field: "bidderBattleTag", headerName: "Battle Tag", minWidth: 180},
    {field: "bidderDiscordName", headerName: "Discord", minWidth: 100},
    {field: "bidderCountryCode", headerName: "Country", minWidth: 60, maxWidth: 60, renderCell: renderCountry},
    {field: "bidderGamingSystem", headerName: "System", minWidth: 60, maxWidth: 60},
    {field: "status", headerName: "Status"}
];

export const BASE_BIDS_HEADERS = [
    {field: "id", headerName: "Id", hide: true},
    {field: "itemName", headerName: "Item", renderCell: renderItemName},
    {field: "itemTitle", headerName: "Title", minWidth: 140},
    amountHeader,
    {field: "price", headerName: "Price", width: 80},
    {field: "pricePerItem", headerName: "Price each", width: 100},
    {field: "currency", headerName: "Currency", minWidth: 120},
    {field: "bidders", headerName: "Bidders"},
    {field: "seller", headerName: "Seller"},
    {field: "myBidStatus", headerName: "Bid status", minWidth: 180},
    {field: "status", headerName: "Sell status"},
    {field: "creationDate", headerName: "Creation Date", minWidth: 180},
];

export const NOTIFICATION_HEADERS = [
    {field: "id", headerName: "Id", hide: true},
    {field: "date", headerName: "Date", minWidth: 180},
    {field: "mode", headerName: "Mode"},
    {field: "realm", headerName: "Realm"},
    {field: "message", headerName: "Message", minWidth: 800}
];

export const getAdditionalHeaders = function(itemType, itemSubType, stats) {

    let additionalHeaders = [];

    if (stats) {
        stats.forEach(stat => {
            additionalHeaders.push({field: stat.name});
        })
    }

    if(contains(ITEM_TYPES_WITH_DPS, itemType)) {
        additionalHeaders.push({field: "dps"})
    }

    if(contains(ITEM_TYPES_WITH_ARMOR, itemType)) {
        additionalHeaders.push({field: "armor"})
    }

    return additionalHeaders;
}