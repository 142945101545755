import * as React from 'react';
import {Grid, Heading} from "@aws-amplify/ui-react";
import AlertCard from "../cards/AlertCard";
import EiaCard from "../cards/EiaCard";
import EiaTable from "./EiaTable";
import Utils from "../../commons/Utils/Utils";
import D4ItemCard from "../d4/D4ItemCard";

const isSmallScreen = Utils.isSmallScreen();

const buttonsColumnEnd = isSmallScreen ? "-1" : "2";
const tableColumnStart = isSmallScreen ? "1" : "2";

const EiaAuctionTableWithButtons = function(props) {

    const {buttons, alert, selectedAuction, selectedAuctionId, setSelectedAuctionId, isLoading, tableData, tableHeader, tableInitialState, heading, rowsPerPage, noItemCard,
        infoMessage} = props;


    const itemCard = !noItemCard && selectedAuction ? <D4ItemCard item={selectedAuction.item} amount={selectedAuction.amount} /> : null;

    return(
        <Grid templateColumns="2fr 9fr">
            {alert ? <AlertCard alert={alert} /> : null }
            <EiaCard columnStart="1" columnEnd={buttonsColumnEnd}>
                {buttons}
                {itemCard}
            </EiaCard>
            <EiaCard
                columnStart={tableColumnStart}
                columnEnd="-1"
            >
                {heading ? <Heading>{heading}</Heading> : null}
                <EiaTable selectedRowId={selectedAuctionId} setSelectedRowId={setSelectedAuctionId}
                          isLoading={isLoading} data={tableData} tableHeader={tableHeader} initialState={tableInitialState} rowsPerPage={rowsPerPage}/>
                {infoMessage ? infoMessage : null}
            </EiaCard>
        </Grid>

    );
}
export default EiaAuctionTableWithButtons;