import {Flex, Grid} from "@aws-amplify/ui-react";
import React from "react";
import EiaCard from "./cards/EiaCard";
import EiaPagination from "./EiaPagination";
import D4ItemCardWithPrice from "./d4/D4ItemCardWithPrice";
import Utils from "../commons/Utils/Utils";
import EiaInfoText from "./EiaInfoText";

const isSmallScreen = Utils.isSmallScreen();

const getTemplateColumns = function (columns) {
    let result = "1fr";

    for (let i = 1; i < columns; i++) {
        result += " 1fr";
    }

    return result;
}

const EiaPaginatedItems = function (props) {

    const {itemsPerPage, columns, currentPage, setCurrentPage, auctions, onClickAuction, showDetails=false,
        isViewFromSeller=false, isViewFromBidder=false} = props;

    const templateColumns = getTemplateColumns(columns)
    const columnSpanPerItem = isSmallScreen ? columns : 1;

    const totalPages = Math.ceil(auctions.length / itemsPerPage);

    React.useEffect(() => {
        if(currentPage > totalPages) {
            setCurrentPage(1);
        }
    }, [auctions])

    const isClickable = onClickAuction !== undefined && onClickAuction != null;

    const onClick = function (auction) {
        if (isClickable) {
            onClickAuction(auction);
        }
    }

    return (
        <EiaCard>
            {
                auctions.length > 0 ? <EiaPagination
                    totalPages={totalPages} currentPage={currentPage} setCurrentPage={setCurrentPage} positionBottom={true}
                >
                    <Grid templateColumns={templateColumns}>
                        {
                            auctions.map((auction, index) => {
                                if (index < currentPage * itemsPerPage && index >= (currentPage - 1) * itemsPerPage) {
                                    return (
                                        <EiaCard key={index} columnSpan={columnSpanPerItem} onClick={() => onClick(auction)} isClickable={isClickable}>
                                            <D4ItemCardWithPrice auctionMergedWithBid={auction} showDetails={showDetails} isViewFromSeller={isViewFromSeller} isViewFromBidder={isViewFromBidder} />
                                        </EiaCard>
                                    )
                                } else {
                                    return null;
                                }
                            })
                        }
                    </Grid>
                </EiaPagination> :
                    <Flex direction={"column"} minHeight={window.screen.height * 0.6}>
                        <EiaInfoText value={"No items found"} />
                    </Flex>
            }
        </EiaCard>
    );
};
export default EiaPaginatedItems;