import React from "react";
import SearchBasePage from "./SearchBasePage";

const SearchPage = function (props) {
    const {user, platform, region} = props;

    const [currency, setCurrency] = React.useState({})
    const [baseItem, setBaseItem] = React.useState({});


    return (<SearchBasePage
            user={user}
            platform={platform}
            region={region}
            currency={currency}
            setCurrency={setCurrency}
            baseItem={baseItem}
            setBaseItem={setBaseItem}
            onlyBestPricePerItemName={false}
            activateFiltersDrawer={true}
        />
    )
}
export default SearchPage;