import React from "react";
import EiaDialog from "./EiaDialog";
import LoadingDialog from "./LoadingDialog";
import AlertCard from "../cards/AlertCard";
import {Flex, Heading} from "@aws-amplify/ui-react";
import EiaButton from "../EiaButton";
import EiaCard from "../cards/EiaCard";
import CurrencySelects from "../CurrencySelects";
import D4ItemBaseStatSelect from "../d4/D4ItemBaseStatSelect";
import {contains} from "../../commons/Utils/ArrayUtils";
import {canHaveAspects, canHaveUniqueAffix, getCurrencyTypeByCurrency, ITEM_SUB_TYPES_WITHOUT_STATS} from "../../commons/enums/D4Enums";
import {d4EditAuction} from "../../commons/api/D4Api";
import {convertToD4ItemForRequest} from "../../commons/Converter";
import Utils from "../../commons/Utils/Utils";
import D4AffixSelects from "../d4/D4AffixSelects";
import D4ImplicitStatSelects from "../d4/D4ImplicitStatSelects";
import EiaHelpButton from "../EiaHelpButton";
import EiaImage from "../EiaImage";
import helpBasicStats from "../../resources/help/helpBasicStats.jpg";
import D4ItemSelects from "../d4/D4ItemSelects";

const isSmallScreen = Utils.isSmallScreen();

function EditAuctionDialog(props) {
    const {onClose, open, auction} = props;

    const [currency, setCurrency] = React.useState({})

    const [baseItem, setBaseItem] = React.useState({});
    const [baseStats, setBaseStats] = React.useState({});
    const [implicitStats, setImplicitStats] = React.useState([])
    const [updateImplicitStatsSelects, setUpdateImplicitStatsSelects] = React.useState(false);
    const [affixStats, setAffixStats] = React.useState([])
    const [updateStatsSelects, setUpdateStatsSelects] = React.useState(false);
    const [aspects, setAspects] = React.useState([])
    const [updateAspectSelects, setUpdateAspectSelects] = React.useState(false);
    const [uniqueAffixes, setUniqueAffixes] = React.useState([])
    const [updateUniqueAffixesSelects, setUpdateUniqueAffixesSelects] = React.useState(false);

    const [isLoading, setIsLoading] = React.useState(false)
    const [alert, setAlert] = React.useState(null)

    const itemCanHaveAspects = canHaveAspects(baseItem);
    const itemCanHaveUniqueAffixes = canHaveUniqueAffix(baseItem);

    React.useEffect(() => {
        if(!auction) {
            return;
        }

        setAlert(null);

        // update currency
        let currencyType = getCurrencyTypeByCurrency(auction.currency);
        let temp = {
            name: auction.currency,
            type: currencyType,
            price: auction.price
        }
        setCurrency(temp);

        // update baseItem
        temp = {
            itemType: auction.itemType,
            itemSubType: auction.itemSubType,
            name: auction.itemName,
            title: auction.item.title,
            amount: auction.amount,
            rarity: auction.rarity
        };
        setBaseItem(temp);

        // update basic stats
        temp = {
            armor: auction.armor,
            dps: auction.dps,
            levelRequirement: auction.levelRequirement,
            classRequirement: auction.classRequirement,
            itemPower: auction.itemPower
        };
        setBaseStats(temp);

        //update stats
        setImplicitStats(auction.item.basicStats ? auction.item.basicStats : [])
        setAffixStats(auction.item.stats ? auction.item.stats : []);
        setAspects(auction.item.aspects ? auction.item.aspects : []);
        setUniqueAffixes(auction.item.uniqueAffixes ? auction.item.uniqueAffixes : [])
        setUpdateStatsSelects(true);
        setUpdateImplicitStatsSelects(true);
        setUpdateAspectSelects(true);
        setUpdateUniqueAffixesSelects(true);
    }, [auction]);

    const save = function() {
        setIsLoading(true)

        const auctionForRequest = Object.assign({}, auction);
        auctionForRequest.item = convertToD4ItemForRequest(baseItem, baseStats, implicitStats, affixStats, aspects, uniqueAffixes);
        auctionForRequest.amount = baseItem.amount;
        auctionForRequest.currency = currency.name;
        auctionForRequest.price = currency.price;

        const request = {
            mode: auction.mode,
            realm: auction.realm,
            region: auction.region,
            platform: auction.platform,
            auction: auctionForRequest
        }

        d4EditAuction(request, function (response) {
            let successMessage = baseItem.title ? "Successfully edited auction with item '" + response.updatedAuction.item.name + "'" :
                "Successfully edited auction with item '" + baseItem.name + "'";
            setAlert(["success", successMessage]);
            setIsLoading(false)
            onClose(true);
        }, function (error) {
            setAlert(["error", error.message]);
            setIsLoading(false)
        })
    }


    return (
        <EiaDialog title={"Edit Auction"} open={open} onClose={onClose} isLoading={isLoading} maxWidth={"xl"} fullWidth={isSmallScreen}>
            <LoadingDialog open={isLoading} />
            <AlertCard alert={alert} />
            <Flex direction={isSmallScreen ? "column" : "row"}>
                <EiaCard>
                    <D4ItemSelects showAmount={true} baseItem={baseItem} setBaseItem={setBaseItem} removeAny={true}/>
                </EiaCard>
                <EiaCard>
                    <Flex direction="column" justifyContent="space-between">
                        <Flex>
                            <Heading level={4}>Basic Stats</Heading>
                            <EiaHelpButton title={"Where are the basic stats?"}>
                                <Flex justifyContent={"space-around"}>
                                    <EiaImage objectFit="cover" height={300}
                                              src={helpBasicStats}/>
                                </Flex>
                            </EiaHelpButton>
                        </Flex>
                        <D4ItemBaseStatSelect baseItem={baseItem} baseStats={baseStats} setBaseStats={setBaseStats}/>
                    </Flex>
                </EiaCard>
                {
                    contains(ITEM_SUB_TYPES_WITHOUT_STATS, baseItem.itemSubType) ? null :
                        <EiaCard>
                            <D4ImplicitStatSelects
                                size={4}
                                implicitStats={implicitStats}
                                setImplicitStats={setImplicitStats}
                                updateImplicitStatsSelects={updateImplicitStatsSelects}
                                setAlert={setAlert}
                            />
                        </EiaCard>
                }
                {
                    contains(ITEM_SUB_TYPES_WITHOUT_STATS, baseItem.itemSubType) ? null :
                        <EiaCard>
                            <D4AffixSelects
                                size={4}
                                stats={affixStats}
                                setStats={setAffixStats}
                                updateStatsSelects={updateStatsSelects}
                                aspects={aspects}
                                setAspects={setAspects}
                                updateAspectSelects={updateAspectSelects}
                                setAlert={setAlert}
                                itemCanHaveAspects={itemCanHaveAspects}
                                uniqueAffixes={uniqueAffixes}
                                setUniqueAffixes={setUniqueAffixes}
                                updateUniqueAffixesSelects={updateUniqueAffixesSelects}
                                itemCanHaveUniqueAffixes={itemCanHaveUniqueAffixes}
                            />
                        </EiaCard>
                }
                <EiaCard>
                    <Flex direction="column" justifyContent="space-between">
                        <Heading level={5}>Currency</Heading>
                        <CurrencySelects currency={currency} setCurrency={setCurrency} addPrice={true} setAlert={setAlert} />
                        <EiaButton isFullWidth colorTheme={"success"} onClick={save} disabled={isLoading}>Save</EiaButton>
                    </Flex>
                </EiaCard>
            </Flex>
        </EiaDialog>
    );
}

export default EditAuctionDialog;