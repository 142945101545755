import HoursToReachLevelCalculator from "./HoursToReachLevelCalculator";
import React from "react";
import {Flex, Heading} from "@aws-amplify/ui-react";
import EiaFixedTable from "../tables/EiaFixedTable";

const aozXpHeader = ["Dungeon Tier", "Glyph XP Reward"];
const aozXpData = [
    [1, 1000],
    [2, 1200],
    [3, 1500],
    [4, 2000],
    [5, 2800],
    [6, 3900],
    [7, 5300],
    [8, 7000],
    [9, 9100],
    [10, 11600],
    [11, 14500],
    [12, 17900],
    [13, 21800],
    [14, 26200],
    [15, 31200],
    [16, 36800],
    [17, 43000],
    [18, 49800],
    [20, 65400],
    [23, 94100],
    [24, 105200],
    [25, 117100],
];
const tearsOfBloodHeader = ["Glyph Level", "Total Glyph XP", "Glyph XP to next level"];
const tearsOfBloodData = [
        [1, 0, 2000],
        [2, 2000, 4600],
        [3, 6600, 7700],
        [4, 14300, 11200],
        [5, 25500, 15000],
        [6, 40500, 19100],
        [7, 59600, 23500],
        [8, 83100, 28100],
        [9, 111200, 32900],
        [10, 144100, 37900],
        [11, 182000, 43100],
        [12, 225100, 48500],
        [13, 273600, 54100],
        [14, 327700, 59800],
        [15, 387500, 65700],
        [16, 453200, 71800],
        [17, 525000, 78000],
        [18, 603000, 84400],
        [19, 687400, 90900],
        [20, 778300, 97500],
        [21, 875800, 104300],
        [22, 980100, 111200],
        [23, 1091300, 118200],
        [24, 1209500, 125300],
        [25, 1334800, 132500],
        [26, 1467300, 139900],
        [27, 1607200, 147400],
        [28, 1754600, 155000],
        [29, 1909600, 162700],
        [30, 2072300, 170500],
        [31, 2242800, 178400],
        [32, 2421200, 186400],
        [33, 2607600, 194500],
        [34, 2802100, 202700],
        [35, 3004800, 211000],
        [36, 3215800, 219400],
        [37, 3435200, 227900],
        [38, 3663100, 236500],
        [39, 3899600, 245200],
        [40, 4144800, 253900],
        [41, 4398700, 262700],
        [42, 4661400, 271600],
        [43, 4933000, 280600],
        [44, 5213600, 289700],
        [45, 5503300, 298900],
        [46, 5802200, 308100],
        [47, 6110300, 317400],
        [48, 6427700, 326800],
        [49, 6754500, 336300],
        [50, 7090800, 345900],
        [51, 7436700, 355500],
        [52, 7792200, 365200],
        [53, 8157400, 375000],
        [54, 8532400, 384900],
        [55, 8917300, 394800],
        [56, 9312100, 404800],
        [57, 9716900, 414900],
        [58, 10131800, 425000],
        [59, 10556800, 435200],
        [60, 10992000, 445500],
        [61, 11437500, 455900],
        [62, 11893400, 466300],
        [63, 12359700, 476800],
        [64, 12836500, 487400],
        [65, 13323900, 498000],
        [66, 13821900, 508700],
        [67, 14330600, 519500],
        [68, 14850100, 530300],
        [69, 15380400, 541200],
        [70, 15921600, 552100],
        [71, 16473700, 563100],
        [72, 17036800, 574200],
        [73, 17611000, 585300],
        [74, 18196300, 596500],
        [75, 18792800, 607700],
        [76, 19400500, 619000],
        [77, 20019500, 630400],
        [78, 20649900, 641800],
        [79, 21291700, 653300],
        [80, 21945000, 664800],
        [81, 22609800, 676400],
        [82, 23286200, 688100],
        [83, 23974300, 699800],
        [84, 24674100, 711600],
        [85, 25385700, 723400],
        [86, 26109100, 735300],
        [87, 26844400, 747200],
        [88, 27591600, 759200],
        [89, 28350800, 771200],
        [90, 29122000, 783300],
        [91, 29905300, 795500],
        [92, 30700800, 807700],
        [93, 31508500, 820000],
        [94, 32328500, 832300],
        [95, 33160800, 844700],
        [96, 34005500, 857100],
        [97, 34862600, 869600],
        [98, 35732200, 882100],
        [99, 36614300, 894700],
        [100, 37509000, 907300],
        [101, 38416300, 920000],
        [102, 39336300, 932700],
        [103, 40269000, 945500],
        [104, 41214500, 958300],
        [105, 42172800, 971200],
        [106, 43144000, 984100],
        [107, 44128100, 997100],
        [108, 45125200, 1010100],
        [109, 46135300, 1023200],
        [110, 47158500, 1036300],
        [111, 48194800, 1049500],
        [112, 49244300, 1062700],
        [113, 50307000, 1076000],
        [114, 51383000, 1089300],
        [115, 52472300, 1102600],
        [116, 53574900, 1116000],
        [117, 54690900, 1129400],
        [118, 55820300, 1142900],
        [119, 56963200, 1156400],
        [120, 58119600, 1170000],
        [121, 59289600, 1183600],
        [122, 60473200, 1197300],
        [123, 61670500, 1211000],
        [124, 62881500, 1224800],
        [125, 64106300, 1238600],
        [126, 65344900, 1252400],
        [127, 66597300, 1266300],
        [128, 67863600, 1280200],
        [129, 69143800, 1294200],
        [130, 70438000, 1308200],
        [131, 71746200, 1322300],
        [132, 73068500, 1336400],
        [133, 74404900, 1350500],
        [134, 75755400, 1364700],
        [135, 77120100, 1378900],
        [136, 78499000, 1393200],
        [137, 79892200, 1407500],
        [138, 81299700, 1421900],
        [139, 82721600, 1436300],
        [140, 84157900, 1450700],
        [141, 85608600, 1465200],
        [142, 87073800, 1479700],
        [143, 88553500, 1494300],
        [144, 90047800, 1508900],
        [145, 91556700, 1523500],
        [146, 93080200, 1538200],
        [147, 94618400, 1552900],
        [148, 96171300, 1567700],
        [149, 97739000, 1582500],
        [150, 99321500, 1597300],
        [151, 100918800, 1612200],
        [152, 102531000, 1627100],
        [153, 104158100, 1642100],
        [154, 105800200, 1657100],
        [155, 107457300, 1672100],
        [156, 109129400, 1687200],
        [157, 110816600, 1702300],
        [158, 112518900, 1717500],
        [159, 114236400, 1732700],
        [160, 115969100, 1747900],
        [161, 117717000, 1763200],
        [162, 119480200, 1778500],
        [163, 121258700, 1793800],
        [164, 123052500, 1809200],
        [165, 124861700, 1824600],
        [166, 126686300, 1840100],
        [167, 128526400, 1855600],
        [168, 130382000, 1871100],
        [169, 132253100, 1886700],
        [170, 134139800, 1902300],
        [171, 136042100, 1917900],
        [172, 137960000, 1933600],
        [173, 139893600, 1949300],
        [174, 141842900, 1965000],
        [175, 143807900, 1980800],
        [176, 145788700, 1996600],
        [177, 147785300, 2012500],
        [178, 149797800, 2028400],
        [179, 151826200, 2044300],
        [180, 153870500, 2060300],
        [181, 155930800, 2076300],
        [182, 158007100, 2092300],
        [183, 160099400, 2108400],
        [184, 162207800, 2124500],
        [185, 164332300, 2140600],
        [186, 166472900, 2156800],
        [187, 168629700, 2173000],
        [188, 170802700, 2189200],
        [189, 172991900, 2205500],
        [190, 175197400, 2221800],
        [191, 177419200, 2238100],
        [192, 179657300, 2254500],
        [193, 181911800, 2270900],
        [194, 184182700, 2287300],
        [195, 186470000, 2303800],
        [196, 188773800, 2320300],
        [197, 191094100, 2336900],
        [198, 193431000, 2353500],
        [199, 195784500, 2370100],
        [200, 198154600, 2386800]
    ]
;

const aozTables = <Flex direction={"column"}>
    <Heading level={6}>Abattoir of Zir XP Reward Table</Heading>
    <EiaFixedTable data = {aozXpData} tableHeader={aozXpHeader}/>
    <Heading level={6}>Tears of Blood XP Table</Heading>
    <EiaFixedTable data = {tearsOfBloodData} tableHeader={tearsOfBloodHeader}/>
</Flex>

const AoZCalculator = function() {


    return(
        <HoursToReachLevelCalculator
            title={"Tears of Blood Calculator"}
            lastUpdated={"2024/01/04"}
            initialCurrentLevel={1}
            initialTargetLevel={50}
            initialDungeonTier={10}
            initialDungeonClearMinutes={5}
            currentLevelText={"Tears of Blood Current Level"}
            targetLevelText={"Tears of Blood Target Level"}
            dungeonTierText={"Aoz dungeon tier you farm"}
            dungeonClearMinutesText={"Aoz dungeon clear time (minutes)"}
            levelXpData={tearsOfBloodData}
            dungeonXpRewardData={aozXpData}
            tables={aozTables}
            levelMax={200}
            dungeonTierMax={25}
        />
    )
}

export default AoZCalculator;