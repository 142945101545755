import {fetchAuthSession} from 'aws-amplify/auth'
import {post as apiPost} from 'aws-amplify/api'
import LocalStorage from "../LocalStorage";

const guestPathsOpenForEveryone = ["/d4/auctions/search", "/basic/rekognize-item"];

export const post = async function (json, successHandler, errorHandler, path, api) {
    const isPathOpenForEveryone = guestPathsOpenForEveryone.some(guestPath => path.includes (guestPath));

    if (!LocalStorage.getUser() && !isPathOpenForEveryone) {
        let error = {
            message: "Login required"
        }
        errorHandler(error);
        return;
    }

    console.log("request", json)
    const bodyAndHeaders = {
        body: json
    };

    if(!isPathOpenForEveryone) {
        bodyAndHeaders.headers = {
            Authorization: `Bearer ${(await fetchAuthSession()).tokens.idToken}`
        }
    }

    let apiName;

    if (api) {
        apiName = api
    } else {
        apiName = "eiaUserApi"
    }

        apiPost({apiName, path, options: bodyAndHeaders}).response
        .then(function(response) {
            console.log("response", response)
            response.body.json().then(function(jsonResponse) {
                if (jsonResponse.errorMessage) {
                    console.error("json response", jsonResponse);
                    let error = {
                        message: jsonResponse.errorMessage
                    }
                    errorHandler(error);
                } else {
                    console.log("json response", jsonResponse);
                    successHandler(jsonResponse);
                }
            })
            .catch(function (error) {
                console.error("response", error);
                errorHandler(error);
            });
        })
        .catch(function (error) {
            console.error("response", error);
            errorHandler(error);
        });
}