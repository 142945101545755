import React from "react"
import {Flex, Link} from "@aws-amplify/ui-react";
import NewsEntry from "../components/NewsEntry";
import itemViewExample from './../resources/news/itemViewExample.jpg'
import itemReaderExample from './../resources/news/itemReaderExample.png'
import itemReadingOnSellPage from './../resources/news/itemReadingOnSellPage.jpg'
import badImageQuality from './../resources/news/badImageQuality.jpg'
import newItemStyle from './../resources/news/newItemStyle.jpg'
import darkMode from './../resources/news/darkMode.jpg'
import browserNotifications from './../resources/news/browserNotifications.jpg'
import implicitStatsRareExample from '../resources/news/implicitStatsRareExample.jpg'
import implicitStatsMagicExample from '../resources/news/implicitStatsMagicExample.jpg'
import salesOffersExample from '../resources/news/salesOffersExample.jpg'
import bestPricesExample from '../resources/news/bestPricesExample.jpg'
import settingsWithCountry from '../resources/news/settingsWithCountry.jpg'
import tradeSimplified from '../resources/news/tradeSimplified.jpg'
import worldBossSchedule from '../resources/news/worldBossSchedule.jpg'
import bidDialog from '../resources/news/bidDialog.jpg'
import reminderExample from '../resources/news/reminderExample.jpg'
import XmasGiveAway2023 from '../resources/XmasGiveAway2023.jpg'
import nmDungeonCalc from '../resources/news/nmDungeonCalc.jpg'
import tooltipExample from '../resources/news/tooltipExample.jpg'
import d4calendar from '../resources/news/d4calendar.jpg'
import tearsOfBloodCalculator from '../resources/news/tearsOfBloodCalculator.jpg'
import {sortDesc} from "../commons/Utils/ArrayUtils";
import EiaCard from "../components/cards/EiaCard";
import EiaPagination from "../components/EiaPagination";
import x36_settings from '../resources/news/x36_settings.jpg'
import x36_bestPrices from '../resources/news/x36_bestPrices.jpg'
import d4calendarUpdate022024 from '../resources/news/d4calendarUpdate022024.jpg'
import nonTableView from '../resources/news/nonTableView.jpg'
import tooltipsExample2 from '../resources/news/tooltipsExample2.jpg'
import tooltipButtonExample from '../resources/news/tooltipButtonExample.jpg'
import itemSelectionMouseOver from '../resources/news/itemSelectionMouseOver.jpg'
import greaterAffixExample from '../resources/news/greaterAffixExample.jpg'
import greaterAffixExample2 from '../resources/news/greaterAffixExample2.jpg'
import season4LegDetectionErrors from '../resources/news/season4LegDetectionErrors.jpg'
import season4LegExample1 from '../resources/news/season4LegExample1.jpg'
import season4LegExample2 from '../resources/news/season4LegExample2.jpg'
import season4Legs from '../resources/news/season4Legs.jpg'
import season4uniqueExamples from '../resources/news/season4uniqueExamples.jpg'
import itemTitleExample from '../resources/news/itemTitleExample.jpg'
import searchTitle from '../resources/news/searchTitle.png'
import pitCalculator from '../resources/news/pitCalculator.png'
import pitCalculatorIngolith from '../resources/news/pitCalculatorIngolith.png'
import pitCalculatorObducite from '../resources/news/pitCalculatorObducite.png'
import importFromClipboard from '../resources/news/importFromClipboard.png'
import greaterAffixDetection from '../resources/news/greaterAffixDetection.png'
import razorPlates from '../resources/news/razorPlates.png'
import twitchLink from '../resources/news/twitchLink.png'
import hideOwnItems from '../resources/news/hideOwnItems.png'
import uniqueAffixSearch from '../resources/news/uniqueAffixSearch.png'
import uniqueAffixSearchResult from '../resources/news/uniqueAffixSearchResult.png'
import sortExample from '../resources/news/sortExample.png'
import faq from '../resources/news/faq.png'
import priceCheck from '../resources/news/priceCheck.png'
import sortByPriceExample from '../resources/news/sortByPriceExample.png'
import anyRoyalGem from '../resources/news/anyRoyalGem.png'
import builds from '../resources/news/builds.png'

import {abyssalScroll, elixirOfHolyBolts, elixirOfMomentum, pincushionedDoll, profaneMindcage, sandScorchedShackles, stygianStone} from "../commons/wowhead/D4ItemLinks";
import {Builds, D4_Calendar, EiaNavigationLink, FAQ, Notifications, Search, Tools, Welcome} from "../components/EiaNavigationLink";
import * as Navigation from "../commons/enums/Navigation";
import {fistsOfFate, flickerStep, rakanothsWake, theOculus} from "../commons/wowhead/D4Uniques";

const getPatchLink = function(text, link) {
    return                 <Link
        href={link}
        color="#F4D03F"
        isExternal={true}
    >
        {text}
    </Link>
}

const getCalenderUpdateEntryWithLink = function(text, link) {
    return ["\u2022 updated ", D4_Calendar, " with ", getPatchLink(text, link)]
}

export const newsEntries = [
    {
        id: 72,
        title: "Season 6 Bosses",
        date: "2023/09/14",
        rows: 4,
        newsV2: [
            ["\u2022 Thanks to wowhead we were able to create a new boss table for season 6 - checkout ",
                <EiaNavigationLink key={"boss-summonings-s6"} relativeLink={Navigation.TOOLS_D4_BOSS_MATERIALS_S6} title={"Boss Summonings (Season 6)"} />],
            ["\u2022 updated notifications style and added titles to each new notification coming from today - checkout ", Notifications]
        ],
    },
    {
        id: 71,
        title: "Patch 1.5.2 and PTR",
        date: "2023/09/01",
        rows: 4,
        newsV2: [
            getCalenderUpdateEntryWithLink("Patch 1.5.2", "https://www.wowhead.com/diablo-4/news/diablo-4-season-5-patch-1-5-2-patch-notes-season-journey-required-helltide-346316"),
            getCalenderUpdateEntryWithLink("2.0 PTR", "https://www.wowhead.com/diablo-4/blue-tracker/news/eu/the-2-0-ptr-what-you-need-to-know-24130178"),
            ["\u2022 fixed various issues with screenshot item detection, e.g. for ", flickerStep, ", ", fistsOfFate, ", ", rakanothsWake, ", ", theOculus]
        ],
    },
    {
        id: 70,
        title: "March of the Goblins",
        date: "2023/08/23",
        rows: 4,
        newsV2: [
            getCalenderUpdateEntryWithLink("March of the Goblins", "https://www.wowhead.com/diablo-4/news/march-of-the-goblins-event-returns-to-diablo-4-on-august-27th-at-10-a-m-pdt-346030")
        ],
    },
    {
        id: 69,
        title: "Mothers Blessing extended",
        date: "2023/08/23",
        rows: 4,
        newsV2: [
            ["\u2022 Mother's Blessing will be extended by one day to make up for the delay. Check ", D4_Calendar],
            ["\u2022 added ", Builds, " - more builds features will come soon"]
        ],
        image: builds
    },
    {
        id: 68,
        title: "Patch 1.5.1, Mothers Blessing and Any Royal Gem",
        date: "2023/08/17",
        rows: 4,
        newsV2: [
            getCalenderUpdateEntryWithLink("Patch 1.5.1", "https://www.wowhead.com/diablo-4/news/diablo-4-patch-1-5-1-patch-notes-infernal-hordes-buffed-rogue-tempers-re-enabled-345905"),
            getCalenderUpdateEntryWithLink("Mother's Blessing", "https://www.wowhead.com/diablo-4/news/mothers-blessing-35-experience-and-50-gold-bonus-starting-august-20th-diablo-4-345879"),
            "\u2022 removed all gems but royal gems since only royal gems are relevant for trading",
            "\u2022 added 'Any Royal Gem' as tradeable item / currency. You can now sell 'Any Royal Gem' for a fixed price or trade items for 'Any Royal Gem' just like in Diablo 2"
        ],
        image: anyRoyalGem
    },
    {
        id: 67,
        title: "Little Fixes",
        date: "2023/08/13",
        rows: 4,
        newsV2: [
            "\u2022 added missing affix '+X_0 Maximum Evade Charges'",
            "\u2022 added price sorting to non table views"
        ],
        image: sortByPriceExample
    },
    {
        id: 66,
        title: "Sort Features",
        date: "2023/08/10",
        rows: 4,
        newsV2: [
            ["\u2022 added sort features to all item non table views, check e.g. ", Search],
            ["\u2022 default sort for ", Search, " shows the items with the most greater affixes first"],
            "\u2022 fixed notification date sorting"
        ],
        image: sortExample
    },
    {
        id: 65,
        title: "Season 5 fixes",
        date: "2023/08/09",
        rows: 4,
        newsV2: [
            ["\u2022 added item sub type 'Consumable' for ", abyssalScroll()],
            ["\u2022 added ", abyssalScroll(), " as tradable item and as currency"],
            "\u2022 fixed some affixes and added some missing season 5 affixes",
        ]
    },
    {
        id: 64,
        title: "Season 5",
        date: "2023/08/06",
        rows: 4,
        newsV2: [
            "Season 5 Trading is live!",
            "\u2022 renamed 'Uber Uniques' to 'Mythics'",
            "\u2022 updated all unique affixes for season 5",
            "\u2022 updated all aspects for season 5",
            ["\u2022 updated ", <EiaNavigationLink key={"boss-summoning"} relativeLink={Navigation.TOOLS_D4_BOSS_MATERIALS} title={"Boss Summoning"} />]
        ]
    },
    {
        id: 63,
        title: "Season 5 wipe",
        date: "2023/08/05",
        rows: 4,
        newsV2: [
            "\u2022 wiped all season listings",
            ["\u2022 added an alternative view of ", Notifications, " that works better especially for mobile devices"],
            "\u2022 renamed item sub type 'Material' to 'Summoning'",
            ["\u2022 added 'Last Updated' date to all ", Tools, " so you can check what is already updated for"
            + " the current season"],
            "\u2022 max price capped to 100,000 million gold / 100 billion gold each item to avoid misunderstandings",
            "There will be an update tomorrow with the latest affixes / aspects / elixirs for Season 5",
        ]
    },
    {
        id: 62,
        title: "Big Updates and Season 5",
        date: "2023/07/27",
        rows: 4,
        newsV2: [
            ["\u2022 added pages and sub pages to the url, so you can directly share links e.g. to ", <EiaNavigationLink relativeLink={Navigation.TOOLS_D4_BOSS_MATERIALS} />,
                " or ", <EiaNavigationLink relativeLink={Navigation.TOOLS_D4_PIT_MASTERWORKING} />
            ],
            ["\u2022 reworked and improved many item / listing views for all devices but especially for mobile devices. Checkout the new style e.g. at ",
                Search, " !"
            ],
            ["\u2022 updated ", FAQ, " and ", Welcome],
            "\u2022 we will wipe all listings at season 5 start"

        ]
    },
    {
        id: 61,
        title: "Youtube tutorials",
        date: "2023/06/30",
        rows: 4,
        newsV2: [
            "I started with some youtube tutorials that should help you with trading.",
            "\u2022 A link to the youtube channel is on the bottom of the screen",
            "\u2022 The sell youtube videos are embedded on the sell page, when you click the question mark",
            "\u2022 More youtube tutorials will follow",
        ],
        youtubeSrc: "https://www.youtube.com/embed/FYqNQPBhiKc?si=zodPxlGdF9uDTq9m"
    },
    {
        id: 60,
        title: "Price Check Beta",
        date: "2023/06/22",
        rows: 4,
        newsV2: [
            "I implemented a test version of item price checks. Just upload an item image or configure it manually on the sell page and then click on the price check button.",
            "As result you will see similar ACTIVE listings to get an idea of the item price.",
            "This depends on the market size - you will probably not get results for 4 greater affix items"
        ],
        image: priceCheck
    },
    {
        id: 59,
        title: "FAQ",
        date: "2023/06/19",
        rows: 4,
        newsV2: [
            "I try to make trading here as easy and convenient as possible but there are still questions from users. For now i added a FAQ and hope that we "
            + "will do not need it anymore soon. Check the FAQ at the bottom of the screen. If you have more questions then join our discord and ask me or other users.",
        ],
        image: faq
    },
    {
        id: 58,
        title: "Patch 1.4.3, Season 5 PTR, Unique affix filter",
        date: "2023/06/15",
        rows: 4,
        newsV2: [
            "\u2022 Updated d4 calendar with patch 1.4.3 coming next monday",
            "\u2022 Updated d4 calendar with patch Season 5 PTR campfire chat coming next friday",
            "\u2022 You can now filter for unique affixes to e.g. only show 'tibault's will' with max unique affix roll",
        ],
        images: [uniqueAffixSearch, uniqueAffixSearchResult]
    },
    {
        id: 57,
        title: "Twitch supporter",
        date: "2023/06/07",
        rows: 4,
        newsV2: [
            "\u2022 Added a link at the bottom of the screen to the german twitch streamer DolphDE who supports easyitemtrading.com. Checkout his stream",
            "\u2022 Increased the visible listings to use the space of screens with higher resolutions like 4k",
            "\u2022 Added switch to hide your own items from the search results",
            "\u2022 Updated our diablo 4 calendar with the mothers blessings event and the upcoming patch 1.4.2 which comes probably (not sure) on tuesday",
        ],
        images: [twitchLink, hideOwnItems]
    },
    {
        id: 56,
        title: "Greater Affix detection",
        date: "2023/06/04",
        rows: 4,
        newsV2: [
            "\u2022 Added greater affix detection from screenshots",
            "\u2022 Added support of multiple affixes with same name - afaik this was only an issue for 'Razorplate' what should work now",
        ],
        images: [greaterAffixDetection, razorPlates]
    },
    {
        id: 55,
        title: "Pit Calculators",
        date: "2023/06/03",
        rows: 4,
        newsV2: [
            "\u2022 Added pit (masterworking) calculator for ingolith and obducite to compare pit farming with tier lower 61 vs 61 or higher",
            "\u2022 Added feature to paste item screenshots from clipboard what makes it faster on pc if you use e.g. windows sniping tool",
        ],
        images: [pitCalculatorIngolith, pitCalculatorObducite, importFromClipboard]
    },
    {
        id: 54,
        title: "Pit Calculator",
        date: "2023/06/02",
        rows: 4,
        newsV2: [
            "\u2022 Added pit (masterworking) calculator so you can find the most effective pit to push your gear to level 12",
            "\u2022 Updated the nightmare dungeon calculator to season 4 with all the new gold, xp, glyph xp, obols rewards",
            "\u2022 Added the upcoming march of the goblins event to the d4 calendar",
        ],
        image: pitCalculator
    },
    {
        id: 53,
        title: "Title Search",
        date: "2023/05/17",
        rows: 4,
        newsV2: ["You can now search for specific titles what is especially useful for uniques. E.g. set the title 'frost' if you search FROSTBURN",
        ],
        image: searchTitle
    },
    {
        id: 52,
        title: "Item titles and fixes",
        date: "2023/05/16",
        rows: 4,
        newsV2: [
            "\u2022 Fixed editing unique items",
            "\u2022 Added auto detection of item titles - we will need this for easier unique item search (coming soon!)",
            "\u2022 You have to delete the old auctions and create new ones to see the title - it is not possible to set arbitrary titles to avoid misuse",
            ],
        image: itemTitleExample
    },
    {
        id: 51,
        title: "Season 4 Uniques",
        date: "2023/05/15",
        rows: 4,
        newsV2: ["Fixed several issues with unique item detection and selling. It should work now.",],
        image: season4uniqueExamples
    },
    {
        id: 50,
        title: "Season 4 Elixirs and Fixes",
        date: "2023/05/15",
        rows: 4,
        newsV2: ["I updated all the 80 new elixirs. You can start trade them now!",
            ["\u2022 Added tooltips for few elixirs - more will come soon ", elixirOfHolyBolts(), ", ", profaneMindcage(),", ", elixirOfMomentum()],
            "\u2022 Fixed legendary aspect detections",
        ],
        image: season4Legs
    },
    {
        id: 49,
        title: "Season 4 Detection fixes",
        date: "2023/05/15",
        rows: 4,
        newsV2: ["Some fixes were implemented - try your own legendary / unique items and send me bug reports if you find some"
        ],
        images: [season4LegExample1, season4LegExample2]
    },
    {
        id: 48,
        title: "Season 4 Stats Update",
        date: "2023/05/15",
        rows: 4,
        newsV2: ["The big stats update is there and you should be able to auto detect your new season 4 legendary and unique items now!",
            "\u2022 Updated 691 basic / implicit stats",
            "\u2022 Updated 259 aspects",
            "\u2022 Updated 116 unique affixes",
            "There are some aspect / unique affix detection bugs but you should be able to manually fix / add them if not detected correctly. I will try to fix the detection bugs"
            + " fast!"
        ],
        image: season4LegDetectionErrors
    },
    {
        id: 47,
        title: "Season 4 Start",
        date: "2023/05/14",
        rows: 4,
        newsV2: ["There are already many season 4 updates but the affix updates will come very soon",
            "\u2022 D4 calendar updates",
            "\u2022 Removed season 3 materials",
            "\u2022 Added unique item trading",
            "\u2022 Added unique auto detection via screenshot",
        ],
    },
    {
        id: 46,
        title: "Season 4 Preparations",
        date: "2023/04/07",
        rows: 4,
        newsV2: ["\u2022 D4 calendar updates",
            ["\u2022 Added season 4 materials ", stygianStone(), ", ", pincushionedDoll(),", ", sandScorchedShackles()],
            "\u2022 Added legendary/unique rarities",
            "\u2022 Added legendary auto detection via screenshot",
            "\u2022 Added greater affixes",
            "\u2022 Added Season 4 bosses to boss material tables",
        ],
        images: [greaterAffixExample, greaterAffixExample2]
    },
    {
        id: 45,
        title: "Campfire: Season 4 & PTR",
        date: "2023/03/18",
        rows: 4,
        news: "\u2022 D4 calendar update for 'Campfire: Season 4 & PTR' coming on next wednesday"
            + "\n\u2022 D4 calendar update for 'Diablo 4 joins Game Pass'"
            + "\n\u2022 Improved visibility for hovered items in non table-view"
        ,
        image: itemSelectionMouseOver
    },
    {
        id: 44,
        title: "D4 Patch 1.3.3 Gauntlet",
        date: "2023/03/02",
        rows: 4,
        news: "\u2022 D4 calendar update for 'Patch 1.3.3 Gauntlet' coming on next tuesday"
            + "\n\u2022 Mobile / desktop ui refinement for buttons"
            + "\n\u2022 Added tooltips to most buttons"
            + "\n\u2022 Changed positive button colors to green and negative button colors to red"
        ,
        image: tooltipButtonExample
    },
    {
        id: 43,
        title: "Tooltips",
        date: "2023/02/28",
        rows: 3,
        news: "10 days ago i added mouse over tooltips for materials. Now i also added tooltips to all other items."
            + "\n\u2022 Yellow item names indicate wowhead.com tooltips / links"
            + "\n\u2022 Teal item names are just tooltips"
        ,
        image: tooltipsExample2
    },
    {
        id: 42,
        title: "Non table view",
        date: "2023/02/25",
        rows: 5,
        news: "Added a non table view for a better user experience on mobile"
            + "\n\u2022 table view is switched off by default for search page on mobile"
            + "\n\u2022 you can switch the table view on/off dynamically on search page and best prices page"
            + "\n\u2022 there is no bid button on the non table view but you can click on the items to bid"
            + "\n Let me know how this feature works for you and what can be improved"
        ,
        image: nonTableView
    },
    {
        id: 41,
        title: "Trials and Gauntlet",
        date: "2023/02/25",
        rows: 6,
        news: "We will get a first look at Trials and Gauntlet on February 29 at 11 a.m. PST in the"
            + " next Diablo 4 Campfire Chat"
            + " materials"
            + "\n\u2022 Updated diablo 4 calendar"
            + "\n\u2022 Fixed armor items with dps values and weapons with armor values"
            + "\n\u2022 Best prices page shows now up to 3 best prices per item that has 0 or 1 bidder"
            + "\n\u2022 Changed table rows from 10 per page to 5 per page on mobile devices"
        ,
        image: d4calendarUpdate022024
    },
    {
        id: 40,
        title: "Tooltips",
        date: "2023/02/18",
        rows: 4,
        news: "\u2022 Added tooltips from wowhead.com for diablo 4 materials"
            + "\n\u2022 Fixed armor items with dps values and weapons with armor values"
        ,
        image: tooltipExample
    },
    {
        id: 39,
        title: "Diablo 4 calendar",
        date: "2023/02/17",
        rows: 4,
        news: "\u2022 Added diablo 4 calendar to see season duration, events duration, patch days etc."
            + "\n\u2022 Added timezone support to world boss calendar"
        ,
        image: d4calendar
    },
    {
        id: 38,
        title: "Nightmare Dungeon Calculator",
        date: "2023/02/12",
        rows: 4,
        news: "\u2022 Added nightmare dungeon calculator to check how long it takes to push glyph levels"
            + "\n\u2022 Updated some item stats for season 3"
        ,
        image: nmDungeonCalc
    },
    {
        id: 37,
        title: "Diablo 4 Season 3 Updates",
        date: "2023/02/07",
        rows: 4,
        news: "\u2022 Added igneous cores"
            + "\n\u2022 Updated guides pages"
            + "\n\u2022 Added notification link for active trades as seller"
            ,
    },
    {
        id: 36,
        title: "Reveal data settings",
        date: "2023/12/18",
        rows: 4,
        news: "\u2022 Added elixir to trade: Gileon's Brew"
            + "\n\u2022 Added gaming system to settings that might be helpful for traders if there are cross play"
            + " issues"
            + "\n\u2022 Added settings to reveal some data to all potential buyers so you can communicate and trade"
            + " faster",
        images: [x36_settings, x36_bestPrices]
    },
    {
        id: 35,
        title: "Tears of Blood Calculator",
        date: "2023/12/11",
        rows: 3,
        news: "Hi guys! I added a calculator for the Tears of Blood glyph we are leveling in Abattoir of Zir. E.g."
            + " if you clear a Level 10 AoZ dungeon in 5 Minutes, then you get the result from the calculator that"
            + " you need 50 hours to level the glyph from Level 10 to 50.",
        image: tearsOfBloodCalculator
    },
    {
        id: 34,
        title: "Winner list in discord",
        date: "2023/12/09",
        rows: 3,
        news: "I am updating the winner list every day in discord. The winners get contacted via e-mail or discord.",
        image: XmasGiveAway2023
    },
    {
        id: 33,
        title: "First winner this evening",
        date: "2023/12/06",
        rows: 3,
        news: "The first winner of 5 x Duriel Sets will get an E-Mail or Discord message in the next hours. " +
        "\nBy the way i filled the trade dialog with item/price information and improved the reminder link" +
            " in the top right corner so you get to the trade dialog with a single click if it is there",
        image: XmasGiveAway2023
    },
    {
        id: 32,
        title: "Christmas Special Give Away",
        date: "2023/12/05",
        rows: 4,
        news: "Thank you guys for the feedback so far. I want to give something back with a daily '5 x Duriel Sets'"
            + " giveaway for every registered user until December 24th starting on December 6th."
    + "\nAdditional new features:"
    + "\n\u2022 You can now trade 'Random Elixir' for the case you are only interested in the xp buff"
    + "\n\u2022 You can now reject bids as seller to give the bidders feedback",
        image: XmasGiveAway2023
    },
    {
        id: 31,
        title: "Little feature list",
        date: "2023/11/30",
        rows: 4,
        news: "\u2022 You will have a clickable reminder message if you have accepted bids and need to contact the"
            + " seller "
            + "\n\u2022 It is now easier to see that you have new notifications with a clickable bell that sends you"
            + " to the notifications"
            + "\n\u2022 The settings are easier to see and find"
            + "\n\u2022 You can now change your password in the account settings",
        image: reminderExample
    },
    {
        id: 30,
        title: "Little improvements and bug fixes",
        date: "2023/11/26",
        rows: 5,
        news: "Thank you for the feedback! We get new users every day! I fixed little bugs and improved names /"
            + " styles / selects / buttons."
            + "\n\u2022 You can now set the total price or the price each item if you sell or edit"
            + "\n\u2022 Renamed season 2 page to guides page since they are not season specific"
            + "\n\u2022 Added 'Reload Table' buttons so you do not have to switch between tabs anymore to reload"
            + "\n\u2022 Some bug fixes and improvements for mobile view",
    },
    {
        id: 29,
        title: "Added feature to create lower bids or other currencies",
        date: "2023/11/19",
        rows: 3,
        news: "Even if the seller wants e.g. 500 Million Gold for his item, you have the possibility to offer 300"
            + " Million Gold or even another currency and the seller can decide if he wants to accept.",
        image: bidDialog
    },
    {
        id: 28,
        title: "World boss schedule and material changes",
        date: "2023/11/17",
        rows: 3,
        news: "There is a world boss week schedule in the 'Season 2' tab for people like me who like to go as often as"
            + " possible to the world boss :-). Besides i added the varshan and duriel material sets to the"
            + " 'Material' category so you an easier overview on the 'Best Prices' page. I will remove the 'Material"
            + " Set' category later.",
        image: worldBossSchedule
    },
    {
        id: 27,
        title: "Country selection and simplified trade dialog",
        date: "2023/11/15",
        rows: 2,
        news: "You can now optionally select your country and that information will be shown in the simplified trade"
            + " dialog. This could help to communicate",
        images: [settingsWithCountry, tradeSimplified]
    },
    {
        id: 26,
        title: "Quick find best material prices",
        date: "2023/11/12",
        rows: 3,
        news: "The last days i got often the same question about the current material prices. That's why i added a"
            + " page to quickly find and bid e.g. the current best material prices each material on"
            + " www.easyitemtrading.com to have a good overview.",
        image: bestPricesExample
    },
    {
        id: 25,
        title: "Small Fixes",
        date: "2023/11/12",
        rows: 5,
        news: "Thank you for the feedback! I fixed little bugs."
            + "\n\u2022 Fixed rarity for screenshot auto item detections"
            + "\n\u2022 Fixed notifications on mobile"
            + "\n\u2022 Fixed doubled item view on bids page"
            + "\n\u2022 Removed error message if a buyer does not have set his battle tag - it is enough that the"
            + " seller has set it",
    },
    {
        id: 24,
        title: "Sales offers presentation",
        date: "2023/11/11",
        rows: 1,
        news: "You can now open a sales presentation with filter features to screenshot it and easy post your offers"
            + " in discord or other forums / chats.",
        image: salesOffersExample
    },
    {
        id: 23,
        title: "Small fixes",
        date: "2023/11/11",
        rows: 5,
        news: "Thank you for the feedback! I got some reports of little bugs and fixed some of them."
            + "\n\u2022 Fixed discord invite link that was expired"
            + "\n\u2022 Fixed battle tag setting with special characters like ä, ö, ü etc."
            +"\n\u2022 Fixed currency select in search options. E.g. you could not select back to 'Any' after you"
            + " searched for a specific currency"
            +"\n\u2022 Fixed news pagination so you can jump through more pages and not only to the next/previous page",
    },
    {
        id: 22,
        title: "Implicit Stats and Rarities",
        date: "2023/11/10",
        news: "\u2022 Although almost noone is interested in magic and normal items we implemented rarities, so you"
            + " can be sure that you bid on a rare item and not magical or normal. "
            +  "\n\u2022 We implemented the differentiation between implicit stats and affix stats. This makes the item previews more convenient.",
        images: [implicitStatsRareExample, implicitStatsMagicExample]
    },
    {
        id: 21,
        title: "Browser notifications",
        date: "2023/11/08",
        news: "After the login you get asked if you want to activate browser notifications. If you accept then you"
            + " get browser notifications whenever you get new notifications because of e.g. bids, expirations etc.",
        image: browserNotifications
    },
    {
        id: 20,
        title: "Added new services and dark mode",
        date: "2023/11/08",
        news: "You can now sell 'Level 1-50' service and the 'T3+T4' World Tier Unlock. Besides there is a dark mode"
            + " switch in the settings dialog and dark mode is default now.",
        image: darkMode
    },
    {
        id: 19,
        title: "Added event service",
        date: "2023/11/07",
        news: "You can now sell your 150 Blood Lures to start the Blood Harvest Season 2 event for other players."
            + " You might have too many of them with your level 100 character and could help lower characters with"
            + " this boost and get money for it",
    },
    {
        id: 18,
        title: "Mobile view improvements",
        date: "2023/11/06",
        news: "The mobile view was terrible and we started to improve it. There will come more mobile view"
            + " improvements in the next couple of days",
    },
    {
        id: 17,
        title: "Added Edit Dialog",
        date: "2023/11/05",
        news: "You can now edit your auctions on the 'My Sales' page if there are no active bidders. This is a very"
            + " good"
            + " quality"
            + " of"
            + " life"
            + " feature since you can fix errors or e.g. reduce the prices if you cannot sell your items for set"
            + " price.",
    },
    {
        id: 16,
        title: "Added discord name to settings",
        date: "2023/11/02",
        news: "You can now add your optional discord name in settings. This may help your trade partners to"
            + " communicate."
            + " You can see the discord name from your trade partner in the trade dialog.",
    },
    {
        id: 15,
        title: "Changed some styles",
        date: "2023/11/01",
        news: "The item card looks a bit better now, there is a background image and we have a new logo",
        image: newItemStyle
    },
    {
        id: 14,
        title: "Added services to trade",
        date: "2023/10/31",
        news: "You can now trade services like world tier unlocks or boss kills. Tell me in discord or contact me via"
            + " e-mail if you want more services.",
    },
    {
        id: 13,
        title: "Mobile image compression",
        date: "2023/10/29",
        news: "You can now take a picture of an item from your phone and upload it on the sell page. We only support"
            + " images to 750kb at the end but we added an image compression that can compress images for e.g. 3 MB to"
            + " 150 kb. The quality is very bad then but the item auto detection on the sell page can handle it.",
        image: badImageQuality
    },
    {
        id: 12,
        title: "Item Views",
        date: "2023/10/27",
        news: "There are now simple item views for selected auctions / bids in the tables.",
        image: itemViewExample
    },
    {
        id: 11,
        title: "Diablo 4 Season 2",
        date: "2023/10/23",
        news: "The season 2 boss summon materials can now be traded. I added a 'Season' tab with a boss material overview.",
    },
    {
        id: 10,
        title: "Item auto detection finished",
        date: "2023/09/09",
        news: "The item type detection works fine now. Send me a bug report if an item detection does not work and i try"
            + " to fix it - thanks.",
    },
    {
        id: 9,
        title: "Item stats auto detection from image",
        date: "2023/09/07",
        news: "The auto stat detection for item images works fine. Just go to the sell page and drop an image of the"
            + " item. All stats should be detected and filled automatically. The item type and item sub type is not"
            + " working yet but will come soon.",
        image: itemReadingOnSellPage
    },
    {
        id: 8,
        title: "Removed platform and region. Big convenience feature coming soon...",
        date: "2023/08/11",
        news: "I removed the platform and region selection because it was needed for my Diablo 2 plans but we do not"
            + " need it for Diablo 4. "
            + "Besides i am working on a big convenience feature that would read the screenshots of your items so you do"
            + " not have to enter the stats manually. I am on vacation for 2 weeks and hope to finish this feature soon"
            + " after the vacation.",
        image: itemReaderExample
    },
    {
        id: 7,
        title: "Class affixes, AutoComplete Selection ",
        date: "2023/08/10",
        news: "Additional to the core item affixes, i added all the class specific affixes. You can now select about 280"
            + " stats - this is too much for a usual"
            + " selection box. So i changed the stat Selection Boxes to AutoComplete Boxes so you can search for stats."
    },
    {
        id: 6,
        title: "Eternal realm, more items and bidder selection",
        date: "2023/08/07",
        news: "I added the selection of Eternal realm, some missing items (polearm, book, shield) and added the feature"
            + " to manually select a bidder when multiple people want to buy your item. At the footer you can now see a"
            + " 'Bug Report' and a 'Contact' button. Feel free to contact me."
    },
    {
        id: 5,
        title: "Hardcore mode, rings, amulets and all stats",
        date: "2023/08/04",
        news: "You can now select softcore or hardcore mode to trade. I added rings and amulets to trade. Besides i added"
            + " all the possible stats i found for yellow items which"
            + " are the best tradeable items for now. I found 63 stats and hope i did not miss some - otherwise contact"
            + " me please."
    },
    {
        id: 4,
        title: "Support for armor, gems, elixirs and stats",
        date: "2023/08/03",
        news: "You can now add stats like crit damage or vulnerable damage to a weapon or armor"
            + " when you sell it. Besides"
            + " you can filter on the search page for these stats. E.g. you can filter for weapons that have at least"
            + " 2500 dps and 50% crit damage and 50% vulnerable damage. Besides you can trade an amount of gems /"
            + " elixirs, e.g. you can sell 10 elixirs for 2 Million gold."
    },
    {
        id: 3,
        title: "Diablo 4 Weapon Trading is live",
        date: "2023/07/29",
        news: "EasyItemTrading is ready to trade Diablo 4 Weapons in Europe/Softcore/Season. More Item Types and region,"
            + " mode, realm options will come soon. For now probably noone will use this page because i have no"
            + " marketing for now but i will start soon."
    },
    {
        id: 2,
        title: "Switch to Diablo 4",
        date: "2023/07/19",
        news: "Greetings! As you can see i did not have time for this project and i also played much Diablo 4 ;-) So i"
            + " decided to switch this project from Diablo 2 to Diablo 4."
    },
    {
        id: 1,
        title: "First news entry",
        date: "2022/11/16",
        news: "Greetings! This site is still under development but you will soon be able to trade diablo 2 resurrected"
            + " items here."
    },
]

const newsPerPage = 3;
const totalPages = Math.ceil(newsEntries.length / newsPerPage);

const NewsPage = function(props) {

    const sortedNewsEntries = sortDesc(newsEntries, "id");

    const [currentPage, setCurrentPage] = React.useState(1)

    return (
        <EiaCard>
            <EiaPagination
                totalPages={totalPages} currentPage={currentPage} setCurrentPage={setCurrentPage} positionBottom={true}
            >
                <Flex direction={"column"}>
                    {
                        sortedNewsEntries.map((newsEntry, index) => {
                            if(index < currentPage * newsPerPage && index >= (currentPage-1) * newsPerPage) {
                                return <NewsEntry key={index} newsObject={newsEntry} />
                            } else {
                                return null;
                            }
                        })
                    }
                </Flex>
            </EiaPagination>
        </EiaCard>
    )
}
export default NewsPage;
