import {DropZone, Flex, VisuallyHidden} from '@aws-amplify/ui-react';
import React from "react";
import EiaButton from "./EiaButton";
import Utils from "../commons/Utils/Utils";

const acceptedFileTypes = ['image/png', 'image/jpeg'];
const acceptedFileEndings = ['png', 'jpeg'];
const isMobile = Utils.isMobile();

const EiaDropZone = function (props) {

    const {handleFiles, isLoading} = props;

    const hiddenInput = React.useRef(null);

    const onFilePickerChange = (event) => {
        const {files} = event.target;
        if (!files || files.length === 0) {
            return;
        }
        handleFiles(Array.from(files));
    };

    const importImageFromClipboard =
        async (clipboardItem, fileType, fileEnding) => {
            const blob = await clipboardItem.getType(fileType);
            const file = new File([blob], 'clipboard-image.' + fileEnding, {
                type: fileType,
            });
            handleFiles([file])
        }

    const importFromClipboard =
        async () => {
            const clipboardItems = await navigator.clipboard.read();
            for (const clipboardItem of clipboardItems) {
                acceptedFileEndings.forEach(fileEnding => {
                    const fileType = 'image/' + fileEnding;
                    if (clipboardItem.types.includes(fileType)) {
                        importImageFromClipboard(clipboardItem, fileType, fileEnding);
                    }
                })
            }
        }



    return (
        <DropZone
            acceptedFileTypes={acceptedFileTypes}
            onDropComplete={({acceptedFiles, rejectedFiles}) => {
                handleFiles(acceptedFiles);
            }}
            isDisabled={isLoading}
        >
            <Flex direction={"column"}>
                <p>
                    Drop file or
                </p>
                <EiaButton size="small" onClick={() => hiddenInput.current.click()}>
                    Browse
                </EiaButton>
                {
                    isMobile ? null : <div>
                        <p>
                            or
                        </p>
                        <EiaButton size="small" onClick={importFromClipboard}>Import from Clipboard</EiaButton>
                    </div>
                }
                <p>
                    Format: jpeg or png
                </p>
            </Flex>
            <VisuallyHidden>
                <input
                    type="file"
                    tabIndex={-1}
                    ref={hiddenInput}
                    onChange={onFilePickerChange}
                    multiple={false}
                    accept={acceptedFileTypes.join(',')}
                />
            </VisuallyHidden>
        </DropZone>
    )
};
export default EiaDropZone;