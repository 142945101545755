import React from "react";
import {TextField} from "@aws-amplify/ui-react";

const EiaTextField = function(props) {
    const {validator, ...restProps} = props;

    const setValidated = function(value) {
        let doChange = validator ? validator(value) === true : true;

        if(doChange) {
            let event = {
                currentTarget: {
                    value: value
                }
            };
            props.onChange(event);
        }
    }
    return (
        <div>
            <TextField
                {...restProps}
                onChange={(e) => setValidated(e.currentTarget.value)}
            />
        </div>
    )
}
export default EiaTextField;