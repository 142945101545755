

// export const PLATFORMS = ["PC", "SWITCH", "PLAYSTATION", "XBOX"];
// export const REGIONS = ["EUROPE", "AMERICAS", "ASIA"];
// export const MODES = ["SOFTCORE", "HARDCORE"];
// export const LADDER = ["LADDER", "Non Ladder"];

// export const GAMES = ["Diablo 4", "Diablo 2 Resurrected"]
export const GAMES = ["Diablo 4"]

