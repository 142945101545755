import * as React from 'react';
import {Flex, Grid, Heading} from "@aws-amplify/ui-react";
import EiaPaginatedItems from "./EiaPaginatedItems";
import LoadingDialog from "./dialogs/LoadingDialog";
import Utils from "../commons/Utils/Utils";
import EiaCard from "./cards/EiaCard";
import AlertCard from "./cards/AlertCard";
import {auctionSortByGreaterAffix, auctionSortByStatusAndBidders, sortByCreationDate, sortByPricePerItem} from "../commons/Utils/SortUtils";
import EiaSelect from "./EiaSelect";

const isSmallScreen = Utils.isSmallScreen();
const itemColumns = Utils.getColumnSizeForItems();
const itemRows = 4;

const itemsPerPage = isSmallScreen ? 10 : itemColumns * itemRows;

const sortValues = ["Sort by Creation Date", "Sort by Status / Bidders", "Sort by Greater Affix", "Sort by Price"]

const EiaAuctionViewWithButtons = function (props) {

    const {
        buttons,
        alert,
        onClickAuction,
        isLoading,
        auctions,
        heading,
        infoMessage,
        showDetails = false,
        supportSorting = false,
        defaultSortIndex = 0,
        isViewFromSeller = false,
        isViewFromBidder = false
    } = props;

    const [currentPage, setCurrentPage] = React.useState(1)
    const [sortIndex, setSortIndex] = React.useState(defaultSortIndex);

    let sortedAuctions = auctions;

    if(supportSorting) {
        sortedAuctions = sortedAuctions ? [...sortedAuctions] : [];

        if (sortIndex == 0) {
            sortedAuctions = sortedAuctions.sort(sortByCreationDate);
        } else if (sortIndex == 1) {
            sortedAuctions = sortedAuctions.sort(auctionSortByStatusAndBidders)
        } else if (sortIndex == 2) {
            sortedAuctions = sortedAuctions.sort(auctionSortByGreaterAffix)
        } else if(sortIndex == 3) {
            sortedAuctions = sortedAuctions.sort(sortByPricePerItem)
        }
    }



    return (
        <div>
            <LoadingDialog open={isLoading}/>
            <Grid templateColumns="2fr 9fr">
                {alert ? <AlertCard alert={alert}/> : null}
                <EiaCard columnStart="1" columnEnd={isSmallScreen ? "-1" : "2"}>
                    <Flex direction={"column"}>
                        {buttons}
                        {
                            supportSorting ?
                                <EiaSelect values={sortValues} value={sortIndex} onChange={(e) => {
                                    setSortIndex(e.target.value)
                                }}/> : null
                        }
                    </Flex>
                </EiaCard>
                <EiaCard
                    columnStart={isSmallScreen ? "1" : "2"}
                    columnEnd="-1"
                >
                    {heading ? <Heading>{heading}</Heading> : null}
                    <EiaPaginatedItems itemsPerPage={itemsPerPage} columns={itemColumns} currentPage={currentPage} setCurrentPage={setCurrentPage} auctions={sortedAuctions}
                                       onClickAuction={onClickAuction} showDetails={showDetails} isViewFromSeller={isViewFromSeller}
                                       isViewFromBidder={isViewFromBidder}/>
                    {infoMessage ? infoMessage : null}
                </EiaCard>
            </Grid>
        </div>
    );
}
export default EiaAuctionViewWithButtons;