import React from "react"
import {Flex, Heading, Menu, MenuButton, MenuItem, Tabs} from "@aws-amplify/ui-react";
import EiaCard from "../components/cards/EiaCard";
import AoZCalculator from "../components/calculators/AoZCalculator";
import NmCalculator from "../components/calculators/NmCalculator";
import D4Calendar from "./tools/D4Calendar";
import WorldBossCalendar from "./tools/WorldBossCalendar";
import PitCalculator_Neathiron from "../components/calculators/PitCalculator_Neathiron";
import PitCalculator_Ingolith from "../components/calculators/PitCalculator_Ingolith";
import PitCalculator_Obducite from "../components/calculators/PitCalculator_Obducite";
import EiaHelpButton from "../components/EiaHelpButton";
import YoutubeVideo from "../components/YoutubeVideo";
import Utils from "../commons/Utils/Utils";
import {useLocation, useNavigate} from "react-router-dom";
import {getFirstIndexOfKeyValueMatch} from "../commons/Utils/ArrayUtils";
import * as Navigation from "../commons/enums/Navigation";
import BossSummoning from "./tools/BossSummoning";
import BossSummoningS6 from "./tools/BossSummoningS6";

const isSmallScreen = Utils.isSmallScreen();

const tabValues = [
    {value: Navigation.SUB_PATH_D4_CALENDAR, title: "Diablo 4 calendar"},
    {value: Navigation.SUB_PATH_D4_WORLDBOSS_CALENDAR, title: "World boss calendar"},
    {value: Navigation.SUB_PATH_D4_BOSS_MATERIALS, title: "Boss Summoning (Season 5)"},
    {value: Navigation.SUB_PATH_D4_BOSS_MATERIALS_S6, title: "Boss Summoning (Season 6)"},
    {value: Navigation.SUB_PATH_D4_PIT_MASTERWORKING, title: "Pit Masterworking"},
    {value: Navigation.SUB_PATH_D4_NM_DUNGEONS, title: "Nightmare Dungeons"},
    {value: Navigation.SUB_PATH_D4_AOZ, title: "Abattoir of Zir (Season 2)"}
]

const tabContents = [
    <EiaCard>
        <D4Calendar/>
    </EiaCard>,

    <EiaCard>
        <WorldBossCalendar/>
    </EiaCard>,

    <EiaCard>
        <BossSummoning/>
    </EiaCard>,

    <EiaCard>
        <BossSummoningS6/>
    </EiaCard>,

    <div>
        <EiaCard>
            <Flex direction={"row"}>
                <Heading level={4}>Calculate the fastest way to masterwork your items</Heading>
                <EiaHelpButton title={"Calculate the fastest way to masterwork your items"}>
                    <YoutubeVideo src={"https://www.youtube.com/embed/moGgDz0kJ34?si=y321fuXYihnX80U7"}/>
                </EiaHelpButton>
            </Flex>
        </EiaCard>
        <Tabs.Container isLazy defaultValue={"Neathiron"} justifyContent="flex-start">
            <Tabs.List>
                <Tabs.Item value="Neathiron">{"Neathiron"}</Tabs.Item>
                <Tabs.Item value="Ingolith">{"Ingolith"}</Tabs.Item>
                <Tabs.Item value="Obducite">{"Obducite"}</Tabs.Item>
            </Tabs.List>
            <Tabs.Panel value="Neathiron"><PitCalculator_Neathiron/></Tabs.Panel>
            <Tabs.Panel value="Ingolith"><PitCalculator_Ingolith/></Tabs.Panel>
            <Tabs.Panel value="Obducite"><PitCalculator_Obducite/></Tabs.Panel>
        </Tabs.Container>
    </div>,

    <NmCalculator/>,

    <AoZCalculator/>
]

const defaultContentIndex = 3;

const ToolsPage = function (props) {

    const navigateWithRouter = useNavigate();

    const navigate=function(value) {
        if(isSmallScreen) {
            Utils.scrollToStickyNavigationBar();
        }

        navigateWithRouter("/"+Navigation.TOOLS+"/" + value);
    }

    let location = useLocation();
    const {basePath, subPath} = Utils.getPaths(location.pathname);

    const currentContent = subPath ? tabContents[getFirstIndexOfKeyValueMatch(tabValues, "value", subPath)] : tabContents[defaultContentIndex];

    return isSmallScreen ?

            <EiaCard>
                <Flex direction={"column"}>
                    <Menu
                        size={"large"}
                        menuAlign="start"
                        trigger={
                            <MenuButton variation="outlined" size="large">
                                Select Tool ...
                            </MenuButton>}
                    >
                        {
                            tabContents.map((tabContent, index) => {
                                return (<MenuItem key={index} onClick={() => navigate(tabValues[index].value)}>
                                    {tabValues[index].title}
                                </MenuItem>)
                            })
                        }
                    </Menu>
                    {currentContent}
                </Flex>
            </EiaCard>

        :

        <Tabs.Container defaultValue={subPath ? subPath : tabValues[defaultContentIndex].value} isLazy justifyContent="flex-start" onValueChange={(value) => navigate(value)}>
            <Tabs.List>
                {tabValues.map((tabValue) => <Tabs.Item key={tabValue.value} value={tabValue.value}>{tabValue.title}</Tabs.Item>)}
            </Tabs.List>
            {
                tabContents.map((tabContent, index) =>
                    <Tabs.Panel key={index} value={tabValues[index].value}>
                        {tabContent}
                    </Tabs.Panel>)
            }
        </Tabs.Container>;
}
export default ToolsPage;