import React from "react";
import {Dialog, LinearProgress} from "@mui/material";
import EiaThemeProvider from "../EiaThemeProvider";
import EiaCard from "../cards/EiaCard";
import {Divider, Heading} from "@aws-amplify/ui-react";

function LoadingDialog(props) {
    const { open } = props;

    return (
        <Dialog open={open ? open : false}>
            <EiaThemeProvider>
                <EiaCard>
                    <EiaCard>
                        <Heading level={3}>Loading...</Heading>
                    </EiaCard>
                    <Divider/>
                    <EiaCard>
                        <LinearProgress></LinearProgress>
                    </EiaCard>
                </EiaCard>
            </EiaThemeProvider>
        </Dialog>
    );
}

export default LoadingDialog;