import React from "react";
import {Flex, Message, Text} from "@aws-amplify/ui-react";
import EiaCard from "../../components/cards/EiaCard";
import {MySales, Notifications} from "../../components/EiaNavigationLink";
import EiaTitle from "../../components/EiaTitle";

const FaqPage = function () {

    return (<EiaCard>
            <Flex direction={"column"}>
                <EiaTitle title={"FAQ"} lastUpdated={"2024/09/07"}/>
                <Message
                    heading="How do the notifications work?">
                    <Text>
                    {["Check ", Notifications ," or click on the red bell on the top right corner of the screen to see your notifications. If you do not use a mobile"
                        + " device, you can also allow the browser to send you system notifications (e.g. for windows)."]}
                    </Text>
                </Message>
                <Message
                    heading="I placed a bid on an item - what now?">
                    {"The seller got a notification of your bid. The seller can accept or reject your bid. Be patient because the seller might not be online. Some sellers show"
                        + " their battle tag or discord name to everyone - in this case you can contact the seller. You get a notification if the seller reacts to your bid."}
                </Message>
                <Message
                    heading="I created a listing / auction - what now?">
                    <Text>
                        {["You get a notification if someone bids on your item. Bidders can suggest another price or even another currency. You can accept or reject bids if you"
                        + " select your listing at ", MySales, " if you click on your listing and then on the 'Bids ... ' button."]}
                    </Text>
                </Message>
                <Message
                    heading="I want to change the price of my listing - do i have to abort and recreate it?">
                    <Text>
                        {["You can select your listing / auction at ", MySales,
                            " and then click on 'Edit ... ' to change the price or even the stats if there is a mistake."]}
                    </Text>
                </Message>
                <Message
                    heading="I got a notification that my auction expired - what is this?">
                    <Text>
                        {["EasyItemTrading automatically expires auctions to clean the search from inactive auctions / users. If you still want to sell the item of the auction,"
                        + " then just select the auction at ", MySales, " page and click on 'Reactivate'"]}
                    </Text>
                </Message>
                <Message
                    heading="Why do i only see 100 items on the search page?">
                    {"The default search without filters only shows 100 items to prevent unnecessary data traffic. If you use filters, you will get all the filtered data."}
                </Message>
                <Message
                    heading="I created a listing / auction - why do i not see it as first item on the search page?">
                    {"First check the sort selection - it should be 'Sort by Creation Date'. The default search only shows the latest 100 items of 1000 random items from the"
                        + " database."}
                </Message>
                <Message
                    heading="Why do i not see my notifications from last year?">
                    {"All notifications have a 'time to live' of 12 months. Older notifications than 12 months get automatically deleted."}
                </Message>
            </Flex>
        </EiaCard>
    );
}

export default FaqPage;