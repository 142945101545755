import HoursToReachLevelCalculator from "./HoursToReachLevelCalculator";
import React from "react";
import {Flex, Heading} from "@aws-amplify/ui-react";
import EiaFixedTable from "../tables/EiaFixedTable";

const pitDungeonHeader = ["Pit Tier", "Monster Level", "Gold Reward", "Obducite Reward", "Ingolith Reward", "Neathiron"
+ " Reward"];
const pitDungeonTable = [
    [1,100,82000,10,0,0],
    [2,101,83600,11,0,0],
    [3,102,85200,12,0,0],
    [4,103,86800,13,0,0],
    [5,104,88400,14,0,0],
    [6,105,90000,15,0,0],
    [7,106,91600,16,0,0],
    [8,107,93200,17,0,0],
    [9,108,94800,18,0,0],
    [10,109,96400,19,0,0],
    [11,110,98000,20,0,0],
    [12,111,99600,21,0,0],
    [13,112,101200,22,0,0],
    [14,113,102800,23,0,0],
    [15,114,104400,24,0,0],
    [16,115,106000,25,0,0],
    [17,116,107600,26,0,0],
    [18,117,109200,27,0,0],
    [19,118,110800,28,0,0],
    [20,119,112400,29,0,0],
    [21,120,114000,30,0,0],
    [22,121,115600,31,0,0],
    [23,122,117200,32,0,0],
    [24,123,118800,33,0,0],
    [25,124,120400,34,0,0],
    [26,125,122000,35,0,0],
    [27,126,123600,36,0,0],
    [28,127,125200,37,0,0],
    [29,128,126800,38,0,0],
    [30,129,128400,39,0,0],
    [31,130,130000,0,20,0],
    [32,131,131600,0,21,0],
    [33,132,133200,0,22,0],
    [34,133,134800,0,23,0],
    [35,134,136400,0,24,0],
    [36,135,138000,0,25,0],
    [37,136,139600,0,26,0],
    [38,137,141200,0,27,0],
    [39,138,142800,0,28,0],
    [40,139,144400,0,29,0],
    [41,140,146000,0,30,0],
    [42,141,147600,0,31,0],
    [43,142,149200,0,32,0],
    [44,143,150800,0,33,0],
    [45,144,152400,0,34,0],
    [46,145,154000,0,35,0],
    [47,146,155600,0,36,0],
    [48,147,157200,0,37,0],
    [49,148,158800,0,38,0],
    [50,149,160400,0,39,0],
    [51,150,162000,0,40,0],
    [52,151,163600,0,41,0],
    [53,152,165200,0,42,0],
    [54,153,166800,0,43,0],
    [55,154,168400,0,44,0],
    [56,155,170000,0,45,0],
    [57,156,171600,0,46,0],
    [58,157,173200,0,47,0],
    [59,158,174800,0,48,0],
    [60,159,176400,0,49,0],
]

const masterWorkingHeader = ["Masterwork Level", "Total Ingolith (from level 0)", "Ingolith (or equivalent) to next level"];
const masterWorkingData = [
    [0,	0,	3.333333333],
    [1,	3.333333333,	6.666666667],
    [2,	10,	10],
    [3,	20,	13.33333333],
    [4,	33.33333333,	20],
    [5,	53.33333333,	40],
    [6,	93.33333333,	80],
    [7,	173.3333333,	120],
    [8,	293.3333333,	0],
    ]
;

const pitTables = <Flex direction={"column"}>
    <Heading level={6}>Pit Reward Table</Heading>
    <EiaFixedTable data = {pitDungeonTable} tableHeader={pitDungeonHeader}/>
    <Heading level={6}>Masterworking Table</Heading>
    <EiaFixedTable data = {masterWorkingData} tableHeader={masterWorkingHeader}/>
</Flex>

const PitCalculator_Ingolith = function() {

    return(
        <HoursToReachLevelCalculator
            title={"Pit Masterwork Calculator (Ingolith)"}
            lastUpdated={"2024/08/02"}
            initialCurrentLevel={0}
            initialTargetLevel={8}
            initialDungeonTier={60}
            initialDungeonClearMinutes={5}
            currentLevelText={"Masterwork Current Level"}
            targetLevelText={"Masterwork Target Level"}
            dungeonTierText={"Pit tier you run"}
            dungeonClearMinutesText={"Pit clear time (minutes)"}
            levelXpData={masterWorkingData}
            dungeonXpRewardData={pitDungeonTable}
            tables={pitTables}
            levelMax={8}
            dungeonTierMax={60}
            rewardToLevelIndex={4}
            rewardName={"ingolith"}
            doRoundRewardToK={false}
        />
    )
}

export default PitCalculator_Ingolith;