import {post} from "./ApiPost";

export async function d4EditAuction(json, successHandler, errorHandler) {
    post(json, successHandler, errorHandler, "/d4/actions/edit");
}

export async function d4RemoveNotifications(json, successHandler, errorHandler) {
    post(json, successHandler, errorHandler, "/d4/notifications/remove");
}

export async function d4GetNotifications(json, successHandler, errorHandler) {
    post(json, successHandler, errorHandler, "/d4/notifications/get");
}

export async function d4SearchAuctions(json, successHandler, errorHandler) {
    post(json, successHandler, errorHandler, "/d4/auctions/search");
}

export async function d4CreateAuction(json, successHandler, errorHandler) {
    post(json, successHandler, errorHandler, "/d4/auctions");
}

export async function d4CreateBid(json, successHandler, errorHandler) {
    post(json, successHandler, errorHandler, "/d4/auctions/createbid");
}

export async function d4GetMyBids(json, successHandler, errorHandler) {
    post(json, successHandler, errorHandler, "/d4/auctions/getmybids");
}

export async function d4GetMyAuctions(json, successHandler, errorHandler) {
    post(json, successHandler, errorHandler, "/d4/auctions/getmy");
}

export async function d4ChangeAuctionStatus(json, successHandler, errorHandler) {
    post(json, successHandler, errorHandler, "/d4/auctions/changestatus");
}

export async function d4UpdateBid(json, successHandler, errorHandler) {
    post(json, successHandler, errorHandler, "/d4/auctions/updatebid");
}
