import {Divider, Heading, Text} from "@aws-amplify/ui-react";
import {COLOR_GRAY, COLOR_TEAL} from "../commons/enums/ColorEnums";
import React from "react";

export const EiaTitle = function(props) {

    const {title, lastUpdated} = props;

    return <div>
            <Heading level={5} style={{color: COLOR_TEAL}}>{title}</Heading>
            {lastUpdated ? <Text fontSize={"0.75em"} style={{color: COLOR_GRAY}}>{"Last Updated: " + lastUpdated}</Text> : null}
            <Divider/>
        </div>
}
export default EiaTitle;