import React from "react";

import {Authenticator, useAuthenticator} from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

import amplifyconfiguration from './amplifyconfiguration.json';
import MainPage from "./pages/MainPage";
import EiaThemeProvider from "./components/EiaThemeProvider";
import {Amplify} from "aws-amplify";

Amplify.configure(amplifyconfiguration);

export default function App() {

    const [openLoginRegisterDialog, setOpenLoginRegisterDialog] = React.useState(false);

    const {authStatus} = useAuthenticator(context => [context.authStatus]);

    const loggedIn = authStatus === 'authenticated';

    const {signOut} = useAuthenticator((context) => [context.user]);

    const goBackToStartPage = function () {
        setOpenLoginRegisterDialog(false)
        signOut();
    }

    return (
        <EiaThemeProvider>
            {
                loggedIn || !openLoginRegisterDialog ? <MainPage setOpenLoginRegisterDialog={setOpenLoginRegisterDialog} signOut={goBackToStartPage}></MainPage>
                    : <Authenticator></Authenticator>
            }
        </EiaThemeProvider>
    );
}