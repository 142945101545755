import React from "react";
import DetailsDialog from "./dialogs/DetailsDialog";
import EiaButton from "./EiaButton";

const ShowDetailsButton = function(props) {
    const [openDetailsDialog, setOpenDetailsDialog] = React.useState(false);

    const {title, additionalOnClose=false, details, ...restProps} = props;

    return (
        <div>
            <DetailsDialog
                open={openDetailsDialog}
                onClose={() => {
                    if(additionalOnClose) {
                        additionalOnClose()
                    }
                    setOpenDetailsDialog(false)
                }}
                details={details}
                title={title}
            />
            <EiaButton {...restProps} onClick={() => {
                setOpenDetailsDialog(true)
            }} tooltip={"Show all details of the selected table row"} >{title}</EiaButton>
        </div>
    );
};
export default ShowDetailsButton;