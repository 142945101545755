import * as React from 'react';
import {DataGrid} from '@mui/x-data-grid';
import {Flex, Text} from "@aws-amplify/ui-react";
import Box from '@mui/material/Box';
import {useCookies} from "react-cookie";
import LoadingDialog from "../dialogs/LoadingDialog";
import Utils from "../../commons/Utils/Utils";

const noRowsOverlay = function() {
        return(
            <Flex justifyContent="center">
                <Text>No data</Text>
            </Flex>
        )
}

const isSmallScreen = Utils.isSmallScreen();
const defaultRowsPerPage = isSmallScreen ? 5 : 20;

const EiaTable = function(props) {

    const neutral = "hsl(210, 5%, 87%)";

    const {selectedRowId, setSelectedRowId, isLoading, data, tableHeader, rowsPerPage, initialState} = props;

    const displayedData = isLoading ? [] : data;

    const pageSize = rowsPerPage ? rowsPerPage : defaultRowsPerPage;
    const height = 113 + 52 * pageSize;

    const [cookies, setCookies, removeCookies] = useCookies(['darkMode']);
    const darkMode = cookies.darkMode === undefined ? true : cookies.darkMode;
    const cellTextColor = darkMode ? 'white' : 'black';
    const headerTextColor = darkMode ? 'black' : 'white';

    const teal = darkMode ? "#7dd6e8" : "#047d95";
    const greenSelection = "#CAFF33";

    return(
        <Box sx={{ height: height, width: '100%' }}>
            <LoadingDialog open={isLoading} />
            <DataGrid
                initialState={initialState}
                rows={displayedData}
                columns={tableHeader}
                pageSize={pageSize}
                loading={isLoading}
                components={{
                    NoRowsOverlay: () => noRowsOverlay()
                }}
                onSelectionModelChange={(newSelectionModel) => {
                    if(setSelectedRowId) {
                        setSelectedRowId(newSelectionModel);
                    }
                }}
                selectionModel={selectedRowId}
                sx={{
                    border: 2,
                    borderColor: neutral,
                    color: cellTextColor,
                    '.MuiDataGrid-columnHeader': {
                        backgroundColor: teal,
                        'font-size': '1.1em',
                        color: headerTextColor,
                    },
                    '.MuiToolbar-root': {
                        // backgroundColor: teal,
                        color: cellTextColor,
                    },
                    '.MuiDataGrid-footerContainer': {
                        // backgroundColor: teal,
                        color: cellTextColor,
                    },
                    '.Mui-selected': {
                        backgroundColor: greenSelection,
                        ':hover': {
                            backgroundColor: "#DCF78F",
                        },
                    },
                }}
            />
        </Box>

    );
}
export default EiaTable;