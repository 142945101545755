/**
* Util functions for array values
*/

export const sortAsc = function(array, sortBy) {
    return array.sort((a,b) => compareAsc(a,b,sortBy));
}

export const sortDesc = function(array, sortBy) {
    return array.sort((a,b) => compareDesc(a,b,sortBy));
}

export const compareAsc =  function(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return 1;
    }
    if (b[orderBy] > a[orderBy]) {
        return -1;
    }
    return 0;
};

export const compareDesc =  function(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
};

export const findValueIgnoreCase = function (stringArray, value) {
    if(!stringArray) return -1;
    let match = stringArray.find(x => x.toLowerCase() === value.toLowerCase());
    return match ? match : false;
}

export const findIndexFromNumbersIgnoreCase = function (numberArray, value) {
    if(!numberArray || !value) {
        return -1;
    }
    let match = numberArray.findIndex(x => x === value);
    return match || match === 0 ? match : -1;
}

export const findIndexIgnoreCase = function (stringArray, value) {
    if(!stringArray || !value) {
        return -1;
    }
    let match = stringArray.findIndex(x => x.toLowerCase() === value.toLowerCase());
    return match || match === 0 ? match : -1;
}

export const containsIgnoreCase = function (array, element) {
    if(!array || !element) {
        return false;
    }
    return findIndexIgnoreCase(array, element) !== -1;
};

export const increaseArrayToIndex = function (array, index) {
    while(array.length <= index) {
        array.push("");
    }
    return array;
};

/**
 * removes elements of array b from array a
 * @param target
 * @param elementsToRemove
 * @returns result array
 */
export const remove = function (target, elementsToRemove) {
    if(!target) {
        return target;
    }
    return target.filter(value => !elementsToRemove.includes(value));
};

export const intersection = function (a, b) {
    return a.filter(value => b.includes(value));
};

export const union = function (a, b, c, d) {
    if (d) {
        return union(union(a, b, c), d);
    } else if (c) {
        return union(union(a, b), c);
    } else {
        return [...a, ...remove(b, a)];
    }
};

export const isEmpty = function (a) {
    return !Array.isArray(a) || !a.length;
};

export const contains = function (array, element) {
    return Array.isArray(array) && array.includes(element);
};

/**
 * If an element already exists in an array, delete the element from the array.
 * If the element does not exist, add it to the array
 * @param array array to add or remove element from
 * @param element element to add or remove
 * @returns {*[]|Array|*}
 */
export const addOrRemove = function(array, element) {
    if(!array && !element) {
        return [];
    } else if (!element) {
        return array;
    } else if (!array) {
        return [element];
    } else if (array.includes(element)){
        return array.filter(ab => ab !== element);
    } else {
        return array.concat([element]);
    }
};

/**
 * find json in an array of jsons by a specific key value pair
 */
export const findByKeyValue = function (arrayOfJsons, key, value) {
    return arrayOfJsons.find(json => json[key] == value);
};

export const findByKeyValueAndSecondKeyNotEmpty = function (arrayOfJsons, key, value, secondKey) {
    return arrayOfJsons.find(json => json[key] == value && json[secondKey].length > 0);
};

/**
 * removes all jsons from an array of json by a specific key value pair
 */
export const removeByKeyValue = function (arrayOfJsons, key, value) {
    return arrayOfJsons.filter(json => json[key] != value);
};

/**
 * removes one element from an array by index
 */
export const removeByIndex = function (array, index) {
    return array.filter((element, i) => i !== index);
};

/**
 * finds a json in an array of jsons with ids
 */
export const findById = function (arrayOfJsonsWithIds, searchId) {
    return findByKeyValue(arrayOfJsonsWithIds, "id", searchId);
};

export const findAllBySubKeyValue = function (arrayOfJsons, key, subKey, value) {
    const resultJsons = arrayOfJsons.filter(json => json[key][subKey] === value);

    if (resultJsons) {
        return resultJsons;
    } else {
        return [];
    }
};

export const findAllByKeyValue = function (arrayOfJsons, key, value) {
    const resultJsons = arrayOfJsons.filter(json => json[key] == value);

    if (resultJsons) {
        return resultJsons;
    } else {
        return [];
    }
};

export const findAllByKeyValues = function (arrayOfJsons, key, values) {
    const resultJsons = arrayOfJsons.filter(json => contains(values, json[key]));

    if (resultJsons) {
        return resultJsons;
    } else {
        return [];
    }
};


/**
 * finds an array in an array of arrays by search index and search value
 */
export const findByIndex = function (arrayOfArrays, searchIndex, searchValue) {
    const resultArray = arrayOfArrays.find(array => array[searchIndex] === searchValue);

    if (resultArray) {
        return resultArray;
    } else {
        return [];
    }
};

/**
 * filters an array of arrays by search index and search value and ignores the case of the search value
 */
export const filterByIndexIgnoreCase = function (arrayOfArrays, searchIndex, searchValue) {

    if (searchValue) {
        return arrayOfArrays.filter(array => {
            if (array[searchIndex]) {
                return array[searchIndex].toLowerCase().includes(searchValue.toLowerCase());
            } else {
                return false;
            }
        });
    } else {
        return arrayOfArrays;
    }
};

export const findByIndexAndValue = function (arrayOfArrays, searchIndex, searchValue) {
    return arrayOfArrays.find(array => {
        if (array[searchIndex] !== undefined && array[searchIndex] !== null) {
            return array[searchIndex] == searchValue;
        } else {
            return false;
        }
    });
};

export const getFirstIndexOfKeyValueMatch = function (arrayOfJsons, key, value) {
    return arrayOfJsons.findIndex(json => json[key] === value);
};

export const getSecondIndexOfKeyValueMatch = function (arrayOfJsons, key, value) {
    const firstMatchIndex = getFirstIndexOfKeyValueMatch(arrayOfJsons, key, value);
    let i;
    for (i = firstMatchIndex + 1; i < arrayOfJsons.length; i++) {
        if (arrayOfJsons[i][key] === value) {
            return i;
        }
    }
    return -1;
};

// e.g. [{name: "john"}, {name: "john"}]
export const hasKeyValueDuplicate = function (arrayOfJsons, key) {
    let result = false;
    let keyArray = [];
    arrayOfJsons.forEach(json => {
        if(keyArray.includes(json[key])) {
            console.log("duplicate key", json[key]);
            result = true;
        } else {
            keyArray.push(json[key]);
        }
    })
    console.log("keyArray", keyArray);
    return result;
}

// e.g. [{name: "john"}, {name: "john"}]
export const getKeyValueDuplicate = function (arrayOfJsons, key) {
    let result = null;
    let keyArray = [];
    arrayOfJsons.forEach(json => {
        if(keyArray.includes(json[key])) {
            result = json;
        } else {
            keyArray.push(json[key]);
        }
    })
    return result;
}
