import React from "react"
import {Flex, Heading, Text} from "@aws-amplify/ui-react";
import EiaCard from "../components/cards/EiaCard";
import Utils from "../commons/Utils/Utils";
import {findAllByKeyValue} from "../commons/Utils/ArrayUtils";
import EiaTitle from "../components/EiaTitle";
import YoutubeVideo from "../components/YoutubeVideo";
import {COLOR_GRAY} from "../commons/enums/ColorEnums";
import EiaSelect from "../components/EiaSelect";
import {CLASSES} from "../commons/enums/D4Enums";

const isSmallScreen = Utils.isSmallScreen();

const creators = ["Any","Hasgar", "DolphDE"];
const languages = ["German", "English"]

const builds = [
    {
        classIndex: 5,
        title: "Andariels Kettenblitz",
        languageIndex: 0,
        season: 5,
        creatorIndex: 2,
        creationDate: "2024/0/01",
        youtube: "https://www.youtube.com/embed/gx4NXExwAmw?si=yb15FtexzXJ7dFvT"
    },
    {
        classIndex: 4,
        title: "Dual Whield Whirlwind Dust Devil",
        languageIndex: 0,
        season: 5,
        creatorIndex: 1,
        creationDate: "2024/08/22",
        youtube: "https://www.youtube.com/embed/o7eBqDQ-JH4?si=pmiwdUB1f3V06SXn"
    },
    {
        classIndex: 3,
        title: "Blood Surge",
        languageIndex: 0,
        season: 5,
        creatorIndex: 2,
        creationDate: "2024/08/17",
        youtube: "https://www.youtube.com/embed/VufsRKrfIoQ?si=5J7-BMHoQvxEcXxN"
    }
]

const BuildsPage = function (props) {

    const [creatorIndex,setCreatorIndex] = React.useState(0)
    const [classIndex,setClassIndex] = React.useState(0)

    let filteredBuilds = builds;
    if(creatorIndex > 0) {
        filteredBuilds = findAllByKeyValue(filteredBuilds, "creatorIndex", creatorIndex);
    }

    if(classIndex > 0) {
        filteredBuilds = findAllByKeyValue(filteredBuilds, "classIndex", classIndex);
    }


    return <EiaCard>

        <Flex direction={"column"}>
            <EiaTitle title={"Builds"} lastUpdated={"2024/08/22"}/>
            <Flex direction={isSmallScreen ? "column" : "row"}>
                <EiaSelect title={"Class"} value={classIndex} values={CLASSES} onChange={(e) => setClassIndex(e.target.value)}/>
                <EiaSelect title={"Language"} values={["German"]}/>
                <EiaSelect title={"Season"} values={[5]}/>
                <EiaSelect title={"Creator"} value={creatorIndex} values={creators} onChange={(e) => setCreatorIndex(e.target.value)}/>
            </Flex>
            <Flex direction={"column"}>
                {filteredBuilds.map((build, index) => {
                    return <EiaCard key={index}>
                        <Heading level={5}>{CLASSES[build.classIndex] + ": " + build.title}</Heading>
                        <Text fontSize={"0.75em"} style={{color: COLOR_GRAY}}>
                            {languages[build.languageIndex] + " | Season " + build.season + " | " + creators[build.creatorIndex] + " | " + build.creationDate}
                        </Text>
                        <YoutubeVideo src={build.youtube}/>
                    </EiaCard>
                })
                }
            </Flex>
        </Flex>
    </EiaCard>;
}
export default BuildsPage;