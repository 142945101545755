import EiaCard from "../cards/EiaCard";
import {Badge, Flex, Heading, ScrollView, Text} from "@aws-amplify/ui-react";
import EiaTextField from "../EiaTextField";
import {testInteger} from "../../commons/Utils/RegExUtils";
import React from "react";
import {findByIndexAndValue} from "../../commons/Utils/ArrayUtils";
import Utils from "../../commons/Utils/Utils";
import EiaTitle from "../EiaTitle";

const isMobile = Utils.isMobile();

const maxMinutesToClear = 100;

const HoursToReachLevelCalculator = function(props) {
    //the total xp data must be in column index 1 of levelXpData as default if not set as props
    //the reward xp data must be in the column index 1 of dungeonXpRewardData as default if not set as props
    const {title, lastUpdated, initialCurrentLevel, initialTargetLevel, initialDungeonTier, initialDungeonClearMinutes, currentLevelText,
        targetLevelText, dungeonTierText, dungeonClearMinutesText, levelXpData, dungeonXpRewardData, tables, levelMax,
        dungeonTierMax, totalRewardsInLevelIndex=1, rewardToLevelIndex=1, rewardName="xp", doRoundRewardToK=true } = props;

    const [currentLevel, setCurrentLevel] = React.useState(initialCurrentLevel);
    const [targetLevel, setTargetLevel] = React.useState(initialTargetLevel);
    const [dungeonTier, setDungeonTier] = React.useState(initialDungeonTier);
    const [dungeonClearMinutes, setDungeonClearMinutes] = React.useState(initialDungeonClearMinutes);

    let resultHours = null;
    let resultTextCurrentLevel = "";
    let resultTextTargetLevel = "";
    let resultFarmingSpeed = "";
    let totalCurrentXp;
    let totalXpNeeded;
    let xpPerHour;

    if(dungeonTier && targetLevel && dungeonClearMinutes) {
        const currentLevelRow = findByIndexAndValue(levelXpData, 0, currentLevel);
        if(currentLevelRow) {
            totalCurrentXp = currentLevelRow[totalRewardsInLevelIndex];
        } else {
            resultTextCurrentLevel = "Cannot find data for '"+currentLevelText+"'";
            resultHours = null;
        }

        const targetLevelRow = findByIndexAndValue(levelXpData, 0, targetLevel);
        if(targetLevelRow) {
            totalXpNeeded = targetLevelRow[totalRewardsInLevelIndex] - totalCurrentXp;
        } else {
            resultTextCurrentLevel = "Cannot find data for '"+targetLevelText+"'";
            resultHours = null;
        }

        const dungeonTierRow = findByIndexAndValue(dungeonXpRewardData, 0, dungeonTier);
        let xpPerDungeon;
        if(dungeonTierRow) {
            xpPerDungeon = dungeonTierRow[rewardToLevelIndex];
        } else {
            resultTextCurrentLevel = "Cannot find data for '"+dungeonTierText+"'";
            resultHours = null;
        }

        if(currentLevelRow && targetLevelRow && dungeonTierRow) {
            xpPerHour = 60 / dungeonClearMinutes * xpPerDungeon;
            resultHours = Math.round(100 * totalXpNeeded / xpPerHour ) / 100;
        }
    }

    if(resultHours != null) {
        if(doRoundRewardToK) {
            resultTextCurrentLevel = "At '"+currentLevelText+"' you have "+ Math.round(totalCurrentXp / 1000) + " k " + rewardName
            resultTextTargetLevel = "At '"+targetLevelText+"' you need "+ Math.round(totalXpNeeded / 1000) + " k " + rewardName
            resultFarmingSpeed = "Your farming speed is " + Math.round(xpPerHour / 1000) + " k " +rewardName+" / hour"
        } else {
            resultTextCurrentLevel = "At '"+currentLevelText+"' you have "+ Math.round(totalCurrentXp)  + " " + rewardName
            resultTextTargetLevel = "At '"+targetLevelText+"' you need "+ Math.round(totalXpNeeded) + " " + rewardName
            resultFarmingSpeed = "Your farming speed is " + Math.round(xpPerHour) + " " +rewardName+" / hour"
        }
    }

    return(
        <EiaCard>
            <Flex direction={"column"}>
                <EiaTitle title={title} lastUpdated={lastUpdated} />
                <EiaCard>
                    <Flex direction={"column"}>
                        <Flex direction={"row"}>
                            <EiaTextField hasError={currentLevel === undefined || currentLevel === null || currentLevel < 0} validator={(value) => testInteger(value, 0, levelMax - 1)} onChange={(e) => setCurrentLevel(e.currentTarget.value)} label={currentLevelText} value={currentLevel}/>
                            <EiaTextField hasError={targetLevel === undefined || targetLevel === null || targetLevel < 0} validator={(value) => testInteger(value, 0, levelMax)} onChange={(e) => setTargetLevel(e.currentTarget.value)} label={targetLevelText} value={targetLevel}/>
                        </Flex>
                        <Flex direction={"row"}>
                            <EiaTextField hasError={!dungeonTier || dungeonTier == 0} validator={(value) => testInteger(value, 0, dungeonTierMax)} onChange={(e) => setDungeonTier(e.currentTarget.value)} label={dungeonTierText} value={dungeonTier}/>
                            <EiaTextField hasError={!dungeonClearMinutes || dungeonClearMinutes == 0} validator={(value) => testInteger(value, 0, maxMinutesToClear)} onChange={(e) => setDungeonClearMinutes(e.currentTarget.value)} label={dungeonClearMinutesText} value={dungeonClearMinutes}/>
                        </Flex>
                        <Flex direction={"column"}>
                            <Text>{resultTextCurrentLevel}</Text>
                            <Text>{resultTextTargetLevel}</Text>
                            <Text>{resultFarmingSpeed}</Text>
                            {
                                resultHours != null ?
                                <Flex>
                                    <Text>{"To reach the target"
                                        + " level you need to farm"}</Text>
                                    <Badge variation={"success"}><Heading level={6}>{resultHours + " hours"}</Heading></Badge>
                                </Flex>
                                     : null
                            }
                        </Flex>
                    </Flex>
                </EiaCard>
                {
                    isMobile ? <ScrollView width="100%" height="300px" maxWidth="580px">
                        {tables}
                    </ScrollView> : tables
                }
            </Flex>
        </EiaCard>
    );
}

export default HoursToReachLevelCalculator;