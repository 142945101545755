import React from "react";
import EiaCard from "./EiaCard";
import {Badge, Divider, Flex, Heading, Text} from "@aws-amplify/ui-react";

const UserCard = function (props) {
    const {user, title} = props;

    return <EiaCard>
        <EiaCard>
            <Heading level={4}>{title}</Heading>
            <Divider/>
        </EiaCard>
        <Flex direction={"column"}>
            <Flex>
                <Badge>{"Nickname"}</Badge>
                <Text>{user.name}</Text>
                {user.countryCode ? <img src={require("../../resources/flags/" + user.countryCode + ".svg")}
                                         style={{width: 30}}/> : null}
            </Flex>
            <Flex>
                <Badge>{"Battle Tag"}</Badge>
                <Text>{user.battleTag}</Text>
            </Flex>
            <Flex>
                <Badge>{"Discord Name"}</Badge>
                <Text>{user.discordName}</Text>
            </Flex>
        </Flex>
    </EiaCard>
}

export default UserCard;