import {Divider, Flex, Heading} from "@aws-amplify/ui-react";
import React from "react";
import {Dialog} from "@mui/material";
import EiaButton from "../EiaButton";
import EiaCard from "../cards/EiaCard";
import EiaThemeProvider from "../EiaThemeProvider";
import {COLOR_TEAL} from "../../commons/enums/ColorEnums";

const dialogStyles = {
    border: '2px solid ' + COLOR_TEAL,
};

const EiaDialog = function(props) {
    const { onClose, open, title, isLoading, maxWidth, fullWidth} = props;

    return (
        <Dialog open={open} maxWidth={maxWidth} fullWidth={fullWidth} >
            <EiaThemeProvider>
                <div style={dialogStyles}>
                    <EiaCard>
                        {
                            title ? <div>
                                <EiaCard>
                                    <Heading level={3}>{title}</Heading>
                                </EiaCard>
                                <Divider/>
                            </div> : null
                        }

                        <EiaCard>
                            <Flex direction={"column"}>
                                {props.children}
                            </Flex>
                        </EiaCard>
                        <Divider></Divider>
                        <EiaCard>
                            <Flex direction={"row"} justifyContent={"right"}>
                                <EiaButton onClick={onClose} disabled={isLoading}>Close</EiaButton>
                            </Flex>
                        </EiaCard>
                    </EiaCard>
                </div>
            </EiaThemeProvider>
        </Dialog>
    );
};
export default EiaDialog;