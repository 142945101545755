import React from "react";
import {Flex, Heading} from "@aws-amplify/ui-react";
import "./D4ItemCard.css";
import EiaCard from "../cards/EiaCard";
import D4ItemCard from "./D4ItemCard";
import {COLOR_ERROR, COLOR_SUCCESS} from "../../commons/enums/ColorEnums";
import StringUtils from "../../commons/Utils/StringUtils";

const bigCardBackground = "hsl(0, 0%, 20%)"
const littleCardBackground = "#ffffff0d"
const priceColor = "#fef08a"
const headingLevel = 6;
const bigBorderColor = ""
const littleBorderColor = "#598BBF"

const hasBidders =function(bidders) {
    if(bidders === undefined || bidders === null || bidders.length === 0) {
        return false;
    }
    return true;
}

const getBiddersString =function(bidders) {
    if(!hasBidders(bidders)) {
        return null;
    }
    let result = bidders.join(",");

    if(result.length > 20) {
        result = bidders.length
    }

    return result;
}

function getStatusBorderColor(auctionMergedWithBid, isViewFromSeller, isViewFromBidder) {

    if(isViewFromSeller && auctionMergedWithBid.status) {
        if(auctionMergedWithBid.status === "TRADE" || (auctionMergedWithBid.status === "ACTIVE" && hasBidders(auctionMergedWithBid.bidders))) {
            return COLOR_SUCCESS;
        }

        if(auctionMergedWithBid.status === "COMPLETED") {
            return COLOR_SUCCESS;
        }

        if(auctionMergedWithBid.status === "ABORTED") {
            return COLOR_ERROR;
        }
    }

    if(isViewFromBidder && auctionMergedWithBid.myBidStatus) {
        if(auctionMergedWithBid.status === "ACCEPTED") {
            return COLOR_SUCCESS;
        }
    }

    return bigBorderColor;
}

const D4ItemCardWithPrice = function (props) {
    //we call it auctionMergedWithBid because we have the auction with e.g. item but the amount, price, currency
    // could come from the original auction or from a bid
    const {auctionMergedWithBid, showDetails = false, showBuyDetails = false, isViewFromSeller=false, isViewFromBidder=false} = props;

    const isItem = auctionMergedWithBid && auctionMergedWithBid.item;
    const isAmount = (auctionMergedWithBid.originalAmount && auctionMergedWithBid.originalAmount > 1) ||
        (auctionMergedWithBid.amount && auctionMergedWithBid.amount > 1);

    const showBidDetails = auctionMergedWithBid.myBidStatus ? true : false;
    const showBidOrBuyDetails = showBidDetails || showBuyDetails;

    const currency = showBidOrBuyDetails ? auctionMergedWithBid.originalCurrency : auctionMergedWithBid.currency;
    const price = showBidOrBuyDetails ? StringUtils.getPriceString(auctionMergedWithBid.originalPrice) : StringUtils.getPriceString(auctionMergedWithBid.price)
    const pricePerItem = showBidOrBuyDetails ? StringUtils.getPriceString(auctionMergedWithBid.originalPricePerItem) : StringUtils.getPriceString(auctionMergedWithBid.pricePerItem)
    const amount = isAmount ? (auctionMergedWithBid.originalAmount ? auctionMergedWithBid.originalAmount : auctionMergedWithBid.amount) : 1;
    const bidders = getBiddersString(auctionMergedWithBid.bidders);

    const bidCurrency = auctionMergedWithBid.currency;
    const bidPrice = StringUtils.getPriceString(auctionMergedWithBid.price);
    const bidPriceEach = StringUtils.getPriceString(auctionMergedWithBid.pricePerItem);
    const bidAmount = auctionMergedWithBid.amount;

    const statusBorderColor = getStatusBorderColor(auctionMergedWithBid, isViewFromSeller, isViewFromBidder)

    const creationDate = auctionMergedWithBid.creationDate;

    if (isItem) {
        return <EiaCard roundCorners={true} styleBgColor={bigCardBackground} borderColor={statusBorderColor} height={"100%"}>
                <Flex direction={"column"} alignItems={"stretch"} justifyContent={"space-between"} style={{height: '100%'}}>
                    <D4ItemCard item={auctionMergedWithBid.item} amount={amount} maxHeight={true}></D4ItemCard>
                    <EiaCard roundCorners={true} styleBgColor={littleCardBackground} borderColor={littleBorderColor}>
                        {
                            showDetails && showBidOrBuyDetails ? <Heading level={headingLevel}>{"Listing details"}</Heading> : null
                        }
                        <Flex direction={"row"}>
                            <Heading level={headingLevel}>{"Price: "}</Heading>
                            <Heading level={headingLevel} color={priceColor}>{price + "  " + currency}</Heading>
                        </Flex>
                        {isAmount ?
                            <Flex direction={"row"}>
                                <Heading level={headingLevel}>{"Price each: "}</Heading>
                                <Heading level={headingLevel} color={priceColor}>{pricePerItem + " " + currency}</Heading>
                            </Flex> : null
                        }
                        {
                            showDetails && !showBuyDetails ?
                                <Flex direction={"row"}>
                                    <Heading level={headingLevel}>{"Status: "}</Heading>
                                    <Heading level={headingLevel} color={priceColor}>{auctionMergedWithBid.status}</Heading>
                                </Flex> : null
                        }
                        {
                            showDetails && !showBuyDetails && auctionMergedWithBid.bidders.length > 0 ?
                                <Flex direction={"row"}>
                                    <Heading level={headingLevel}>{"Bidders: "}</Heading>
                                    <Heading level={headingLevel} color={priceColor}>{bidders}</Heading>
                                </Flex> : null
                        }
                        {
                            showDetails && !showBuyDetails && auctionMergedWithBid.buyer ?
                                <Flex direction={"row"}>
                                    <Heading level={headingLevel}>{"Buyer: "}</Heading>
                                    <Heading level={headingLevel} color={priceColor}>{auctionMergedWithBid.buyer}</Heading>
                                </Flex> : null
                        }
                    </EiaCard>
                    {
                        showDetails && showBidOrBuyDetails ?
                            <EiaCard roundCorners={true} styleBgColor={littleCardBackground} borderColor={littleBorderColor}>
                                <Heading level={headingLevel}>{showBuyDetails ? "Buy details" : "Bid details"}</Heading>
                                {auctionMergedWithBid.myBidStatus ?
                                    <Flex direction={"row"}>
                                        <Heading level={headingLevel}>{"Status: "}</Heading>
                                        <Heading level={headingLevel} color={priceColor}>{auctionMergedWithBid.myBidStatus}</Heading>
                                    </Flex> : null
                                }
                                <Flex direction={"row"}>
                                    <Heading level={headingLevel}>{"Price: "}</Heading>
                                    <Heading level={headingLevel} color={priceColor}>{bidPrice + "  " + bidCurrency}</Heading>
                                </Flex>
                                {isAmount ?
                                    [
                                        <Flex direction={"row"}>
                                            <Heading level={headingLevel}>{"Price each: "}</Heading>
                                            <Heading level={headingLevel} color={priceColor}>{bidPriceEach + " " + bidCurrency}</Heading>
                                        </Flex>,
                                        <Flex direction={"row"}>
                                            <Heading level={headingLevel}>{"Amount: "}</Heading>
                                            <Heading level={headingLevel} color={priceColor}>{bidAmount}</Heading>
                                        </Flex>
                                    ]
                                    : null
                                }
                            </EiaCard>
                            : null
                    }
                </Flex>
            </EiaCard>
    } else {
        return null;
    }
};
export default D4ItemCardWithPrice;