import {Card, useAuthenticator} from "@aws-amplify/ui-react";
import React from "react";
import {PLATFORMS, REGIONS} from "../commons/enums/D4Enums";
import MyBidsPage from "./MyBidsPage";
import MyHistoryPage from "./MyHistoryPage";
import MySalesPage from "./MySalesPage";
import NewsPage from "./NewsPage";
import NotificationsPage from "./NotificationsPage";
import SearchPage from "./SearchPage";
import SellPage from "./SellPage";
import WelcomePage from "./WelcomePage";
import ToolsPage from "./ToolsPage";
import MainPageFooter from "./MainPageFooter";
import welcomeImage from '../resources/welcomeImage.jpg'
import EiaCard from "../components/cards/EiaCard";
import MainPageBody from "./MainPageBody";
import MainPageHeader from "./MainPageHeader";
import Utils from "../commons/Utils/Utils";
import BestPrices from "./BestPricesPage";
import {d4GetMyAuctions, d4GetMyBids, d4GetNotifications} from "../commons/api/D4Api";
import {auctionToTableElement, myAuctionsAndBidsToMyBids} from "../commons/Converter";
import {useCookies} from "react-cookie";
import LocalStorage from "../commons/LocalStorage";
import {BrowserRouter, Outlet, Route, Routes} from "react-router-dom";
import UserPrivacyPolicy from "./footer/UserPrivacyNotice";
import FaqPage from "./footer/FaqPage";
import * as Navigation from "../commons/enums/Navigation";
import BuildsPage from "./BuildsPage";

const platformIndex = 0;
const regionIndex = 0;

const platform = PLATFORMS[platformIndex];
const region = REGIONS[regionIndex];

const withBackgroundImage = {
    backgroundImage: `url(${welcomeImage})`
}

const withTransparentBackground = {
    opacity: "1.0"
}

const TransparentCard = function (props) {
    return (<EiaCard style={withTransparentBackground}  {...props}>
        {props.children}
    </EiaCard>)
}

const isSmallScreen = Utils.isSmallScreen();
const padding = isSmallScreen ? '0px' : '16px';

const MainPage = function (props) {
    const {signOut, setOpenLoginRegisterDialog} = props;

    const {user} = useAuthenticator((context) => [context.user]);
    const loggedIn = user && user.username;

    const [cookies, setCookies, removeCookies] = useCookies(['mode', 'realm']);
    const mode = cookies.mode;
    const realm = cookies.realm;

    const showAds = true;

    const reloadNotifications = function () {
        if(!loggedIn) {
            return;
        }

        let tempNotificationsState = Object.assign([], notificationsState);
        tempNotificationsState.alert = null;
        tempNotificationsState.isLoading = true;
        setNotificationsState(tempNotificationsState);

        const request = {
            mandatory: "empty json is not allowed"
        }

        d4GetNotifications(request, function (response) {
            let tempNotificationsState = Object.assign({}, notificationsState);
            tempNotificationsState.isLoading = false;

            if (response.notifications) {
                tempNotificationsState.notifications =response.notifications.map(notification => {
                    let result = notification
                    let d = new Date(0);
                    d.setUTCSeconds(result.date);
                    result.date = d
                    return result;
                });
            } else {
                tempNotificationsState.notifications = [];
            }

            tempNotificationsState.alert = null;

            const areThereNewNotifications = LocalStorage.updateNotificationCounter(tempNotificationsState.notifications.length);

            if(areThereNewNotifications) {
                //if there are new notifications then we want to reload bids/sales to maybe create new links
                bidsState.reload(Utils.doNothing, Utils.doNothing, mode, realm);
                mySalesState.reload(Utils.doNothing, Utils.doNothing, mode, realm);
            }

            setNotificationsState(tempNotificationsState);
        }, function (error) {
            let tempNotificationsState = Object.assign({}, notificationsState);
            tempNotificationsState.alert = ["error", error.message];
            tempNotificationsState.isLoading = false;
            setNotificationsState(tempNotificationsState);
        })
    }

    const reloadBids = function(setIsLoading, setAlert, mode, realm) {
        setAlert(null);
        setIsLoading(true);

        const request = {
            platform: platform,
            region: region,
            mode: mode,
            realm: realm
        }

        d4GetMyBids(request, function (response) {
            let tempBidsState = Object.assign({}, bidsState);

            if(response.auctions) {
                tempBidsState.bids = myAuctionsAndBidsToMyBids(response.auctions, response.bids, ["ACTIVE", "ACCEPTED"]);
            } else {
                tempBidsState.bids = [];
            }

            setBidsState(tempBidsState);
            setIsLoading(false);
        }, function (error) {
            setAlert(["error", error.message])
            setIsLoading(false);
        })
    }

    const reloadMySales = function (setIsLoading, setAlert, mode, realm) {
        setAlert(null);
        setIsLoading(true);

        const request = {
            platform: platform,
            region: region,
            mode: mode,
            realm: realm,
            statusList: ["ACTIVE", "TRADE", "EXPIRED"]
        }

        d4GetMyAuctions(request, function (response) {
            let tempMySalesState = Object.assign([], mySalesState);
            if (response.auctions) {
                tempMySalesState.auctions = response.auctions.map(auction => {
                    return auctionToTableElement(auction);
                });
            } else {
                tempMySalesState.auctions = [];
            }
            setMySalesState(tempMySalesState);
            setIsLoading(false);
        }, function (error) {
            setAlert(["error", error.message]);
            setIsLoading(false);
        })
    }

    const [notificationsState, setNotificationsState] = React.useState({notifications: [], isLoading: false, alert: null})
    const [bidsState, setBidsState] = React.useState({bids: [], reload: reloadBids})
    const [mySalesState, setMySalesState] = React.useState({auctions: [], reload: reloadMySales})

    let pageItems = [
        {
            path: Navigation.WELCOME,
            title: "Welcome",
            page: <WelcomePage/>
        },
        {
            path: Navigation.NEWS,
            title: "News",
            page: <NewsPage/>
        },
        {
            path: Navigation.SEARCH,
            title: "Search",
            page: <SearchPage
                user={user}
                platform={platform}
                region={region}
            />
        },
        {
            path: Navigation.BEST_PRICES,
            title: "Best Prices",
            page: <BestPrices
                user={user}
                platform={platform}
                region={region}
            />
        },
        {
            path: Navigation.SELL,
            title: "Sell",
            page: <SellPage
                user={user}
                platform={platform}
                region={region}
            />
        }];

    if(loggedIn) {
        [
            {
                path: Navigation.MY_SALES,
                title: "My Sales",
                page: <MySalesPage
                    platform={platform}
                    region={region}
                    mySalesState={mySalesState}
                />
            },
            {
                path: Navigation.MY_BIDS,
                title: "My Bids",
                page: <MyBidsPage
                    platform={platform}
                    region={region}
                    bidsState={bidsState}
                />
            },
            {
                path: Navigation.MY_HISTORY,
                title: "My History",
                page: <MyHistoryPage
                    platform={platform}
                    region={region}
                />
            },
            {
                path: Navigation.NOTIFICATIONS,
                title: "Notifications",
                page: <NotificationsPage notificationsState={notificationsState} reloadNotifications={reloadNotifications}/>
            },
        ].forEach(pageItem => pageItems.push(pageItem));
    }

    pageItems.push(
        {
            path: Navigation.BUILDS,
            title: "Builds",
            page: <BuildsPage/>
        }
    );

    pageItems.push(
        {
            path: Navigation.TOOLS,
            title: "Tools",
            page: <ToolsPage/>
        }
    );

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={
                    <Card style={withBackgroundImage} padding={padding}>
                        <MainPageHeader signOut={signOut} setOpenLoginRegisterDialog={setOpenLoginRegisterDialog}
                                        bidsState={bidsState} mySalesState={mySalesState}
                                        notificationsState={notificationsState} reloadNotifications={reloadNotifications}/>
                        <Outlet/>
                        <TransparentCard>
                            <MainPageFooter/>
                        </TransparentCard>
                    </Card>
                }>
                    <Route index element={<MainPageBody showAds={showAds} pageItems={pageItems} path={Navigation.WELCOME} />} />
                    <Route path={Navigation.WELCOME} element={<MainPageBody showAds={showAds} pageItems={pageItems} path={Navigation.WELCOME} />} />
                    <Route path={Navigation.NEWS} element={<MainPageBody showAds={showAds} pageItems={pageItems} path={Navigation.NEWS} />} />
                    <Route path={Navigation.SEARCH} element={<MainPageBody showAds={showAds} pageItems={pageItems} path={Navigation.SEARCH} />} />
                    <Route path={Navigation.BEST_PRICES} element={<MainPageBody showAds={showAds} pageItems={pageItems} path={Navigation.BEST_PRICES} />} />
                    <Route path={Navigation.SELL} element={<MainPageBody showAds={showAds} pageItems={pageItems}path={Navigation.SELL} />} />
                    <Route path={Navigation.MY_SALES} element={<MainPageBody showAds={showAds} pageItems={pageItems} path={Navigation.MY_SALES} />} />
                    <Route path={Navigation.MY_BIDS} element={<MainPageBody showAds={showAds} pageItems={pageItems} path={Navigation.MY_BIDS} />} />
                    <Route path={Navigation.MY_HISTORY + "/*"} element={<MainPageBody showAds={showAds} pageItems={pageItems} path={Navigation.MY_HISTORY} />} />
                    <Route path={Navigation.NOTIFICATIONS} element={<MainPageBody showAds={showAds} pageItems={pageItems} path={Navigation.NOTIFICATIONS} />} />
                    <Route path={Navigation.BUILDS + "/*"} element={<MainPageBody showAds={showAds} pageItems={pageItems} path={Navigation.BUILDS} />} />
                    <Route path={Navigation.TOOLS + "/*"} element={<MainPageBody showAds={showAds} pageItems={pageItems} path={Navigation.TOOLS} />} />

                    <Route path={Navigation.FAQ} element={<MainPageBody showAds={showAds} pageItems={pageItems} content={<FaqPage/>} />} />
                    <Route path={Navigation.USER_PRIVACY_POLICY} element={<MainPageBody showAds={showAds} pageItems={pageItems} content={<UserPrivacyPolicy/>} />} />
                </Route>
            </Routes>
        </BrowserRouter>

    );
};
export default MainPage;