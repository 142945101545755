export const sortByDate = function(a,b) {
    return b.date && a.date ? b.date - a.date : 0;
}

export const sortByCreationDate = function(a,b) {
    return b.creationDate && a.creationDate ? b.creationDate - a.creationDate : 0;
}

export const sortByPricePerItem = function(a,b) {
    const aPrice = a.pricePerItem;
    const bPrice = b.pricePerItem;

    if(aPrice === bPrice) {
        return sortByCreationDate(a, b);
    } else {
        return aPrice - bPrice;
    }
}

export const sortByBidders = function(a,b) {
    const aBidders = a.bidders && a.bidders.length ? a.bidders.length : 0;
    const bBidders = b.bidders && b.bidders.length ? b.bidders.length : 0;

    if(aBidders === bBidders) {
        return sortByCreationDate(a, b);
    } else {
        return bBidders - aBidders;
    }
}

const auctionsSortByStatusOrder = ["TRADE", "EXPIRED", "ACTIVE", "COMPLETED", "ABORTED"]
export const auctionSortByStatusAndBidders = function(a,b) {
    if(!a || !a.status) {
        return 1;
    }

    if(!b || !b.status) {
        return -1;
    }

    const indexA = auctionsSortByStatusOrder.indexOf(a.status);
    const indexB = auctionsSortByStatusOrder.indexOf(b.status);

    if (indexA === -1) return 1; // Wenn a nicht in der Reihenfolge ist, kommt es nach b
    if (indexB === -1) return -1; // Wenn b nicht in der Reihenfolge ist, kommt es vor a

    if(indexA === indexB) {
        return sortByBidders(a, b);
    } else {
        return indexA - indexB;
    }
}

const getGreaterAffixSize=function(auction) {
    let result = 0;
    if(auction.item && auction.item.stats) {
        auction.item.stats.forEach(stat => {
            if(stat.greaterAffix) {
                result++;
            }
        })
    }
    return result;
}

export const auctionSortByGreaterAffix = function(a,b) {
    const aGreaterAffixes = getGreaterAffixSize(a);
    const bGreaterAffixes = getGreaterAffixSize(b);

    if(aGreaterAffixes === bGreaterAffixes) {
        return sortByCreationDate(a, b);
    } else {
        return bGreaterAffixes - aGreaterAffixes;
    }
}