import {defaultDarkModeOverride, ThemeProvider} from "@aws-amplify/ui-react";
import React from "react";
import {useCookies} from "react-cookie";

const EiaThemeProvider = function (props) {

    const theme = {
        name: 'my-theme',
        overrides: [defaultDarkModeOverride],
    };

    const [cookies, setCookies, removeCookies] = useCookies(['darkMode']);
    const darkMode = cookies.darkMode === undefined ? true : cookies.darkMode;
    const colorMode = darkMode ? "dark" : "light";

    return(
        <ThemeProvider theme={theme} colorMode={colorMode}>
            {props.children}
        </ThemeProvider>
    )
}
export default EiaThemeProvider;