import React from "react";
import EiaButton from "../EiaButton";
import {TextAreaField} from "@aws-amplify/ui-react";
import EiaDrawer from "./EiaDrawer";

const text = "Marius Synowiec\nAm Hofbauerfeld 4\nRohr i. NB , 93352\nGermany\neasyitemtrading@gmx.de"

function LegalNotice() {

    const [openDrawer, setOpenDrawer] = React.useState(false);

    return (
        <div>
            <EiaButton isFullWidth={true} onClick={() => setOpenDrawer(true)}>Legal Notice</EiaButton>
            <EiaDrawer open={openDrawer} setOpen={setOpenDrawer} title={"Legal Notice"}>
                <TextAreaField rows={5} defaultValue={text} readOnly>
                </TextAreaField>
            </EiaDrawer>
        </div>
    );
}

export default LegalNotice;