import React from "react";
import {d4UpdateBid} from "../commons/api/D4Api";
import EiaButton from "../components/EiaButton";
import {findByKeyValue} from "../commons/Utils/ArrayUtils";
import TradeDialog from "../components/dialogs/TradeDialog";
import {BASE_BIDS_HEADERS} from "../commons/TableHeaders";
import ShowDetailsButton from "../components/ShowDetailsButton";
import {useCookies} from "react-cookie";
import EiaAuctionsWithButtons from "../components/EiaAuctionsWithButtons";

const tableInitialState = {
    sorting: {
        sortModel: [{ field: 'creationDate', sort: 'desc' }],
    },
}

const MyBidsPage = function (props) {
    const {platform, region, bidsState} = props;

    const [cookies, setCookies, removeCookies] = useCookies(['mode', 'realm']);
    const mode = cookies.mode;
    const realm = cookies.realm;

    const [isLoading, setIsLoading]  = React.useState(false);
    const [alert, setAlert] = React.useState(null)

    const tableData = bidsState.bids;

    const [selectedAuctionId, setSelectedAuctionId] = React.useState([])
    const [openTradeDialog, setOpenTradeDialog] = React.useState(false);
    const [openAuctionSpecificDialog, setOpenAuctionSpecificDialog] = React.useState(false);

    const selectedAuction = selectedAuctionId.length === 0 ? null : findByKeyValue(tableData, "id", selectedAuctionId);

    const reloadMyBids = function() {
        bidsState.reload(setIsLoading, setAlert, mode, realm);
    }

    React.useEffect(() => {
        reloadMyBids();
    }, []);

    const withdraw = function() {
        setIsLoading(true)
        const request = {
            platform: platform,
            region: region,
            mode: mode,
            realm: realm,

            auctionId: selectedAuction.id,
            status: "WITHDRAWN"
        }

        d4UpdateBid(request, function (response) {
            setAlert(["success", ("Successfully withdrawn bid")]);
            setIsLoading(false);
            reloadMyBids();
        }, function (error) {
            setAlert(["error", error.message]);
            setIsLoading(false);
            reloadMyBids();
        })
    }

    const loadingOrNoSelectedAuction = isLoading || selectedAuctionId.length === 0 || selectedAuction === undefined || selectedAuction === null;

    const generalButtons = [
        <EiaButton key={"reloadButton"} isFullWidth onClick={reloadMyBids} disabled={isLoading} tooltip={"Reload all your bids"} >Reload</EiaButton>
    ]

    const auctionSpecificButtons = [
        <ShowDetailsButton key={"detailsButton"} isFullWidth disabled={loadingOrNoSelectedAuction} title={"Details"} details={selectedAuction}
                           additionalOnClose={() => setOpenAuctionSpecificDialog(false)} />,
        <EiaButton key={"withdrawButton"} isFullWidth colorTheme={"error"} onClick={() => {
            setOpenAuctionSpecificDialog(false);
            withdraw();

        }} disabled={loadingOrNoSelectedAuction} tooltip={"Withdraw your bid"}>Withdraw</EiaButton>,
        <EiaButton key={"tradeButton"} isFullWidth colorTheme={"success"} onClick={() => {
            setOpenAuctionSpecificDialog(false);
            setOpenTradeDialog(true);
        }} disabled={loadingOrNoSelectedAuction || selectedAuction.myBidStatus !== "ACCEPTED"} tooltip={"Trade your bid with bid status ACCEPTED"} >Trade ...</EiaButton>
    ]

    return (
        <div>
            <TradeDialog open={openTradeDialog} onCancel={() => setOpenTradeDialog(false)}
                         bidsState={bidsState}
                         auction={selectedAuction ? selectedAuction : null}
            />
            <EiaAuctionsWithButtons
                alert={alert}
                isLoading={isLoading}
                tableData={tableData}
                tableHeaders={BASE_BIDS_HEADERS}
                tableInitialState={tableInitialState}
                generalButtons={generalButtons}
                auctionSpecificButtons={auctionSpecificButtons}
                selectedAuctionId={selectedAuctionId}
                selectedAuction={selectedAuction}
                setSelectedAuctionId={setSelectedAuctionId}
                openAuctionSpecificDialog={openAuctionSpecificDialog}
                setOpenAuctionSpecificDialog={setOpenAuctionSpecificDialog}
                isViewFromBidder={true}
            />
        </div>
    )
}
export default MyBidsPage;