import {Link, Message} from "@aws-amplify/ui-react";
import React from "react";

const EiaReminderLink = function(props) {

    const {text, onClick} = props;

    return (
        <Message colorTheme={"success"}>
            <Link
                textDecoration="underline"
                onClick={onClick}
            >
                {text}
            </Link>
        </Message>
    );
};
export default EiaReminderLink;