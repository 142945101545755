import React from "react";
import {Flex} from "@aws-amplify/ui-react";
import D4ItemTypesSelect from "../components/d4/D4ItemTypesSelect";
import CurrencySelects from "../components/CurrencySelects";
import SearchBasePage from "./SearchBasePage";
import {ITEM_SUB_TYPES} from "../commons/enums/D4Enums";

const baseStats = {itemPower: 0, classRequirement: "Any", levelRequirement: 100};

const BestPrices = function (props) {
    const {user, platform, region} = props;

    const [currency, setCurrency] = React.useState({})
    const [baseItem, setBaseItem] = React.useState({itemType: "Misc", itemSubType: ITEM_SUB_TYPES["Misc"][0], name: "Any"});

    const optionalLeftElements=
        <Flex direction={"column"}>
            <CurrencySelects currency={currency} setCurrency={setCurrency} addAnyCurrencyType={false} addPrice={false} />
            <D4ItemTypesSelect showAmount={false} baseItem={baseItem} setBaseItem={setBaseItem} removeAny={false} hideRarity={true} hideName={true} hideTitle={true}/>
        </Flex>

    return (<SearchBasePage
        user={user}
        platform={platform}
        region={region}
        optionalLeftElements={optionalLeftElements}
        currency={currency}
        baseItem={baseItem}
        initialBaseStats={baseStats}
        onlyBestPricePerItemName={true}
    />)
}
export default BestPrices;