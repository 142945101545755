import {Button, Flex, Icon, Menu, MenuItem, Tabs} from "@aws-amplify/ui-react";
import React from "react";
import EiaCard from "../components/cards/EiaCard";
import Utils from "../commons/Utils/Utils";
import {RiArrowDownCircleFill, RiArrowUpCircleFill} from "react-icons/ri";
import AppBar from '@mui/material/AppBar';
import {useNavigate} from "react-router-dom";

const NormalCard = function (props) {
    return (<EiaCard style={{opacity: "1.0"}}  {...props}>
        {props.children}
    </EiaCard>)
}

const isSmallScreen = Utils.isSmallScreen();
const initialTab = "search";

const MainPageBody = function (props) {
    const {showAds, pageItems, path, content} = props;

    const effectiveContent = content ? content : pageItems.map((item, itemIndex) => {
            return <Tabs.Panel key={itemIndex} value={item.path}>{item.page}</Tabs.Panel>
        })

    const navigateWithRouter = useNavigate();

    const navigate=function(value) {
        if(isSmallScreen) {
            Utils.scrollToStickyNavigationBar();
        }

        navigateWithRouter("/" + value);
    }

    return (
        <div>
            {
                isSmallScreen ?

                    <NormalCard>
                        <div id={"sticky-navigation-bar"}></div>
                        <AppBar position={"sticky"}>
                            <EiaCard variation={"outlined"}>
                                <Flex justifyContent={"space-between"}>
                                    <Flex>
                                        <EiaCard>
                                            <Menu
                                                size={"large"}
                                                menuAlign="start"
                                            >
                                                {
                                                    pageItems.map((item, itemIndex) => {
                                                        return (<MenuItem key={itemIndex} onClick={() => navigate(item.path)}>
                                                            {item.title}
                                                        </MenuItem>)
                                                    })
                                                }
                                            </Menu>
                                        </EiaCard>
                                    </Flex>
                                    <Flex justifyContent={"flex-end"}>
                                        <EiaCard>
                                            <Button size={"large"} variation={"default"} onClick={Utils.scrollToBotOfTheScreen}>
                                                <Icon ariaLabel="Javascript" as={RiArrowDownCircleFill}/>
                                            </Button>
                                        </EiaCard>
                                        <EiaCard>
                                            <Button size={"large"} variation={"default"} onClick={Utils.scrollToRedBellAndLogin}>
                                                <Icon ariaLabel="Javascript" as={RiArrowUpCircleFill}/>
                                            </Button>
                                        </EiaCard>
                                    </Flex>
                                </Flex>
                            </EiaCard>
                        </AppBar>
                        <Tabs.Container isLazy value={path} onValueChange={(value) => navigate(value)}>
                            {effectiveContent}
                        </Tabs.Container>
                    </NormalCard>

                    :

                    <NormalCard>
                        <Flex direction={"column"}>
                            <Tabs.Container isLazy value={path} onValueChange={(value) => navigate(value)}>
                                <Tabs.List>
                                    {
                                        pageItems.map((item, itemIndex) => {
                                            return <Tabs.Item key={itemIndex} value={item.path}>{item.title}</Tabs.Item>
                                        })
                                    }
                                </Tabs.List>
                                {effectiveContent}
                            </Tabs.Container>
                        </Flex>
                    </NormalCard>
            }
        </div>
    );
};
export default MainPageBody;