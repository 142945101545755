import {COLOR_GRAY, COLOR_TEAL} from "../commons/enums/ColorEnums";
import {Flex, Heading, Text} from "@aws-amplify/ui-react";
import EiaCard from "./cards/EiaCard";
import React from "react";

const bigCardBackground = "hsl(0, 0%, 20%)"

const EiaNotificationCard = function(props) {
    const {notification} = props;

    return <EiaCard roundCorners={true} styleBgColor={bigCardBackground} height={"100%"}>
        {
            notification.title ? <Heading level={5} style={{color: COLOR_TEAL}}>{notification.title}</Heading> : null
        }

        <Text>
            {notification.message}
        </Text>
        <Flex direction={"row"} justifyContent={"flex-end"}>
            <Text fontSize={"0.75em"} style={{color: COLOR_GRAY}}>
                {notification.date + " | " + notification.mode + " | " + notification.realm}
            </Text>
        </Flex>
    </EiaCard>
}

export default EiaNotificationCard;