import React from "react";
import EiaButton from "../EiaButton";
import {Flex, SwitchField} from "@aws-amplify/ui-react";
import {userApiGetUser, userApiUpdateUser} from "../../commons/api/BasicApi";
import AlertCard from "../cards/AlertCard";
import EiaTextField from "../EiaTextField";
import LocalStorage from "../../commons/LocalStorage";
import EiaDialog from "./EiaDialog";
import LoadingDialog from "./LoadingDialog";
import {useCookies} from "react-cookie";
import {COUNTRIES, COUNTRIES_OBJECTS} from "../../commons/enums/GeneralEnums";
import EiaSelect from "../EiaSelect";
import {getFirstIndexOfKeyValueMatch} from "../../commons/Utils/ArrayUtils";
import EiaInfoText from "../EiaInfoText";
import Utils from "../../commons/Utils/Utils";

const GAMING_SYSTEMS = ["PC", "XBOX", "PS"]
const infoText = "If you disable 'Reveal to all' only registered players you are trading with can see this data. For"
    + " faster sells it is recommended to reveal your battle tag and discord name to all. Reveal changes do not"
    + " affect already existing sells or bids."

const isSmallScreen = Utils.isSmallScreen();
const switchFieldLabelPosition = isSmallScreen ? "top" : "start";

function SettingsDialog(props) {
    const {onClose, open} = props;

    const [isLoading, setIsLoading] = React.useState(false)
    const [battleTag, setBattleTag] = React.useState(null);
    const [discordName, setDiscordName] = React.useState(null);
    const [countryIndex, setCountryIndex] = React.useState(null);
    const [gamingSystemIndex, setGamingSystemIndex] = React.useState(null);

    const [revealBattleTag, setRevealBattleTag] = React.useState(true);
    const [revealDiscordName, setRevealDiscordName] = React.useState(true);
    const [revealCountry, setRevealCountry] = React.useState(true);
    const [revealGamingSystem, setRevealGamingSystem] = React.useState(true);

    const [alert, setAlert] = React.useState(null)
    const [cookies, setCookies, removeCookies] = useCookies(['darkMode']);
    const darkMode = cookies.darkMode === undefined ? true : cookies.darkMode;

    React.useEffect(() => {
        if (open) {
            setIsLoading(true)
            const request = {
                requestedUser: LocalStorage.getUser()
            }

            userApiGetUser(request, function (response) {
                if (response) {
                    //we check if there is a user in the response because new users do not exist in the USERS table
                    if(response.user) {
                        if (response.user.gamingSystem) {
                            setGamingSystemIndex(GAMING_SYSTEMS.indexOf(response.user.gamingSystem));
                        }
                        setBattleTag(response.user.battleTag)
                        setDiscordName(response.user.discordName);
                        if (response.user.countryCode) {
                            setCountryIndex(
                                getFirstIndexOfKeyValueMatch(COUNTRIES_OBJECTS, "code", response.user.countryCode));
                        }

                        setRevealDiscordName(response.user.revealDiscordName);
                        setRevealGamingSystem(response.user.revealGamingSystem);
                        setRevealCountry(response.user.revealCountry);
                        setRevealBattleTag(response.user.revealBattleTag)
                    }
                } else {
                    setAlert(["error", "Cannot load user"]);
                }

                setIsLoading(false)
            }, function (error) {
                setAlert(["error", error.message]);
                setIsLoading(false)
            })
        }
    }, [open]);

    const updateUser = function () {
        setIsLoading(true)
        const request = {
            userObject: {
                battleTag: battleTag,
                discordName: discordName,
                countryCode: countryIndex == null || countryIndex === "" ? null : COUNTRIES_OBJECTS[countryIndex].code,
                gamingSystem: gamingSystemIndex == null || gamingSystemIndex === "" ? null : GAMING_SYSTEMS[gamingSystemIndex],
                revealBattleTag: revealBattleTag,
                revealCountry: revealCountry,
                revealDiscordName: revealDiscordName,
                revealGamingSystem: revealGamingSystem
            }
        }

        userApiUpdateUser(request, function (response) {
            setAlert(["success", ("Successfully updated user")]);
            setIsLoading(false)
        }, function (error) {
            setAlert(["error", error.message]);
            setIsLoading(false)
        })
    }

    const close = function () {
        setAlert(null);
        setIsLoading(false)
        onClose();
    }

    return (
        <EiaDialog title={"Settings"} open={open} onClose={close} isLoading={isLoading}>
            <LoadingDialog open={isLoading}/>
            <AlertCard alert={alert}/>
            <SwitchField
                isDisabled={isLoading}
                label="DarkMode"
                labelPosition="start"
                isChecked={darkMode}
                onChange={(e) => {
                    setCookies("darkMode", e.target.checked, {maxAge: 31536000});
                }}
            />
            <EiaInfoText value={infoText} />
            <Flex direction={"row"}>
                <Flex direction={"column"} justifyContent={"space-around"}>
                    <EiaTextField
                        label={"BattleTag, e.g. myUser#1234"}
                        value={battleTag}
                        onChange={(e) => setBattleTag(e.currentTarget.value)}
                    />
                    <EiaSelect placeholder={"Select"} title={"Gaming System"} values={GAMING_SYSTEMS}
                               value={gamingSystemIndex} onChange={(e) => {
                        setGamingSystemIndex(e.target.value)
                    }}/>
                    <EiaSelect placeholder={"Select"} title={"Country"} values={COUNTRIES}
                               value={countryIndex} onChange={(e) => {
                        setCountryIndex(e.target.value)
                    }}/>
                    <EiaTextField
                        label={"Discord Name"}
                        value={discordName}
                        onChange={(e) => setDiscordName(e.currentTarget.value)}
                    />
                </Flex>
                <Flex direction={"column"} justifyContent={"space-around"}>
                    <SwitchField
                        isDisabled={isLoading}
                        label="Reveal to all"
                        labelPosition={switchFieldLabelPosition}
                        isChecked={revealBattleTag}
                        onChange={(e) => {setRevealBattleTag(e.target.checked);}}
                    />
                    <SwitchField
                        isDisabled={isLoading}
                        label="Reveal to all"
                        labelPosition={switchFieldLabelPosition}
                        isChecked={revealGamingSystem}
                        onChange={(e) => {setRevealGamingSystem(e.target.checked);}}
                    />
                    <SwitchField
                        isDisabled={isLoading}
                        label="Reveal to all"
                        labelPosition={switchFieldLabelPosition}
                        isChecked={revealCountry}
                        onChange={(e) => {setRevealCountry(e.target.checked);}}
                    />
                    <SwitchField
                        isDisabled={isLoading}
                        label="Reveal to all"
                        labelPosition={switchFieldLabelPosition}
                        isChecked={revealDiscordName}
                        onChange={(e) => {setRevealDiscordName(e.target.checked);}}
                    />
                </Flex>
            </Flex>
            <EiaButton isFullWidth colorTheme={"success"} onClick={updateUser} disabled={isLoading}>Save Changes</EiaButton>
        </EiaDialog>
    );
}

export default SettingsDialog;