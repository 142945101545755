import React from "react";
import {Badge, Button, Flex, Grid, Heading, Icon, Image, Link, Menu, MenuButton, MenuItem, Text, useAuthenticator} from "@aws-amplify/ui-react";
import EiaButton from "../components/EiaButton";
import Utils from "../commons/Utils/Utils";
import AccountDialog from "../components/dialogs/AccountDialog";
import SettingsDialog from "../components/dialogs/SettingsDialog";
import eiaLabel from "../resources/eiaLabel.jpg";
import EiaSelect from "../components/EiaSelect";
import {GAMES} from "../commons/enums/EiaEnums";
import {MODES, REALM} from "../commons/enums/D4Enums";
import LocalStorage from "../commons/LocalStorage";
import EiaCard from "../components/cards/EiaCard";
import {RiNotificationFill} from "react-icons/ri";
import {VscTriangleDown} from "react-icons/vsc";

import {useCookies} from "react-cookie";
import {findByKeyValue, findByKeyValueAndSecondKeyNotEmpty} from "../commons/Utils/ArrayUtils";
import TradeDialog from "../components/dialogs/TradeDialog";
import EiaReminderLink from "../components/EiaReminderLink";
import {useNavigate} from "react-router-dom";
import * as Navigation from "../commons/enums/Navigation";
import {MY_SALES, NOTIFICATIONS} from "../commons/enums/Navigation";
import {newsEntries} from "./NewsPage";
import {COLOR_GRAY, COLOR_TEAL} from "../commons/enums/ColorEnums";
import EiaNavigationLink from "../components/EiaNavigationLink";
import EiaPagination from "../components/EiaPagination";

const isSmallScreen = Utils.isSmallScreen();

const withTransparentBackground = {
    opacity: "1.0"
}

const TransparentCard = function(props) {
    return (<EiaCard style={withTransparentBackground}  {...props}>
        {props.children}
    </EiaCard>)
}



const MainPageHeader = function (props) {
    const {signOut, setOpenLoginRegisterDialog, bidsState, mySalesState, notificationsState, reloadNotifications} = props;

    const {user} = useAuthenticator((context) => [context.user]);
    const loggedIn = user && user.username;

    const navigateWithRouter = useNavigate();

    const [cookies, setCookies, removeCookies] = useCookies(['mode', 'realm']);
    const [gameIndex, setGameIndex] = React.useState(1)

    const [openAccountDialog, setOpenAccountDialog] = React.useState(false)
    const [openSettingsDialog, setOpenSettingsDialog] = React.useState(false)
    const [openBidderTradeDialog, setOpenBidderTradeDialog] = React.useState(false)
    const [openSellerTradeDialog, setOpenSellerTradeDialog] = React.useState(false)

    const [notificationUpdateInterval, setNotificationUpdateInterval] = React.useState(null);

    React.useEffect(() => {
        if(!cookies.mode) {
            setCookies("mode", MODES[0], { maxAge: 31536000 })
        }

        if(!cookies.realm) {
            setCookies("realm", REALM[0], { maxAge: 31536000 })
        }

        LocalStorage.initializeApp();
        if (loggedIn) {
            console.log("login detected");
            if (!LocalStorage.getUser()) {
                LocalStorage.login(user.username);
            }

            if(Utils.isBrowserNotificationSupported()) {
                try {
                    Notification.requestPermission();
                } catch (error) {
                    console.error(error);
                }
            } else {
                console.log("Browser notifications are not supported by the browser");
            }

            reloadNotifications();
            setNotificationUpdateInterval(setInterval(reloadNotifications, 60000))
        }

        //init google adds
        // $('#adsense-side').html('<ins class="adsbygoogle" style="display:inline-block;width:250px;height:250px" data-ad-client="ca-pub-XXXXXXXXXXXXXXXX" data-ad-slot="XXXXXXXXXX"></ins>');
    }, [loggedIn]);

    const logout = function () {
        LocalStorage.logout();
        if(notificationUpdateInterval != null) {
            console.log("Stopping notifications...")
            clearInterval(notificationUpdateInterval)
            setNotificationUpdateInterval(null);
        }
        navigateWithRouter("/" + Navigation.WELCOME )
        signOut();
    }

    const bidToRemind = findByKeyValue(bidsState.bids, "myBidStatus", "ACCEPTED");
    const saleToAccept = findByKeyValueAndSecondKeyNotEmpty(mySalesState.auctions, "status", "ACTIVE", "bidders");
    const saleToTrade = findByKeyValue(mySalesState.auctions, "status", "TRADE");

    const imageElementWidth = isSmallScreen ? "50%" : "25%";

    const imageElement = <Image src={eiaLabel} width={imageElementWidth} height={"15%"}/>
    const gameSelectElement = <EiaSelect title={"Game"} values={GAMES} value={gameIndex}
                                  onChange={(e) => setGameIndex(e.target.value)}/>
    const firstNews = newsEntries[0];
    const firstNewsElement = <EiaCard>
        <Heading level={5} style={{color: COLOR_TEAL}}>{firstNews.title}</Heading>
        <Text fontSize={"0.75em"} style={{color: COLOR_GRAY}}>{firstNews.date}</Text>
        <EiaNavigationLink key={"news"} relativeLink={Navigation.NEWS} title={"Learn More"} />
    </EiaCard>

    const modeSelectElements = <Flex direction="row" justifyContent="space-evenly">
        <EiaSelect title={"Mode"} values={MODES} value={MODES.indexOf(cookies.mode)}
                   onChange={(e) => setCookies("mode", MODES[e.target.value], { maxAge: 31536000 })}/>
        <EiaSelect title={"Realm"} values={REALM} value={REALM.indexOf(cookies.realm)}
                   onChange={(e) => setCookies("realm", REALM[e.target.value], { maxAge: 31536000 })}/>
    </Flex>

    let reminders = [];
    if(bidToRemind) {
        reminders.push(<EiaReminderLink onClick={() => setOpenBidderTradeDialog(true)}
                                        text={"Your bid is accepted! Click here to trade"}/>)
    }
    if(saleToAccept) {
        reminders.push(<EiaReminderLink onClick={() => navigateWithRouter("/" + MY_SALES)}
                                        text={"You got a bid! Click here to view"}/>)
    }
    if(saleToTrade) {
        reminders.push(<EiaReminderLink onClick={() => setOpenSellerTradeDialog(true)}
                                        text={"You have a buyer! Click here to trade"}/>)
    }

    const [currentPage, setCurrentPage] = React.useState(1)

    const reminderElements = reminders.length > 0 ? <div>
            <TradeDialog open={openBidderTradeDialog} onCancel={() => setOpenBidderTradeDialog(false)}
                         bidsState={bidsState}
                         auction={bidToRemind ? bidToRemind : null} />
            <TradeDialog open={openSellerTradeDialog} onCancel={() => setOpenSellerTradeDialog(false)}
                         salesState={mySalesState}
                         isSeller={true}
                         auction={saleToTrade ? saleToTrade : null} />
            {
                reminders.length > 1 ? <EiaPagination totalPages={reminders.length} currentPage={currentPage} setCurrentPage={setCurrentPage} positionBottom={true}>
                        {reminders[currentPage - 1]}
                    </EiaPagination>
                    : reminders[0]
            }
        </div> : null;

    const notificationElement = loggedIn ? <div>
            <Button  size={"large"} variation={"link"} onClick={() => navigateWithRouter("/" + NOTIFICATIONS)}>
                <Badge size={"small"} style={{backgroundColor: "#950404"}}>{notificationsState.notifications.length}</Badge>
                <Icon ariaLabel="Javascript" as={RiNotificationFill}/>
            </Button>     </div> : null;

    const loginMenuElement = loggedIn ? <Flex
        direction="row"
        justifyContent="left"
    >
        <Menu menuAlign="end" size="large"                                 trigger={
            <MenuButton variation="link">
                <Flex>
                    <Link
                        textDecoration="underline"
                    >
                        {user ? user.username : ""}
                    </Link>
                    <Icon ariaLabel="Javascript" as={VscTriangleDown}/>
                </Flex>
            </MenuButton>}>

            <MenuItem onClick={() => setOpenAccountDialog(true)}>Account...</MenuItem>
            <MenuItem onClick={() => setOpenSettingsDialog(true)}>Settings...</MenuItem>
            <MenuItem onClick={logout}>Sign Out</MenuItem>
        </Menu>
    </Flex> : <EiaButton flex="1 0 auto" onClick={() => setOpenLoginRegisterDialog(true)}>
        Login / Register
    </EiaButton>

    const redBellAndLogin = <Flex id="red-bell-login" direction="row" justifyContent="flex-end">
        {notificationElement}
        {loginMenuElement}
    </Flex>

    return (
        <div>
            <AccountDialog open={openAccountDialog} onClose={() => setOpenAccountDialog(false)}/>
            <SettingsDialog open={openSettingsDialog} onClose={() => setOpenSettingsDialog(false)}/>
            {
                isSmallScreen ?
                    <div>
                        <Flex direction="row" justifyContent="space-evenly">
                            {imageElement}
                        </Flex>
                        <TransparentCard>
                            <Flex direction={"column"}>
                                <Flex direction="row" justifyContent="space-evenly">
                                    {firstNewsElement}
                                </Flex>
                                <Flex direction="row" justifyContent="space-evenly">
                                    {gameSelectElement}
                                </Flex>
                                {modeSelectElements}
                                {redBellAndLogin}
                                {reminderElements}
                            </Flex>
                        </TransparentCard>
                    </div>

                    :

                    <Grid templateColumns="1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr"
                          templateRows="1fr">
                        <TransparentCard columnStart="1" columnEnd="5">
                            <Flex direction="row" justifyContent="space-evenly">
                                {imageElement}
                                {firstNewsElement}
                            </Flex>
                        </TransparentCard>
                        <TransparentCard columnStart="5" columnEnd="6">
                            <Flex direction="row" justifyContent="space-evenly">
                                {gameSelectElement}
                            </Flex>
                        </TransparentCard>
                        <TransparentCard columnStart="6" columnEnd="9">
                            {modeSelectElements}
                        </TransparentCard>
                        <TransparentCard columnStart="9" columnEnd="12">
                            {reminderElements}
                        </TransparentCard>
                        <TransparentCard  columnStart="12" columnEnd="-1">
                            {redBellAndLogin}
                        </TransparentCard>
                    </Grid>
            }
        </div>

    );
}

export default MainPageHeader;