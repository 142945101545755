import {MY_SALES_TABLE_HEADERS} from "../../commons/TableHeaders";
import React from "react";
import ShowDetailsButton from "../../components/ShowDetailsButton";
import {findAllByKeyValues, findByKeyValue} from "../../commons/Utils/ArrayUtils";
import {useCookies} from "react-cookie";
import {d4GetMyAuctions} from "../../commons/api/D4Api";
import {auctionToTableElement} from "../../commons/Converter";
import EiaAuctionsWithButtons from "../../components/EiaAuctionsWithButtons";
import EiaButton from "../../components/EiaButton";

const tableInitialState = {
    sorting: {
        sortModel: [{field: 'creationDate', sort: 'desc'}],
    },
}

const MyHistorySalesPage = function (props) {

    const {platform, region} = props;

    const [cookies, setCookies, removeCookies] = useCookies(['mode', 'realm']);
    const mode = cookies.mode;
    const realm = cookies.realm;

    const [alert, setAlert] = React.useState(null)
    const [openAuctionSpecificDialog, setOpenAuctionSpecificDialog] = React.useState(false);

    const [auctionsTableData, setAuctionsTableData] = React.useState([])
    const [isLoading, setIsLoading] = React.useState(false)
    const [selectedAuction, setSelectedAuction] = React.useState(null)

    React.useEffect(() => {
        reloadMyAuctions();
    }, []);

    const reloadMyAuctions = function () {
        setIsLoading(true)
        selectAuction([])
        const request = {
            platform: platform,
            region: region,
            mode: mode,
            realm: realm,
            statusList: ["ABORTED", "COMPLETED"]
        }

        d4GetMyAuctions(request, function (response) {
            if (response.auctions) {
                let tempTableData = response.auctions.map(auction => {
                    return auctionToTableElement(auction);
                })

                let filteredAuctions = findAllByKeyValues(tempTableData, "status", ["ABORTED", "COMPLETED"]);
                setAuctionsTableData(filteredAuctions);
            } else {
                setAuctionsTableData([])
            }
            setIsLoading(false)
        }, function (error) {
            setAlert(["error", error.message]);
            setIsLoading(false)
        })
    }

    const selectAuction = function (id) {
        if (id.length != 0) {
            const auction = findByKeyValue(auctionsTableData, "id", id);
            setSelectedAuction(auction);
        } else {
            setSelectedAuction(null);
        }
    }

    const loadingOrNoSelectedAuction = isLoading || selectedAuction === undefined || selectedAuction === null;

    const auctionSpecificButtons = [
        <ShowDetailsButton key={"detailsButton"} isFullWidth disabled={loadingOrNoSelectedAuction} title={"Details"} details={selectedAuction}
                           additionalOnClose={() => setOpenAuctionSpecificDialog(false)}/>,
    ]

    const generalButtons = [
        <EiaButton key={"reloadButton"} isFullWidth onClick={reloadMyAuctions} disabled={isLoading} tooltip={"Reloads the item data"}>Reload</EiaButton>
    ]

    return <EiaAuctionsWithButtons
        alert={alert}
        isLoading={isLoading}
        tableData={auctionsTableData}
        tableHeaders={MY_SALES_TABLE_HEADERS}
        tableInitialState={tableInitialState}
        generalButtons={generalButtons}
        auctionSpecificButtons={auctionSpecificButtons}
        selectedAuctionId={selectedAuction ? selectedAuction.id : []}
        selectedAuction={selectedAuction}
        setSelectedAuctionId={selectAuction}
        openAuctionSpecificDialog={openAuctionSpecificDialog}
        setOpenAuctionSpecificDialog={setOpenAuctionSpecificDialog}
        defaultSortIndex={0}
        isViewFromSeller={true}
    />
}

export default MyHistorySalesPage;