import React from "react";
import {Tabs} from "@aws-amplify/ui-react";
import MyHistorySalesPage from "./history/MyHistorySalesPage";
import MyHistoryBidsBuysPage from "./history/MyHistoryBidsBuysPage";
import {useLocation, useNavigate} from "react-router-dom";
import Utils from "../commons/Utils/Utils";
import * as Navigation from "../commons/enums/Navigation";

const isSmallScreen = Utils.isSmallScreen()

const MyHistoryPage = function (props) {
    const {platform, region} = props;

    const navigateWithRouter = useNavigate();

    const navigate=function(value) {
        if(isSmallScreen) {
            Utils.scrollToStickyNavigationBar();
        }

        navigateWithRouter("/"+Navigation.MY_HISTORY+"/" + value);
    }

    let location = useLocation();
    const {basePath, subPath} = Utils.getPaths(location.pathname);

    return (
        <Tabs.Container isLazy defaultValue={subPath ? subPath : Navigation.SUB_PATH_SALES} justifyContent="flex-start" onValueChange={(value) => navigate(value)}>
            <Tabs.List>
                <Tabs.Item value={Navigation.SUB_PATH_SALES}>{"Sales"}</Tabs.Item>
                <Tabs.Item value={Navigation.SUB_PATH_BIDS_AND_BUYS}>{"Bids and Buys"}</Tabs.Item>
            </Tabs.List>
            <Tabs.Panel value={Navigation.SUB_PATH_SALES}>
                <MyHistorySalesPage platform={platform} region={region} />
            </Tabs.Panel>
            <Tabs.Panel value={Navigation.SUB_PATH_BIDS_AND_BUYS}>
                <MyHistoryBidsBuysPage platform={platform} region={region} />
            </Tabs.Panel>
        </Tabs.Container>
    )
}
export default MyHistoryPage;