import {BASE_BIDS_HEADERS} from "../../commons/TableHeaders";
import React from "react";
import {useCookies} from "react-cookie";
import ShowDetailsButton from "../../components/ShowDetailsButton";
import {findByKeyValue} from "../../commons/Utils/ArrayUtils";
import {d4GetMyBids} from "../../commons/api/D4Api";
import {myAuctionsAndBidsToMyBids} from "../../commons/Converter";
import EiaButton from "../../components/EiaButton";
import EiaAuctionsWithButtons from "../../components/EiaAuctionsWithButtons";

const tableInitialState = {
    sorting: {
        sortModel: [{field: 'creationDate', sort: 'desc'}],
    },
}

const MyHistoryBidsBuysPage = function (props) {

    const {platform, region} = props;

    const [cookies, setCookies, removeCookies] = useCookies(['mode', 'realm']);
    const mode = cookies.mode;
    const realm = cookies.realm;

    const [alert, setAlert] = React.useState(null)

    const [bidsTableData, setBidsTableData] = React.useState([])
    const [isLoading, setIsLoading] = React.useState(false)
    const [selectedBid, setSelectedBid] = React.useState(null)
    const [openAuctionSpecificDialog, setOpenAuctionSpecificDialog] = React.useState(false);

    React.useEffect(() => {
        reloadMyBids();
    }, []);

    const selectBid = function (id) {
        if (id.length != 0) {
            const bid = findByKeyValue(bidsTableData, "id", id);
            setSelectedBid(bid);
        } else {
            setSelectedBid(null);
        }
    }

    const reloadMyBids = function () {
        setIsLoading(true);
        selectBid([]);
        const request = {
            platform: platform,
            region: region,
            mode: mode,
            realm: realm,
        }

        d4GetMyBids(request, function (response) {
            if (response.auctions) {
                let tempTableData = myAuctionsAndBidsToMyBids(response.auctions, response.bids, ["WITHDRAWN", "SUCCESSFUL", "NOT_SUCCESSFUL"])
                setBidsTableData(tempTableData)
            } else {
                setBidsTableData([])
            }
            setIsLoading(false)
        }, function (error) {
            setAlert(["error", error.message]);
            setIsLoading(false)
        })
    }

    const loadingOrNoSelectedBid = isLoading || selectedBid === undefined || selectedBid === null;

    const generalButtons = [
        <EiaButton key={"reloadButton"} isFullWidth onClick={reloadMyBids} disabled={isLoading} tooltip={"Reload all your bids"}>Reload</EiaButton>
    ]

    const auctionSpecificButtons = [
        <ShowDetailsButton key={"detailsButton"} isFullWidth disabled={loadingOrNoSelectedBid} title={"Bid Details"} details={selectedBid}
                           additionalOnClose={() => setOpenAuctionSpecificDialog(false)}/>,
    ]

    return <EiaAuctionsWithButtons
        alert={alert}
        isLoading={isLoading}
        tableData={bidsTableData}
        tableHeaders={BASE_BIDS_HEADERS}
        tableInitialState={tableInitialState}
        generalButtons={generalButtons}
        auctionSpecificButtons={auctionSpecificButtons}
        selectedAuctionId={selectedBid ? selectedBid.id : []}
        selectedAuction={selectedBid}
        setSelectedAuctionId={selectBid}
        openAuctionSpecificDialog={openAuctionSpecificDialog}
        setOpenAuctionSpecificDialog={setOpenAuctionSpecificDialog}
        defaultSortIndex={0}
    />
}

export default MyHistoryBidsBuysPage;