import React from "react";
import {increaseArrayToIndex, removeByIndex} from "../../commons/Utils/ArrayUtils";
import EiaTextField from "../EiaTextField";
import {testShortFloatTwoDecimals} from "../../commons/Utils/RegExUtils";
import {Button, Flex, Icon, SwitchField, View} from "@aws-amplify/ui-react";
import {RiAddBoxFill, RiCloseCircleFill} from "react-icons/ri";
import EiaAutoComplete from "../EiaAutoComplete";
import EiaCard from "../cards/EiaCard";
import EiaSelect from "../EiaSelect";
import Utils from "../../commons/Utils/Utils";
import {AFFIXES} from "../../commons/enums/D4Affixes";

//currently we only support one number X_0 for stats but there could come more in the future
const floatValueIndex = 0;

const isTouchScreen = Utils.isTouchScreen();

//implicit stat or affix stat or aspect or unique stat selection
const D4ItemStatSelect = function(props) {
    const {statEnum, index, update, stats, onChange, setAlert, isGreaterAffixPossible} = props;

    const effectiveStatEnum = statEnum === undefined ? AFFIXES : statEnum;
    const isStatAdded = stats !== undefined && index !== undefined && index !== null && index < stats.length
        && stats[index] !== undefined && stats[index] !== null;
    const thisStat = isStatAdded ? stats[index] : null;
    const thisStatName = thisStat ? thisStat.name : "";
    const thisStatValueX0 = thisStat && thisStat.floatValues ? thisStat.floatValues[floatValueIndex] : 0;
    const greaterAffix = thisStat ? thisStat.greaterAffix : false;

    const [statNameFieldValue, setStatNameFieldValue] = React.useState("")

    React.useEffect(() => {
        if(update) {
            setStatNameFieldValue(thisStatName);
        }
    }, [update]);

    const updateStatName = function(newNameValue, index) {
        let newStatValue;
        if(index !== undefined) {
            newStatValue = effectiveStatEnum[index];
        } else {
            newStatValue = newNameValue;
        }

        setStatNameFieldValue(newNameValue);

        if(effectiveStatEnum.includes(newStatValue)) {
            let newStats = Object.assign([], stats);
            updateStats(newStats, newStatValue, thisStatValueX0)
        }
    }

    const updateStatValue = function(newValue) {
        let newStats = Object.assign([], stats);
        updateStats(newStats, null, newValue);
    }

    const updateStatGreaterAffix = function(newValue) {
        let newStats = Object.assign([], stats);
        updateStats(newStats, null, null, newValue);
    }

    const updateStats = function(newStats, statName, value, greaterAffix) {
        let stat = newStats[index];
        if(!stat) {
            const newStat = {
                "name": statName,
                "floatValues": []
            }
            newStats[index] = newStat;
            stat = newStat;
        } else {
            if(statName) {
                stat.name = statName;
            }
        }

        if(value || value === 0) {
            stat.floatValues = increaseArrayToIndex(stat.floatValues, floatValueIndex)
            stat.floatValues[floatValueIndex] = value;
        }

        if(greaterAffix) {
            stat.greaterAffix = true;
        } else {
            stat.greaterAffix = false;
        }

        onChange(newStats);
    }

    const addStat = function() {
        const initialStatName = "";
        setStatNameFieldValue(initialStatName);
        let newStats = Object.assign([], stats);
        updateStats(newStats, initialStatName, 0);
    }

    const removeStat = function() {
        let newStats = Object.assign([], stats);
        newStats = removeByIndex(newStats, index);
        onChange(newStats);
    }

    return (
        <EiaCard variation={"outlined"} roundCorners={true}>
            <Flex direction="column" justifyContent="space-between">
                {
                    isStatAdded ? null : <View>
                        <Button  size={"large"} variation={"link"} onClick={addStat}>
                            <Icon ariaLabel="Javascript" as={RiAddBoxFill}/>
                            Add Stat
                        </Button>
                    </View>
                }
                {
                    isStatAdded ?
                        <div>
                            {
                                isTouchScreen ? <EiaSelect values={effectiveStatEnum} value={statNameFieldValue} onChange={(e) => updateStatName(null, e.target.value)}></EiaSelect> :
                                    <EiaAutoComplete title={"Stat"} values={effectiveStatEnum} value={statNameFieldValue} setValue={(value) => updateStatName(value)}/>
                            }
                            {
                                thisStatName ? <EiaTextField errorMessage={"Enter number X_0 (integer or float like 1.5)"} validator={testShortFloatTwoDecimals} onChange={(e) => updateStatValue(e.currentTarget.value)} value={thisStatValueX0} hasError={thisStatValueX0 === ""}/> : null
                            }
                            <View>
                                <Flex>
                                    {thisStatName && isGreaterAffixPossible ?
                                        <SwitchField
                                            label="Greater Affix"
                                            labelPosition="start"
                                            isChecked={greaterAffix}
                                            onChange={(e) => {
                                                updateStatGreaterAffix(e.target.checked);
                                            }}
                                        /> : null}
                                    <Button size={"large"} variation={"link"} onClick={removeStat}>
                                        <Icon ariaLabel="Javascript" as={RiCloseCircleFill}/>
                                        Remove Stat
                                    </Button>
                                </Flex>
                            </View>
                        </div> : null
                }
            </Flex>
        </EiaCard>
    )
}
export default D4ItemStatSelect;