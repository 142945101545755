//created 20240813
export const AFFIXES = [
    "+X_0 Energy when a Stun Grenade Explodes",
    "+X_0 Essence On Hit",
    "+X_0 Essence per Enemy Drained by Blood Surge",
    "+X_0 to Darkness Skills",
    "+X_0 to Skeletal Warrior Mastery, Skeletal Mage Mastery, and Golem Mastery",
    "+X_0 to Stand Alone",
    "+X_0 Mount Armor",
    "+X_0 Resource On Hit",
    "+X_0 Maximum Poison Resistance",
    "+X_0% Chance When Struck to Fortify for X_1 Life",
    "+X_0% Resource Generation with Polearms",
    "+X_0% Chance When Struck to Gain X_1 Life as Barrier for X_2 Seconds",
    "+X_0% Critical Strike, Vulnerable, and Overpower Damage",
    "+X_0% Main Hand Weapon Damage",
    "+X_0 All Stats",
    "+X_0 Armor",
    "+X_0 Dexterity",
    "+X_0 Energy On Kill",
    "+X_0 Energy per Second",
    "+X_0 Essence On Kill",
    "+X_0 Essence per Second",
    "+X_0 Fury On Kill",
    "+X_0 Fury per Second",
    "+X_0 Intelligence",
    "+X_0 Life On Hit",
    "+X_0 Life On Kill",
    "+X_0 Life per Second",
    "+X_0 Mana On Kill",
    "+X_0 Mana per Second",
    "+X_0 Mana when a Fireball Explodes",
    "+X_0 Maximum Energy",
    "+X_0 Maximum Essence",
    "+X_0 Maximum Evade Charges",
    "+X_0 Maximum Fury",
    "+X_0 Maximum Life",
    "+X_0 Maximum Mana",
    "+X_0 Maximum Resource",
    "+X_0 Maximum Spirit",
    "+X_0 Potion Capacity",
    "+X_0 Spirit On Kill",
    "+X_0 Spirit per Second",
    "+X_0 Strength",
    "+X_0 Thorns",
    "+X_0 Willpower",
    "+X_0 to Abundance",
    "+X_0 to Adrenaline Rush",
    "+X_0 to Aftermath",
    "+X_0 to Aggressive Resistance",
    "+X_0 to Agile",
    "+X_0 to Agility Skills",
    "+X_0 to Alchemical Advantage",
    "+X_0 to Alchemist's Fortune",
    "+X_0 to Amplify Damage",
    "+X_0 to Arc Lash",
    "+X_0 to Ball Lightning",
    "+X_0 to Barrage",
    "+X_0 to Bash",
    "+X_0 to Basic Skills",
    "+X_0 to Blade Shift",
    "+X_0 to Blight",
    "+X_0 to Blizzard",
    "+X_0 to Blood Howl",
    "+X_0 to Blood Lance",
    "+X_0 to Blood Mist",
    "+X_0 to Blood Surge",
    "+X_0 to Bone Prison",
    "+X_0 to Bone Skills",
    "+X_0 to Bone Spear",
    "+X_0 to Bone Spirit",
    "+X_0 to Bone Splinters",
    "+X_0 to Boulder",
    "+X_0 to Brawling Skills",
    "+X_0 to Brute Force",
    "+X_0 to Call of the Wild",
    "+X_0 to Caltrops",
    "+X_0 to Chain Lightning",
    "+X_0 to Challenging Shout",
    "+X_0 to Charge",
    "+X_0 to Charged Bolts",
    "+X_0 to Chilling Weight",
    "+X_0 to Circle of Life",
    "+X_0 to Clarity",
    "+X_0 to Claw",
    "+X_0 to Coalesced Blood",
    "+X_0 to Cold Front",
    "+X_0 to Cold Imbuement",
    "+X_0 to Companion Skills",
    "+X_0 to Compound Fracture",
    "+X_0 to Concealment",
    "+X_0 to Concussion",
    "+X_0 to Conduction",
    "+X_0 to Conjuration Mastery",
    "+X_0 to Conjuration Skills",
    "+X_0 to Consuming Shadows",
    "+X_0 to Convulsions",
    "+X_0 to Core Skills",
    "+X_0 to Corpse Explosion",
    "+X_0 to Corpse Skills",
    "+X_0 to Corpse Tendrils",
    "+X_0 to Counteroffensive",
    "+X_0 to Crippling Darkness",
    "+X_0 to Crippling Flames",
    "+X_0 to Crushing Earth",
    "+X_0 to Curse Skills",
    "+X_0 to Cut to the Bone",
    "+X_0 to Cyclone Armor",
    "+X_0 to Dark Shroud",
    "+X_0 to Dash",
    "+X_0 to Deadly Venom",
    "+X_0 to Death Blow",
    "+X_0 to Death's Approach",
    "+X_0 to Death's Defense",
    "+X_0 to Death's Embrace",
    "+X_0 to Debilitating Roar",
    "+X_0 to Decompose",
    "+X_0 to Decrepify",
    "+X_0 to Defensive Posture",
    "+X_0 to Defensive Skills",
    "+X_0 to Defensive Stance",
    "+X_0 to Defiance",
    "+X_0 to Devouring Blaze",
    "+X_0 to Digitigrade Gait",
    "+X_0 to Double Swing",
    "+X_0 to Drain Vitality",
    "+X_0 to Duelist",
    "+X_0 to Earth Spike",
    "+X_0 to Earthen Bulwark",
    "+X_0 to Elemental Attunement",
    "+X_0 to Elemental Dominance",
    "+X_0 to Endless Fury",
    "+X_0 to Endless Pyre",
    "+X_0 to Endless Tempest",
    "+X_0 to Envenom",
    "+X_0 to Evulsion",
    "+X_0 to Exploit",
    "+X_0 to Expose Vulnerability",
    "+X_0 to Fiery Surge",
    "+X_0 to Fire Bolt",
    "+X_0 to Fireball",
    "+X_0 to Firewall",
    "+X_0 to Flame Shield",
    "+X_0 to Flay",
    "+X_0 to Flurry",
    "+X_0 to Forceful Arrow",
    "+X_0 to Frenzy",
    "+X_0 to Frigid Breeze",
    "+X_0 to Frigid Finesse",
    "+X_0 to Frost Bolt",
    "+X_0 to Frost Nova",
    "+X_0 to Frozen Orb",
    "+X_0 to Fueled by Death",
    "+X_0 to Furious Impulse",
    "+X_0 to Glass Cannon",
    "+X_0 to Gloom",
    "+X_0 to Golem Mastery",
    "+X_0 to Grim Harvest",
    "+X_0 to Ground Stomp",
    "+X_0 to Guttural Yell",
    "+X_0 to Hammer of the Ancients",
    "+X_0 to Haste",
    "+X_0 to Heartseeker",
    "+X_0 to Heavy Handed",
    "+X_0 to Heightened Senses",
    "+X_0 to Hellbent Commander",
    "+X_0 to Hemorrhage",
    "+X_0 to Hewed Flesh",
    "+X_0 to Hoarfrost",
    "+X_0 to Hurricane",
    "+X_0 to Hydra",
    "+X_0 to Ice Armor",
    "+X_0 to Ice Blades",
    "+X_0 to Ice Shards",
    "+X_0 to Icy Touch",
    "+X_0 to Imbuement Skills",
    "+X_0 to Imperfectly Balanced",
    "+X_0 to Impetus",
    "+X_0 to Imposing Presence",
    "+X_0 to Incinerate",
    "+X_0 to Inner Flames",
    "+X_0 to Innervation",
    "+X_0 to Invigorating Conduit",
    "+X_0 to Invigorating Fury",
    "+X_0 to Invigorating Strike",
    "+X_0 to Iron Fur",
    "+X_0 to Iron Maiden",
    "+X_0 to Iron Skin",
    "+X_0 to Kick",
    "+X_0 to Landslide",
    "+X_0 to Leap",
    "+X_0 to Lightning Spear",
    "+X_0 to Lightning Storm",
    "+X_0 to Lunging Strike",
    "+X_0 to Macabre Skills",
    "+X_0 to Malice",
    "+X_0 to Martial Vigor",
    "+X_0 to Mastery Skills",
    "+X_0 to Maul",
    "+X_0 to Memento Mori",
    "+X_0 to Mending Obscurity",
    "+X_0 to Mending",
    "+X_0 to Meteor",
    "+X_0 to Natural Disaster",
    "+X_0 to Nature Magic Skills",
    "+X_0 to Nature's Reach",
    "+X_0 to Nature's Resolve",
    "+X_0 to Necrotic Carapace",
    "+X_0 to No Mercy",
    "+X_0 to Outburst",
    "+X_0 to Penetrating Shot",
    "+X_0 to Permafrost",
    "+X_0 to Pit Fighter",
    "+X_0 to Poison Creeper",
    "+X_0 to Poison Imbuement",
    "+X_0 to Poison Trap",
    "+X_0 to Potent Warding",
    "+X_0 to Precision Imbuement",
    "+X_0 to Predatory Instinct",
    "+X_0 to Pressure Point",
    "+X_0 to Prolific Fury",
    "+X_0 to Pulverize",
    "+X_0 to Puncture",
    "+X_0 to Pyromancy Skills",
    "+X_0 to Quickshift",
    "+X_0 to Rabies",
    "+X_0 to Raid Leader",
    "+X_0 to Rallying Cry",
    "+X_0 to Rapid Fire",
    "+X_0 to Rapid Gambits",
    "+X_0 to Rapid Ossification",
    "+X_0 to Ravens",
    "+X_0 to Reap",
    "+X_0 to Reaper's Pursuit",
    "+X_0 to Rend",
    "+X_0 to Resonance",
    "+X_0 to Rupture",
    "+X_0 to Safeguard",
    "+X_0 to Second Wind",
    "+X_0 to Sever",
    "+X_0 to Shadow Crash",
    "+X_0 to Shadow Imbuement",
    "+X_0 to Shadow Step",
    "+X_0 to Shocking Impact",
    "+X_0 to Shred",
    "+X_0 to Siphoning Strikes",
    "+X_0 to Skeletal Mage Mastery",
    "+X_0 to Skeletal Warrior Mastery",
    "+X_0 to Slashing Skills",
    "+X_0 to Slaying Strike",
    "+X_0 to Smoke Grenade",
    "+X_0 to Snap Freeze",
    "+X_0 to Spark",
    "+X_0 to Spiked Armor",
    "+X_0 to Steel Grasp",
    "+X_0 to Steel Grasp, Cold Imbuement, Frost, Hurricane, or Skeletal Mage Mastery",
    "+X_0 to Stone Guard",
    "+X_0 to Storm Strike",
    "+X_0 to Stutter Step",
    "+X_0 to Subterfuge Skills",
    "+X_0 to Teleport",
    "+X_0 to Terror",
    "+X_0 to Tides of Blood",
    "+X_0 to Tornado",
    "+X_0 to Tough as Nails",
    "+X_0 to Toxic Claws",
    "+X_0 to Trample",
    "+X_0 to Transfusion",
    "+X_0 to Trap Mastery",
    "+X_0 to Trick Attacks",
    "+X_0 to Twisting Blades",
    "+X_0 to Unstable Elixirs",
    "+X_0 to Upheaval",
    "+X_0 to Upheaval, Cutthroat, Pyromancy, Earth, or Blood",
    "+X_0 to Wallop",
    "+X_0 to War Cry",
    "+X_0 to Warmth",
    "+X_0 to Weapon Mastery Skills",
    "+X_0 to Weapon Mastery",
    "+X_0 to Whirlwind",
    "+X_0 to Wild Impulses",
    "+X_0 to Wind Shear",
    "+X_0 to Wolves",
    "+X_0 to Wrath Skills",
    "+X_0% All Stats",
    "+X_0% Attack Speed for 4 Seconds After Dodging an Attack",
    "+X_0% Attack Speed while Berserking",
    "+X_0% Attack Speed",
    "+X_0% Barrier Generation",
    "+X_0% Basic Attack Speed",
    "+X_0% Basic Damage",
    "+X_0% Berserking Duration",
    "+X_0% Blight Size",
    "+X_0% Blight Slow Potency",
    "+X_0% Blizzard Damage",
    "+X_0% Blizzard Size",
    "+X_0% Blood Attack Speed",
    "+X_0% Blood Damage",
    "+X_0% Blood Lance Duration",
    "+X_0% Blood Mist Duration",
    "+X_0% Blood Orb Healing",
    "+X_0% Blood Overpower Damage",
    "+X_0% Blood Surge Nova Size",
    "+X_0% Bone Critical Strike Chance",
    "+X_0% Bone Critical Strike Damage",
    "+X_0% Bone Damage",
    "+X_0% Bone Spirit Damage",
    "+X_0% Bone Spirit Explosion Size",
    "+X_0% Bone Storm Duration",
    "+X_0% Boulder Damage",
    "+X_0% Brawling Damage",
    "+X_0% Caltrops Duration",
    "+X_0% Caltrops Size",
    "+X_0% Cataclysm Damage",
    "+X_0% Chance For Minion Attacks to Fortify You for 3% Maximum Life",
    "+X_0% Chance for Arc Lash to Swipe Twice",
    "+X_0% Chance for Ball Lightning Projectiles to Cast Twice",
    "+X_0% Chance for Barrage Projectiles to Cast Twice",
    "+X_0% Chance for Basic Projectiles to Cast Twice",
    "+X_0% Chance for Blight Projectiles to Cast Twice",
    "+X_0% Chance for Bone Spear Projectiles to Cast Twice",
    "+X_0% Chance for Bone Splinters Projectiles to Cast Twice",
    "+X_0% Chance for Boulder Projectiles to Cast Twice",
    "+X_0% Chance for Chain Lightning Projectiles to Cast Twice",
    "+X_0% Chance for Charged Bolts Projectiles to Cast Twice",
    "+X_0% Chance for Core Projectiles to Cast Twice",
    "+X_0% Chance for Dust Devils to Cast Twice",
    "+X_0% Chance for Earth Spike Projectiles to Cast Twice",
    "+X_0% Chance for Fire Bolt Projectiles to Cast Twice",
    "+X_0% Chance for Fireball Projectiles to Cast Twice",
    "+X_0% Chance for Forceful Arrow Projectiles to Cast Twice",
    "+X_0% Chance for Frost Bolt Projectiles to Cast Twice",
    "+X_0% Chance for Frozen Orb Projectiles to Cast Twice",
    "+X_0% Chance for Heartseeker Projectiles to Cast Twice",
    "+X_0% Chance for Hemorrhage to Form Blood Orbs",
    "+X_0% Chance for Ice Shards Projectiles to Cast Twice",
    "+X_0% Chance for Ice Spikes to Explode Twice",
    "+X_0% Chance for Landslide Projectiles to Cast Twice",
    "+X_0% Chance for Marksman Projectiles to Cast Twice",
    "+X_0% Chance for Penetrating Shot Projectiles to Cast Twice",
    "+X_0% Chance for Projectiles to Cast Twice",
    "+X_0% Chance for Puncture Projectiles to Cast Twice",
    "+X_0% Chance for Rain of Arrows Waves to Cast Twice",
    "+X_0% Chance for Rapid Fire Projectiles to Cast Twice",
    "+X_0% Chance for Sever Projectiles to Cast Twice",
    "+X_0% Chance for Skeletal Mage Attacks to Cast Twice",
    "+X_0% Chance for Spark Projectiles to Cast Twice",
    "+X_0% Chance for Tornado Projectiles to Cast Twice",
    "+X_0% Chance for Wind Shear Projectiles to Cast Twice",
    "+X_0% Chance for a Second Ice Blades When Cast",
    "+X_0% Chance for a Second Lightning Spear When Cast",
    "+X_0% Charge Damage",
    "+X_0% Chill Slow Potency",
    "+X_0% Cold Damage",
    "+X_0% Cold Mage Attack Speed",
    "+X_0% Cold Resistance",
    "+X_0% Companion Damage",
    "+X_0% Companion Movement Speed",
    "+X_0% Concealment Duration",
    "+X_0% Conjuration Damage",
    "+X_0% Core Attack Speed",
    "+X_0% Core Damage",
    "+X_0% Corpse Attack Speed",
    "+X_0% Corpse Explosion Damage",
    "+X_0% Corpse Explosion Size",
    "+X_0% Corpse Tendrils Damage",
    "+X_0% Corpse Tendrils Duration",
    "+X_0% Corpse Tendrils Size",
    "+X_0% Crackling Energy Damage",
    "+X_0% Crafting Material Drop Rate",
    "+X_0% Critical Strike Chance Against Chilled Enemies",
    "+X_0% Critical Strike Chance Against Close Enemies",
    "+X_0% Critical Strike Chance Against Crowd Controlled Enemies",
    "+X_0% Critical Strike Chance Against Feared Enemies",
    "+X_0% Critical Strike Chance Against Injured Enemies",
    "+X_0% Critical Strike Chance Against Stunned Enemies",
    "+X_0% Critical Strike Chance to each Enhanced Rapid Fire Bonus",
    "+X_0% Critical Strike Chance",
    "+X_0% Critical Strike Damage",
    "+X_0% Crowd Control Duration",
    "+X_0% Curse Duration",
    "+X_0% Curse Size",
    "+X_0% Cutthroat Attack Speed",
    "+X_0% Cutthroat Critical Strike Chance",
    "+X_0% Cutthroat Critical Strike Damage",
    "+X_0% Cutthroat Damage",
    "+X_0% Cyclone Armor Active Size",
    "+X_0% Cyclone Armor Damage",
    "+X_0% Damage Over Time",
    "+X_0% Damage for 4 Seconds After Dodging an Attack",
    "+X_0% Damage for 4 Seconds After Killing an Elite",
    "+X_0% Damage for 4 Seconds After Picking Up a Blood Orb",
    "+X_0% Damage on Next Attack After Entering Stealth",
    "+X_0% Damage per Combo Point Spent",
    "+X_0% Damage per Dark Shroud Shadow",
    "+X_0% Damage to Bleeding Enemies",
    "+X_0% Damage to Burning Enemies",
    "+X_0% Damage to Chilled Enemies",
    "+X_0% Damage to Close Enemies",
    "+X_0% Damage to Crowd Controlled Enemies",
    "+X_0% Damage to Dazed Enemies",
    "+X_0% Damage to Distant Enemies",
    "+X_0% Damage to Elites",
    "+X_0% Damage to Enemies Affected by Curse Skills",
    "+X_0% Damage to Enemies Affected by Trap Skills",
    "+X_0% Damage to Frozen Enemies",
    "+X_0% Damage to Healthy Enemies",
    "+X_0% Damage to Immobilized Enemies",
    "+X_0% Damage to Injured Enemies",
    "+X_0% Damage to Poisoned Enemies",
    "+X_0% Damage to Shadow Damage Over Time-Affected Enemies",
    "+X_0% Damage to Slowed Enemies",
    "+X_0% Damage to Stunned Enemies",
    "+X_0% Damage when Swapping Weapons",
    "+X_0% Damage while Berserking",
    "+X_0% Damage while Fortified",
    "+X_0% Damage while Healthy",
    "+X_0% Damage while Iron Maelstrom is Active",
    "+X_0% Damage while Shapeshifted",
    "+X_0% Damage while War Cry is Active",
    "+X_0% Damage while Wrath of the Berserker is Active",
    "+X_0% Damage while in Human Form",
    "+X_0% Damage with Dual-Wielded Weapons",
    "+X_0% Damage with Ranged Weapons",
    "+X_0% Damage with Two-Handed Bludgeoning Weapons",
    "+X_0% Damage with Two-Handed Slashing Weapons",
    "+X_0% Damage",
    "+X_0% Darkness Damage",
    "+X_0% Dash Damage",
    "+X_0% Death Blow Damage",
    "+X_0% Death Blow Size",
    "+X_0% Debilitating Roar Duration",
    "+X_0% Decompose Explosion Size",
    "+X_0% Decrepify Size",
    "+X_0% Desecrated Ground Damage",
    "+X_0% Dexterity",
    "+X_0% Dodge Chance",
    "+X_0% Double Swing Size",
    "+X_0% Dust Devil Damage",
    "+X_0% Dust Devil Duration",
    "+X_0% Dust Devil Size",
    "+X_0% Earth Attack Speed",
    "+X_0% Earth Critical Strike Chance",
    "+X_0% Earth Critical Strike Damage",
    "+X_0% Earth Damage",
    "+X_0% Earth Lucky Hit Chance",
    "+X_0% Earth Overpower Damage",
    "+X_0% Earthen Bulwark Duration",
    "+X_0% Earthquake Damage",
    "+X_0% Earthquake Duration",
    "+X_0% Earthquake Size",
    "+X_0% Enhanced Rupture Explosion Size",
    "+X_0% Fire Damage Over Time",
    "+X_0% Fire Damage",
    "+X_0% Fire Lucky Hit Chance",
    "+X_0% Fire Resistance",
    "+X_0% Fire and Cold Damage",
    "+X_0% Fireball Attack Speed",
    "+X_0% Fireball Projectile Speed",
    "+X_0% Firewall Size",
    "+X_0% Flame Shield Duration",
    "+X_0% Flay Duration",
    "+X_0% Flurry Healing",
    "+X_0% Flurry Size",
    "+X_0% Fortify Generation",
    "+X_0% Freeze Duration",
    "+X_0% Frenzy Duration",
    "+X_0% Frost Damage",
    "+X_0% Frost Nova Size",
    "+X_0% Golems Damage",
    "+X_0% Grenade Damage",
    "+X_0% Grizzly Rage Duration",
    "+X_0% Ground Stomp Damage",
    "+X_0% Ground Stomp Size",
    "+X_0% Hammer of the Ancients Size",
    "+X_0% Healing Received",
    "+X_0% Heartseeker Duration",
    "+X_0% Hemorrhage Explosion Size",
    "+X_0% Hurricane Damage",
    "+X_0% Hurricane Duration",
    "+X_0% Hurricane Size",
    "+X_0% Hydra Damage",
    "+X_0% Hydra Lucky Hit Chance",
    "+X_0% Ice Armor Duration",
    "+X_0% Ice Blades Damage",
    "+X_0% Ice Blades Lucky Hit Chance",
    "+X_0% Ice Spike Damage",
    "+X_0% Imbued Critical Strike Damage",
    "+X_0% Imbued Damage",
    "+X_0% Imbuement Damage",
    "+X_0% Immobilize Duration",
    "+X_0% Incinerate Size",
    "+X_0% Inner Sight Duration",
    "+X_0% Intelligence",
    "+X_0% Invigorating Strike Duration",
    "+X_0% Invigorating Strike Energy Regeneration",
    "+X_0% Iron Maiden Damage",
    "+X_0% Iron Maiden Size",
    "+X_0% Kick Damage",
    "+X_0% Kick Vulnerable Duration",
    "+X_0% Lacerate Damage",
    "+X_0% Lacerate Duration",
    "+X_0% Leap Damage",
    "+X_0% Leap Slam Size",
    "+X_0% Lightning Bolt Damage",
    "+X_0% Lightning Critical Strike Damage",
    "+X_0% Lightning Damage",
    "+X_0% Lightning Resistance",
    "+X_0% Lightning Spear Damage",
    "+X_0% Lightning Spear Lucky Hit Chance",
    "+X_0% Lightning Storm Duration",
    "+X_0% Lucky Hit Chance for the Pack Leader Spirit Boon",
    "+X_0% Lucky Hit Chance while You Have a Barrier",
    "+X_0% Lucky Hit Chance",
    "+X_0% Lunging Strike Healing",
    "+X_0% Lunging Strike Range",
    "+X_0% Macabre Damage",
    "+X_0% Marksman Attack Speed per Precison Stack",
    "+X_0% Marksman Critical Strike Chance",
    "+X_0% Marksman Critical Strike Damage",
    "+X_0% Marksman Damage",
    "+X_0% Mastery Damage",
    "+X_0% Maul Size",
    "+X_0% Maximum Life while Dark Shroud is Active",
    "+X_0% Maximum Resistance to All Elements",
    "+X_0% Maximum Shadow Resistance",
    "+X_0% Meteor Size",
    "+X_0% Minion Attack Speed",
    "+X_0% Movement Speed during Blood Mist",
    "+X_0% Movement Speed for 4 Seconds After Killing an Elite",
    "+X_0% Movement Speed for 4 Seconds After Picking Up Crackling Energy",
    "+X_0% Movement Speed for 7 Seconds After Killing an Elite",
    "+X_0% Movement Speed from Blade Shift",
    "+X_0% Movement Speed per Dark Shroud Shadow",
    "+X_0% Movement Speed per Walking Arsenal Bonus",
    "+X_0% Movement Speed while Berserking",
    "+X_0% Movement Speed while Cataclysm is Active",
    "+X_0% Movement Speed while Hurricane is Active",
    "+X_0% Movement Speed while the Inner Sight Gauge is Full",
    "+X_0% Movement Speed",
    "+X_0% Non-Physical Damage",
    "+X_0% Overpower Chance",
    "+X_0% Overpower Damage with Two-Handed Bludgeoning Weapons",
    "+X_0% Overpower Damage",
    "+X_0% Petrify Duration",
    "+X_0% Physical Critical Strike Chance Against Elites",
    "+X_0% Physical Damage Over Time",
    "+X_0% Physical Damage",
    "+X_0% Poison Creeper Damage",
    "+X_0% Poison Creeper Duration",
    "+X_0% Poison Damage",
    "+X_0% Poison Resistance",
    "+X_0% Poison Trap Duration",
    "+X_0% Poison Trap Size",
    "+X_0% Potion Drop Rate",
    "+X_0% Potion Healing",
    "+X_0% Pulverize Size",
    "+X_0% Pyromancy Attack Speed",
    "+X_0% Pyromancy Critical Strike Damage",
    "+X_0% Pyromancy Damage",
    "+X_0% Rabies Damage",
    "+X_0% Rabies Duration",
    "+X_0% Rain of Arrows Damage",
    "+X_0% Ravens Active Size",
    "+X_0% Ravens Attack Speed",
    "+X_0% Ravens Damage",
    "+X_0% Reap Duration",
    "+X_0% Rend Size",
    "+X_0% Resistance to All Elements",
    "+X_0% Resource Generation while Wielding a Scythe",
    "+X_0% Resource Generation while Wielding a Shield",
    "+X_0% Resource Generation with Dual-Wielded Weapons",
    "+X_0% Resource Generation with Two-Handed Bludgeoning Weapons",
    "+X_0% Resource Generation with Two-Handed Slashing Weapons",
    "+X_0% Rupture Damage",
    "+X_0% Sever Size",
    "+X_0% Shadow Clone Damage",
    "+X_0% Shadow Clone Duration",
    "+X_0% Shadow Damage Over Time",
    "+X_0% Shadow Damage",
    "+X_0% Shadow Imbuement Size",
    "+X_0% Shadow Lucky Hit Chance",
    "+X_0% Shadow Resistance",
    "+X_0% Shadow Step Damage",
    "+X_0% Shadow Step Duration",
    "+X_0% Shapeshifting Attack Speed",
    "+X_0% Shock Critical Strike Chance",
    "+X_0% Shock Critical Strike Damage",
    "+X_0% Shock Damage",
    "+X_0% Shred Critical Strike Chance",
    "+X_0% Shrine Buff Duration",
    "+X_0% Skeletal Mages Damage",
    "+X_0% Skeleton Priest Effect Duration",
    "+X_0% Smoke Grenade Damage",
    "+X_0% Smoke Grenade Duration",
    "+X_0% Smoke Grenade Size",
    "+X_0% Steel Grasp Damage",
    "+X_0% Steel Grasp Duration",
    "+X_0% Storm Critical Strike Chance",
    "+X_0% Storm Damage",
    "+X_0% Strength",
    "+X_0% Stun Duration",
    "+X_0% Stun Grenade Damage",
    "+X_0% Stun Grenade Size",
    "+X_0% Summoning Damage",
    "+X_0% Teleport Damage",
    "+X_0% Teleport Nova Size",
    "+X_0% Thorns while Fortified",
    "+X_0% Total Armor while in Werebear Form",
    "+X_0% Total Armor while in Werewolf Form",
    "+X_0% Total Armor",
    "+X_0% Trample Damage",
    "+X_0% Trap Damage",
    "+X_0% Ultimate Damage",
    "+X_0% Upheaval Size",
    "+X_0% Vulnerable Damage",
    "+X_0% Weapon Mastery Attack Speed",
    "+X_0% Weapon Mastery Damage",
    "+X_0% Werebear Damage",
    "+X_0% Werebear Overpower Damage",
    "+X_0% Werewolf Attack Speed",
    "+X_0% Werewolf Critical Strike Chance",
    "+X_0% Werewolf Critical Strike Damage",
    "+X_0% Werewolf Damage",
    "+X_0% Whirlwind Size",
    "+X_0% Willpower",
    "+X_0% Wolves Attack Speed",
    "+X_0% Wolves Damage",
    "+X_0% to Lupine Ferocity's Damage Bonus",
    "+X_0% to Prime Bone Storm's Damage Reduction",
    "+X_0% to Shatter's Damage Echo",
    "+X_0% to Ursine Strength's Bonuses",
    "X_0% Block Chance",
    "X_0% Blocked Damage Reduction",
    "Attacks Reduce Evade's Cooldown by X_0 Seconds",
    "Bash Cleaves for +X_0% Damage",
    "Blood Howl Grants Stealth for +X_0 Seconds",
    "Blood Orbs Restore +X_0 Essence",
    "Blood Surge Drains +X_0 Times from Elites",
    "Casted Hydras Have +1 Head",
    "Casted Hydras Have +2 Heads",
    "Casting Macabre Skills Restores +X_0 Primary Resource",
    "Casting Ultimate Skills Restores +X_0 Primary Resource",
    "Casting Wrath Skills Restores +X_0 Primary Resource",
    "Cold Imbue Lasts For +1 Cast",
    "Cold Imbue Lasts For +2 Casts",
    "Conjuration Cooldowns are Reduced by +X_0 Seconds when a Frozen Orb Explodes",
    "Corpse Explosion Fears and Slows for X_0 Seconds",
    "Drinking a Potion Grants X_0% Movement Speed for 2 Seconds",
    "Evade Grants +10% Attack Speed for 3 Seconds",
    "Evade Grants +X_0% Movement Speed for X_1 Seconds",
    "Evade Grants Unhindered for 2.0 Seconds",
    "Evade Leaves Behind Desecrated Ground for 2 Seconds",
    "Golems Inherit +X_0% of Your Thorns",
    "Ice Spikes Freeze Enemies for X_0 Seconds",
    "Ignore Durability Loss",
    "Lucky Hit: Critical Strikes Have Up to a +X_0% Chance to Daze for 2 Seconds",
    "Lucky Hit: Critical Strikes Have Up to a +X_0% Chance to Immobilize for 2 Seconds",
    "Lucky Hit: Critical Strikes Have Up to a +X_0% Chance to Slow for 2 Seconds",
    "Lucky Hit: Critical Strikes Have Up to a +X_0% Chance to Stun for 2 Seconds",
    "Lucky Hit: Up to a +30.0% Chance to Deal X_0% Bleeding Damage over 5 seconds",
    "Lucky Hit: Up to a +X_0% Chance to Apply a Random Crowd Control Effect for 2 Seconds",
    "Lucky Hit: Up to a +X_0% Chance to Daze for 2 Seconds",
    "Lucky Hit: Up to a +X_0% Chance to Execute Injured Non-Elites",
    "Lucky Hit: Up to a +X_0% Chance to Fear for 2 Seconds",
    "Lucky Hit: Up to a +X_0% Chance to Freeze for 2 Seconds",
    "Lucky Hit: Up to a +X_0% Chance to Immobilize for 2 Seconds",
    "Lucky Hit: Up to a +X_0% Chance to Make Enemies Vulnerable for 0 Seconds",
    "Lucky Hit: Up to a +X_0% Chance to Make Enemies Vulnerable for 2 Seconds",
    "Lucky Hit: Up to a +X_0% Chance to Slow for 2 Seconds",
    "Lucky Hit: Up to a +X_0% Chance to Stun for 2 Seconds",
    "Lucky Hit: Up to a 10% Chance to Gain +X_0% Damage for 4 Seconds",
    "Lucky Hit: Up to a 40% Chance to Deal +X_0 Cold Damage",
    "Lucky Hit: Up to a 40% Chance to Deal +X_0 Fire Damage",
    "Lucky Hit: Up to a 40% Chance to Deal +X_0 Lightning Damage",
    "Lucky Hit: Up to a 40% Chance to Deal +X_0 Physical Damage",
    "Lucky Hit: Up to a 40% Chance to Deal +X_0 Poison Damage",
    "Lucky Hit: Up to a 40% Chance to Deal +X_0 Shadow Damage",
    "Lucky Hit: Up to a 5% Chance to Heal +X_0 Life",
    "Lucky Hit: Up to a 5% Chance to Restore +X_0% Primary Resource",
    "Minions Inherit +X_0% of Your Thorns",
    "Poison Imbue Lasts For +1 Cast",
    "Poison Imbue Lasts For +2 Casts",
    "Shadow Imbue Lasts For +1 Cast",
    "Shadow Imbue Lasts For +2 Casts",
    "Skeletal Mages Inherit +X_0% of Your Thorns",
    "Skeletal Warriors Inherit +X_0% of Your Thorns",
    "Steel Grasp Stuns for +X_0 Seconds",
    "Storm Strike Chains to +1 Target",
    "Traps Arm 1.0 Second Faster",
    "Traps Arm X_0 Seconds Faster",
    "Twisting Blades Returns +X_0% Faster",
    "Upheaval Overpowers Stun for +X_0 Seconds",
    "While Injured, Your Potion Also Grants 20% Maximum Life as Barrier",
    "While Injured, Your Potion Also Grants 30% Movement Speed for 2 Seconds",
    "While Injured, Your Potion Also Restores 20% Resource",
    "X_0% Agility Cooldown Reduction",
    "X_0% Basic Resource Generation",
    "X_0% Blood Howl Cooldown Reduction",
    "X_0% Blood Mist Cooldown Reduction",
    "X_0% Bone Prison Cooldown Reduction",
    "X_0% Bone Spirit Cooldown Reduction",
    "X_0% Boulder Cooldown Reduction",
    "X_0% Brawling Cooldown Reduction",
    "X_0% Call of the Ancients Cooldown Reduction",
    "X_0% Caltrops Cooldown Reduction",
    "X_0% Cataclysm Cooldown Reduction",
    "X_0% Challenging Shout Cooldown Reduction",
    "X_0% Charge Cooldown Reduction",
    "X_0% Companion Cooldown Reduction",
    "X_0% Concealment Cooldown Reduction",
    "X_0% Cooldown Reduction",
    "X_0% Cyclone Armor Cooldown Reduction",
    "X_0% Damage Reduction for Your Minions",
    "X_0% Damage Reduction from Bleeding Enemies",
    "X_0% Damage Reduction from Burning Enemies",
    "X_0% Damage Reduction from Close Enemies",
    "X_0% Damage Reduction from Distant Enemies",
    "X_0% Damage Reduction from Elites",
    "X_0% Damage Reduction from Enemies Affected by Blood Skills",
    "X_0% Damage Reduction from Enemies Affected by Curse Skills",
    "X_0% Damage Reduction from Enemies Affected by Trap Skills",
    "X_0% Damage Reduction from Poisoned Enemies",
    "X_0% Damage Reduction from Shadow Damage Over Time-Affected Enemies",
    "X_0% Damage Reduction per Crackling Energy Charge",
    "X_0% Damage Reduction while Fortified",
    "X_0% Damage Reduction while Healthy",
    "X_0% Damage Reduction while Injured",
    "X_0% Damage Reduction while Unstoppable",
    "X_0% Damage Reduction",
    "X_0% Dark Shroud Cooldown Reduction",
    "X_0% Dash Cooldown Reduction",
    "X_0% Death Blow Cooldown Reduction",
    "X_0% Debilitating Roar Cooldown Reduction",
    "X_0% Deep Freeze Cooldown Reduction",
    "X_0% Defensive Cooldown Reduction",
    "X_0% Dodge Chance Against Close Enemies",
    "X_0% Dodge Chance Against Distant Enemies",
    "X_0% Dodge Chance",
    "X_0% Earthen Bulwark Cooldown Reduction",
    "X_0% Energy Cost Reduction",
    "X_0% Essence Cost Reduction",
    "X_0% Evade Cooldown Reduction",
    "X_0% Frost Nova Cooldown Reduction",
    "X_0% Fury Cost Reduction",
    "X_0% Gem Strength in this Item",
    "X_0% Golem Active Cooldown Reduction",
    "X_0% Grizzly Rage Cooldown Reduction",
    "X_0% Ground Stomp Cooldown Reduction",
    "X_0% Hurricane Cooldown Reduction",
    "X_0% Hydra Resource Cost Reduction",
    "X_0% Ice Blades Cooldown Reduction",
    "X_0% Imbuement Cooldown Reduction",
    "X_0% Impairment Reduction",
    "X_0% Inferno Cooldown Reduction",
    "X_0% Iron Maelstrom Cooldown Reduction",
    "X_0% Iron Skin Cooldown Reduction",
    "X_0% Kick Cooldown Reduction",
    "X_0% Lacerate Cooldown Reduction",
    "X_0% Leap Cooldown Reduction",
    "X_0% Lightning Spear Cooldown Reduction",
    "X_0% Mana Cost Reduction",
    "X_0% Maximum Life",
    "X_0% Maximum Minion Life",
    "X_0% Mobility Cooldown Reduction",
    "X_0% Nature Magic Cooldown Reduction",
    "X_0% Petrify Cooldown Reduction",
    "X_0% Poison Creeper Cooldown Reduction",
    "X_0% Poison Trap Cooldown Reduction",
    "X_0% Puncture Resource Generation",
    "X_0% Rabies Cooldown Reduction",
    "X_0% Rain of Arrows Cooldown Reduction",
    "X_0% Ravens Cooldown Reduction",
    "X_0% Resource Cost Reduction",
    "X_0% Resource Generation and Maximum",
    "X_0% Resource Generation",
    "X_0% Rupture Cooldown Reduction",
    "X_0% Shadow Step Cooldown Reduction",
    "X_0% Shout Cooldown Reduction",
    "X_0% Slow Duration Reduction",
    "X_0% Smoke Grenade Cooldown Reduction",
    "X_0% Spirit Cost Reduction",
    "X_0% Steel Grasp Cooldown Reduction",
    "X_0% Storm Cooldown Reduction",
    "X_0% Subterfuge Cooldown Reduction",
    "X_0% Teleport Cooldown Reduction",
    "X_0% Trample Cooldown Reduction",
    "X_0% Trap Cooldown Reduction",
    "X_0% Ultimate Cooldown Reduction",
    "X_0% Unstable Currents Cooldown Reduction",
    "X_0% War Cry Cooldown Reduction",
    "X_0% Weapon Mastery Cooldown Reduction",
    "X_0% Wolves Cooldown Reduction",
    "X_0% Wrath of the Berserker Cooldown Reduction",
    "Your Potion Also Grants +X_0% Maximum Resistances for 5 Seconds"
];


