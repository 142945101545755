import {post} from "./ApiPost";

export async function userApiUpdateUser(json, successHandler, errorHandler) {
    post(json, successHandler, errorHandler, "/basic/users");
}

export async function userApiGetUser(json, successHandler, errorHandler) {
    post(json, successHandler, errorHandler, "/basic/users/get");
}

export async function basicApiRekognizeItem(json, successHandler, errorHandler) {
    post(json, successHandler, errorHandler, "/basic/rekognize-item");
}
