import {Pagination} from "@aws-amplify/ui-react";
import React from "react";
import Utils from "../commons/Utils/Utils";

const EiaPagination = function(props) {

    const { totalPages, currentPage, setCurrentPage, positionBottom} = props;

    function handleNextPage() {
        setCurrentPage(currentPage + 1);
        Utils.scrollToRedBellAndLogin();
    }

    function handlePreviousPage() {
        setCurrentPage(currentPage - 1);
        Utils.scrollToRedBellAndLogin();
    }

    const handleChange = (newPageIndex, prevPageIndex) => {
        setCurrentPage(newPageIndex);
        Utils.scrollToRedBellAndLogin();
    };

    const pagination = <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onNext={handleNextPage}
        onPrevious={handlePreviousPage}
        onChange={handleChange}
    />

    return (
        <div>
            {
                positionBottom ?

                    <div>
                        {props.children}
                        {pagination}
                    </div>

                    :

                    <div>
                        {pagination}
                        {props.children}
                    </div>
            }
        </div>
    );
};
export default EiaPagination;