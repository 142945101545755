import React from "react";
import {Drawer} from "@mui/material";
import EiaThemeProvider from "../EiaThemeProvider";
import EiaCard from "../cards/EiaCard";
import {Divider, Heading} from "@aws-amplify/ui-react";

const EiaDrawer = function (props) {

    const {open, setOpen, variant, position, title} = props;

    const anchor = position ? position : "bottom";

    return(
        <div>
            <Drawer
                variant={variant}
                anchor={anchor}
                open={open}
                onClose={() => setOpen(false)}
                ModalProps={{
                    keepMounted: true,
                }}
            >
                <EiaThemeProvider>
                    <EiaCard>
                        <EiaCard>
                            <Heading level={3}>{title}</Heading>
                        </EiaCard>
                        <Divider/>
                        <EiaCard>
                            {props.children}
                        </EiaCard>
                    </EiaCard>
                </EiaThemeProvider>
            </Drawer>
        </div>
    )
}
export default EiaDrawer;