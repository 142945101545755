export const WELCOME = "welcome";
export const NEWS = "news";
export const SEARCH = "search";
export const BEST_PRICES = "best-prices";
export const SELL = "sell";
export const MY_SALES = "my-sales";
export const MY_BIDS = "my-bids";
export const MY_HISTORY = "my-history";
export const NOTIFICATIONS = "notifications";
export const BUILDS = "builds";
export const TOOLS = "tools";

export const FAQ = "faq";
export const USER_PRIVACY_POLICY = "user-privacy-policy";

//my history
export const SUB_PATH_SALES = "sales"
export const SUB_PATH_BIDS_AND_BUYS = "bids-and-buys"

export const MY_HISTORY_SALES = MY_HISTORY + "/" + SUB_PATH_SALES;
export const MY_HISTORY_BIDS_AND_BUYS = MY_HISTORY + "/" + SUB_PATH_BIDS_AND_BUYS;

//tools
export const SUB_PATH_D4_CALENDAR = "d4-calendar";
export const SUB_PATH_D4_WORLDBOSS_CALENDAR = "d4-wb-calendar";
export const SUB_PATH_D4_BOSS_MATERIALS = "d4-boss-materials";
export const SUB_PATH_D4_BOSS_MATERIALS_S6 = "d4-boss-materials-s6";
export const SUB_PATH_D4_PIT_MASTERWORKING = "d4-pit-masterworking";
export const SUB_PATH_D4_NM_DUNGEONS = "d4-nm-dungeons";
export const SUB_PATH_D4_AOZ = "d4-aoz";

export const TOOLS_D4_CALENDAR = TOOLS + "/" + SUB_PATH_D4_CALENDAR;
export const TOOLS_D4_WORLDBOSS_CALENDAR = TOOLS + "/" + SUB_PATH_D4_WORLDBOSS_CALENDAR;
export const TOOLS_D4_BOSS_MATERIALS = TOOLS + "/" + SUB_PATH_D4_BOSS_MATERIALS;
export const TOOLS_D4_BOSS_MATERIALS_S6 = TOOLS + "/" + SUB_PATH_D4_BOSS_MATERIALS_S6;
export const TOOLS_D4_PIT_MASTERWORKING = TOOLS + "/" + SUB_PATH_D4_PIT_MASTERWORKING;
export const TOOLS_D4_NM_DUNGEONS = TOOLS + "/" + SUB_PATH_D4_NM_DUNGEONS;
export const TOOLS_D4_AOZ = TOOLS + "/" + SUB_PATH_D4_AOZ;