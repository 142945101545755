import React from "react";
import {Autocomplete} from "@aws-amplify/ui-react";
import {useCookies} from "react-cookie";

const EiaAutoComplete = function(props) {
    const {title, values, value, setValue} = props;

    const [cookies, setCookies, removeCookies] = useCookies(['darkMode']);
    const darkMode = cookies.darkMode === undefined ? true : cookies.darkMode;
    const textColor = darkMode ? 'white' : 'black';

    const options = values ? values.map(value => {
        return {"id": value, "label": value, "color": textColor}}
    ) : [];

    const optionFilter = (option, value) => {
        return option?.label?.toLowerCase().includes(value.toLowerCase());
    };



    return (
            <Autocomplete
                label={title}
                options={options}
                optionFilter={optionFilter}
                value={value}
                onClear={() => setValue("")}
                onSelect={(option) => setValue(option.label)}
                onChange={(event) => setValue(event.target.value)}
                hasSearchIcon={false}
            />
    )
}
export default EiaAutoComplete;