import {contains} from "./Utils/ArrayUtils";
import {ITEM_SUB_TYPES_WITHOUT_STATS} from "./enums/D4Enums";

const adjustItemInAuctionForGui = function(auction) {
    auction.itemType = auction.item.type
    auction.itemSubType = auction.item.subType
    auction.itemName = auction.item.name
    auction.itemTitle = auction.item.title
    auction.itemPower = auction.item.itemPower
    auction.levelRequirement = auction.item.levelRequirement
    auction.classRequirement = auction.item.classRequirement
    auction.dps = auction.item.dps
    auction.armor = auction.item.armor
    auction.rarity = auction.item.rarity
    if(auction.item.basicStats) {
        auction.item.basicStats.forEach(stat => {
            auction[stat.name] = stat.floatValues[0];
        })
    }
    if(auction.item.stats) {
        auction.item.stats.forEach(stat => {
            auction[stat.name] = stat.floatValues[0];
        })
    }

    if(auction.item.aspects) {
        auction.item.aspects.forEach(stat => {
            auction[stat.name] = stat.floatValues[0];
        })
    }

    if(auction.item.uniqueStats) {
        //TODO: rename? backend calls it uniqueStats and forntend calls it uniqueAffixes
        auction.item.uniqueAffixes = auction.item.uniqueStats;
        auction.item.uniqueStats.forEach(stat => {
            auction[stat.name] = stat.floatValues[0];
        })
    }
}

const adjustDatesInAuctionForGui=function(auction) {
    let cDate = new Date(0);
    cDate.setUTCSeconds(auction.creationDate);
    auction.creationDate = cDate
    let eDate = new Date(0);
    eDate.setUTCSeconds(auction.expirationDate);
    auction.expirationDate = eDate;
    if(auction.abortionDate) {
        let aDate = new Date(0);
        aDate.setUTCSeconds(auction.abortionDate);
        auction.abortionDate = aDate;
    }
}

export const auctionToTableElement = function (auction) {
    let result = auction

    // result.region = "ALL"

    adjustDatesInAuctionForGui(result);
    adjustItemInAuctionForGui(result);

    result.pricePerItem = auction.amount ? auction.price / auction.amount : auction.price
    return result;
}

export const bidsForMyAuction = function(bids, setAlert) {
    let result = [];

    for (let i = 0; i < bids.length; i++) {
        let resultBid = bids[i]
        if(resultBid == null) {
            setAlert(["warning", "Cannot find bid for a bidder - please report a bug"]);
        } else {
            resultBid.id = resultBid.bidder + resultBid.auctionId
            resultBid.pricePerItem = resultBid.amount ? resultBid.price / resultBid.amount : resultBid.price
            result.push(resultBid)
        }
    }

    return result;
}

export const myAuctionsAndBidsToMyBids = function(auctions, bids, bidStatusFilter) {
    let result = [];
    for (let i = 0; i < auctions.length; i++) {
        let bid = bids[i];
        if(contains(bidStatusFilter, bid.status)) {
            let temp = auctions[i]

            adjustDatesInAuctionForGui(temp);
            adjustItemInAuctionForGui(temp)

            temp.myBidStatus = bid.status
            temp.bidder = bid.bidder
            if(bid.currency) {
                temp.originalCurrency = temp.currency;
                temp.originalPrice = temp.price;
                temp.originalAmount = temp.amount;
                temp.originalPricePerItem = temp.amount ? temp.price / temp.amount : temp.price

                temp.currency = bid.currency;
                temp.price = bid.price;
                temp.amount = bid.amount;
            }

            temp.pricePerItem = temp.amount ? temp.price / temp.amount : temp.price
            result.push(temp)
        }
    }
    return result;
}

export const convertToD4ItemForRequest = function(baseItem, baseStats, implicitStatsToConvert, statsToConvert, aspects, uniqueAffixes) {
    return {
        type: baseItem.itemType,
        subType: baseItem.itemSubType,
        title: baseItem.title,
        name: baseItem.name,
        rarity: baseItem.rarity,
        basicStats: !contains(ITEM_SUB_TYPES_WITHOUT_STATS, baseItem.itemSubType) && implicitStatsToConvert ? getStats(implicitStatsToConvert) : [],
        stats: !contains(ITEM_SUB_TYPES_WITHOUT_STATS, baseItem.itemSubType) && statsToConvert ? getStats(statsToConvert) : [],
        aspects: !contains(ITEM_SUB_TYPES_WITHOUT_STATS, baseItem.itemSubType) && aspects ? getStats(aspects) : [],
        uniqueStats: !contains(ITEM_SUB_TYPES_WITHOUT_STATS, baseItem.itemSubType) && uniqueAffixes ? getStats(uniqueAffixes) : [],
        itemPower : baseStats.itemPower,
        classRequirement: baseStats.classRequirement,
        levelRequirement: baseStats.levelRequirement,
        dps: baseStats.dps && baseStats.dps > 0 ? baseStats.dps : null,
        armor: baseStats.armor && baseStats.armor > 0 ? baseStats.armor : null,
    }
}

const getStats = function(statsToConvert) {
    if(!statsToConvert) {
        return statsToConvert;
    }

    let result = Object.assign([], statsToConvert);
    result.map(stat => {
        if(!stat.floatValues || !stat.floatValues[0]) {
            stat.floatValues = [0];
        }
        return stat;
    })
    return result;
}