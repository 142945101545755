import {Button} from "@aws-amplify/ui-react";
import React from "react";
import Tooltip from "@mui/material/Tooltip";

const EiaButton = function(props) {

    const {tooltip, hide=false, ...restProps} = props;

    if(hide) {
        return null;
    }

    return (
        <div>
            {
                tooltip ?
                    <Tooltip title={tooltip} placement={"top"}>
                        <span>
                            <Button
                                variation="primary"
                                {...restProps}
                            >
                                {props.children}
                            </Button>
                        </span>
                    </Tooltip>
                :

                    <Button
                        variation="primary"
                        {...restProps}
                    >
                        {props.children}
                    </Button>
            }

        </div>
    );
};
export default EiaButton;