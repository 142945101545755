import HoursToReachLevelCalculator from "./HoursToReachLevelCalculator";
import React from "react";
import {Flex, Heading} from "@aws-amplify/ui-react";
import EiaFixedTable from "../tables/EiaFixedTable";

const pitDungeonHeader = ["Pit Tier", "Monster Level", "Gold Reward", "Obducite Reward", "Ingolith Reward", "Neathiron"
+ " Reward"];
const pitDungeonTable = [
    [1,100,82000,10,0,0],
    [2,101,83600,11,0,0],
    [3,102,85200,12,0,0],
    [4,103,86800,13,0,0],
    [5,104,88400,14,0,0],
    [6,105,90000,15,0,0],
    [7,106,91600,16,0,0],
    [8,107,93200,17,0,0],
    [9,108,94800,18,0,0],
    [10,109,96400,19,0,0],
    [11,110,98000,20,0,0],
    [12,111,99600,21,0,0],
    [13,112,101200,22,0,0],
    [14,113,102800,23,0,0],
    [15,114,104400,24,0,0],
    [16,115,106000,25,0,0],
    [17,116,107600,26,0,0],
    [18,117,109200,27,0,0],
    [19,118,110800,28,0,0],
    [20,119,112400,29,0,0],
    [21,120,114000,30,0,0],
    [22,121,115600,31,0,0],
    [23,122,117200,32,0,0],
    [24,123,118800,33,0,0],
    [25,124,120400,34,0,0],
    [26,125,122000,35,0,0],
    [27,126,123600,36,0,0],
    [28,127,125200,37,0,0],
    [29,128,126800,38,0,0],
    [30,129,128400,39,0,0],
    [31,130,130000,0,20,0],
    [32,131,131600,0,21,0],
    [33,132,133200,0,22,0],
    [34,133,134800,0,23,0],
    [35,134,136400,0,24,0],
    [36,135,138000,0,25,0],
    [37,136,139600,0,26,0],
    [38,137,141200,0,27,0],
    [39,138,142800,0,28,0],
    [40,139,144400,0,29,0],
    [41,140,146000,0,30,0],
    [42,141,147600,0,31,0],
    [43,142,149200,0,32,0],
    [44,143,150800,0,33,0],
    [45,144,152400,0,34,0],
    [46,145,154000,0,35,0],
    [47,146,155600,0,36,0],
    [48,147,157200,0,37,0],
    [49,148,158800,0,38,0],
    [50,149,160400,0,39,0],
    [51,150,162000,0,40,0],
    [52,151,163600,0,41,0],
    [53,152,165200,0,42,0],
    [54,153,166800,0,43,0],
    [55,154,168400,0,44,0],
    [56,155,170000,0,45,0],
    [57,156,171600,0,46,0],
    [58,157,173200,0,47,0],
    [59,158,174800,0,48,0],
    [60,159,176400,0,49,0],
    [61,160,178000,0,0,20],
    [62,161,179600,0,0,21],
    [63,162,181200,0,0,22],
    [64,163,182800,0,0,23],
    [65,164,184400,0,0,24],
    [66,165,186000,0,0,25],
    [67,166,187600,0,0,26],
    [68,167,189200,0,0,27],
    [69,168,190800,0,0,28],
    [70,169,192400,0,0,29],
    [71,170,194000,0,0,30],
    [72,171,195600,0,0,31],
    [73,172,197200,0,0,32],
    [74,173,198800,0,0,33],
    [75,174,200400,0,0,34],
    [76,175,202000,0,0,35],
    [77,176,203600,0,0,36],
    [78,177,205200,0,0,37],
    [79,178,206800,0,0,38],
    [80,179,208400,0,0,39],
    [81,180,210000,0,0,40],
    [82,181,211600,0,0,41],
    [83,182,213200,0,0,42],
    [84,183,214800,0,0,43],
    [85,184,216400,0,0,44],
    [86,185,218000,0,0,45],
    [87,186,219600,0,0,46],
    [88,187,221200,0,0,47],
    [89,188,222800,0,0,48],
    [90,189,224400,0,0,49],
    [91,190,226000,0,0,50],
    [92,191,227600,0,0,51],
    [93,192,229200,0,0,52],
    [94,193,230800,0,0,53],
    [95,194,232400,0,0,54],
    [96,195,234000,0,0,55],
    [97,196,235600,0,0,56],
    [98,197,237200,0,0,57],
    [99,198,238800,0,0,58],
    [100,199,240400,0,0,59],
    [101,199,241200,0,0,60],
    [102,199,242000,0,0,60],
    [103,199,242800,0,0,61],
    [104,199,243600,0,0,61],
    [105,199,244400,0,0,62],
    [106,199,245200,0,0,62],
    [107,199,246000,0,0,62],
    [108,199,246800,0,0,63],
    [109,199,247600,0,0,63],
    [110,199,248400,0,0,64],
    [111,199,249200,0,0,64],
    [112,199,250000,0,0,64],
    [113,199,250800,0,0,65],
    [114,199,251600,0,0,65],
    [115,199,252400,0,0,66],
    [116,199,253200,0,0,66],
    [117,199,254000,0,0,66],
    [118,199,254800,0,0,67],
    [119,199,255600,0,0,67],
    [120,199,256400,0,0,68],
    [121,199,257200,0,0,68],
    [122,199,258000,0,0,68],
    [123,199,258800,0,0,69],
    [124,199,259600,0,0,69],
    [125,199,260400,0,0,70],
    [126,199,261200,0,0,70],
    [127,199,262000,0,0,70],
    [128,199,262800,0,0,71],
    [129,199,263600,0,0,71],
    [130,199,264400,0,0,72],
    [131,199,265200,0,0,72],
    [132,199,266000,0,0,72],
    [133,199,266800,0,0,73],
    [134,199,267600,0,0,73],
    [135,199,268400,0,0,74],
    [136,199,269200,0,0,74],
    [137,199,270000,0,0,74],
    [138,199,270800,0,0,75],
    [139,199,271600,0,0,75],
    [140,199,272400,0,0,76],
    [141,199,273200,0,0,76],
    [142,199,274000,0,0,76],
    [143,199,274800,0,0,77],
    [144,199,275600,0,0,77],
    [145,199,276400,0,0,78],
    [146,199,277200,0,0,78],
    [147,199,278000,0,0,78],
    [148,199,278800,0,0,79],
    [149,199,279600,0,0,79],
    [150,199,280400,0,0,80],
    [151,199,281200,0,0,80],
    [152,199,282000,0,0,80],
    [153,199,282800,0,0,81],
    [154,199,283600,0,0,81],
    [155,199,284400,0,0,82],
    [156,199,285200,0,0,82],
    [157,199,286000,0,0,82],
    [158,199,286800,0,0,83],
    [159,199,287600,0,0,83],
    [160,199,288400,0,0,84],
    [161,199,289200,0,0,84],
    [162,199,290000,0,0,84],
    [163,199,290800,0,0,85],
    [164,199,291600,0,0,85],
    [165,199,292400,0,0,86],
    [166,199,293200,0,0,86],
    [167,199,294000,0,0,86],
    [168,199,294800,0,0,87],
    [169,199,295600,0,0,87],
    [170,199,296400,0,0,88],
    [171,199,297200,0,0,88],
    [172,199,298000,0,0,88],
    [173,199,298800,0,0,89],
    [174,199,299600,0,0,89],
    [175,199,300400,0,0,90],
    [176,199,301200,0,0,90],
    [177,199,302000,0,0,90],
    [178,199,302800,0,0,91],
    [179,199,303600,0,0,91],
    [180,199,304400,0,0,92],
    [181,199,305200,0,0,92],
    [182,199,306000,0,0,92],
    [183,199,306800,0,0,93],
    [184,199,307600,0,0,93],
    [185,199,308400,0,0,94],
    [186,199,309200,0,0,94],
    [187,199,310000,0,0,94],
    [188,199,310800,0,0,95],
    [189,199,311600,0,0,95],
    [190,199,312400,0,0,96],
    [191,199,313200,0,0,96],
    [192,199,314000,0,0,96],
    [193,199,314800,0,0,97],
    [194,199,315600,0,0,97],
    [195,199,316400,0,0,98],
    [196,199,317200,0,0,98],
    [197,199,318000,0,0,98],
    [198,199,318800,0,0,99],
    [199,199,319600,0,0,99],
    [200,199,320400,0,0,100]
]

const masterWorkingHeader = ["Masterwork Level", "Total Neathiron (from level 0)", "Neathiron (or equivalent) to next level"];
const masterWorkingData = [
        [0,	0,	1.111111111],
        [1,	1.111111111, 2.222222222],
        [2,	3.333333333,	3.333333333],
        [3,	6.666666667,	4.444444444],
        [4,	11.11111111,	6.666666667],
        [5,	17.77777778,	13.33333333],
        [6,	31.11111111,	26.66666667],
        [7,	57.77777778,	40],
        [8,	97.77777778,	50],
        [9,	147.7777778,	100],
        [10,	247.7777778,	150],
        [11,	397.7777778,	250],
        [12,	647.7777778,	0]
    ]
;

const pitTables = <Flex direction={"column"}>
    <Heading level={6}>Pit Reward Table</Heading>
    <EiaFixedTable data = {pitDungeonTable} tableHeader={pitDungeonHeader}/>
    <Heading level={6}>Masterworking Table</Heading>
    <EiaFixedTable data = {masterWorkingData} tableHeader={masterWorkingHeader}/>
</Flex>

const PitCalculator_Neathiron = function() {


    return(
        <HoursToReachLevelCalculator
            title={"Pit Masterwork Calculator (Neathiron)"}
            lastUpdated={"2024/08/02"}
            initialCurrentLevel={0}
            initialTargetLevel={12}
            initialDungeonTier={61}
            initialDungeonClearMinutes={5}
            currentLevelText={"Masterwork Current Level"}
            targetLevelText={"Masterwork Target Level"}
            dungeonTierText={"Pit tier you run"}
            dungeonClearMinutesText={"Pit clear time (minutes)"}
            levelXpData={masterWorkingData}
            dungeonXpRewardData={pitDungeonTable}
            tables={pitTables}
            levelMax={12}
            dungeonTierMax={200}
            rewardToLevelIndex={5}
            rewardName={"neathiron"}
            doRoundRewardToK={false}
        />
    )
}

export default PitCalculator_Neathiron;