import {Flex, Heading} from "@aws-amplify/ui-react";
import EiaHelpButton from "../EiaHelpButton";
import EiaImage from "../EiaImage";
import React from "react";
import helpItemName from "../../resources/help/helpItemName.jpg";
import D4ItemTypesSelect from "./D4ItemTypesSelect";

const D4ItemSelects = function(props) {
    const {baseItem, setBaseItem, showAmount, removeAny, titleEditable} = props;

    return (
        <Flex direction="column" justifyContent="space-between">
            <Flex>
                <Heading level={4}>Item</Heading>
                <EiaHelpButton title={"Where is the item title, rarity and description?"}>
                    <Flex justifyContent={"space-around"}>
                        <EiaImage objectFit="cover" height={300} src={helpItemName}/>
                    </Flex>
                </EiaHelpButton>
            </Flex>
            <D4ItemTypesSelect showAmount={showAmount} baseItem={baseItem} setBaseItem={setBaseItem} removeAny={removeAny} titleEditable={titleEditable}/>
        </Flex>
    )
}
export default D4ItemSelects;