import React from "react";
import {Alert} from "@aws-amplify/ui-react";
import EiaCard from "./EiaCard";

const AlertCard = function(props) {

    const {alert} = props;

    const [lastAlert, setLastAlert] = React.useState(null)

    React.useEffect(() => {
        if(alert) {
            setLastAlert(<Alert variation={alert[0]} isDismissible={true} onDismiss={() => setLastAlert(null)}>{alert[1]}</Alert>)
        } else {
            setLastAlert(null)
        }
    }, [alert]);

    return (
        <EiaCard
            columnStart="1"
            columnEnd="-1"
        >
            {lastAlert}
        </EiaCard>
    )
}
export default AlertCard;